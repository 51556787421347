import React, { Component } from "react";
import {
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CALCULAR_MORA_LEGAL,
    REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL,
    REACT_APP_ROL_CONTABILIDAD
} from "../../environment";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import { ObtenerParametro } from "../../Services/ParametroService";
import swal from "sweetalert";
import ReactDOM from "react-dom";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export default class ProgramaCarteraComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indUbicacion: false,
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        sessionStorage.removeItem("infoProgramaCarteraEditar");
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idRol = localStorage.getItem("idRol");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const userAgent = ObtenerUserAgent();
        this.obtenerMoraLegal();
        /**
         * Definicion de las columnas 
         **/
        let columnas = [
            { data: "detallePrograma.id", name: "id" },
            { data: "cliente.nombreOperadora", name: "cliente.nombreOperadora" },
            {
                data: "detallePrograma.nombrePrograma",
                name: "detallePrograma.nombrePrograma",
            },
            {
                data: "detallePrograma.montoProgramaAsignado",
                render: function (montoPrograma) {
                    return montoPrograma.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "detallePrograma.montoProgramaAsignado",
            },
            {
                data: "disponibleMontoProgramaAsignado",
                render: function (disponibleMontoProgramaAsignado) {
                    return disponibleMontoProgramaAsignado.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "disponibleMontoProgramaAsignado",
            },
            {
                data: "detallePrograma.fechaInicio",
                render: function (jsonDate) {
                    var date = new Date(jsonDate);
                    return date.toLocaleDateString();
                },
                name: "detallePrograma.fechaInicio",
            },
            {
                data: "detallePrograma.fechaAprobacion",
                render: function (jsonDate) {
                    var date = new Date(jsonDate);
                    return date.toLocaleDateString();
                },
                name: "detallePrograma.fechaAprobacion",
            },
            {
                data: "detallePrograma.vigenciaPrograma",
                name: "detallePrograma.vigenciaPrograma",
            },
            {
                data: "detallePrograma.montoMaximoAval",
                render: function (montoMaximo) {
                    return montoMaximo.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "detallePrograma.montoMaximoAval",
            },
            { data: "estado.valor", name: "estado.valor" },
            {
                data: "detallePrograma.indicadorConsumoWSRiesgo",
                name: "detallePrograma.indicadorConsumoWSRiesgo",
                render: function (data, type, row, meta) {
                    if (type === "display") {
                        if (row.detallePrograma.indicadorConsumoWSRiesgo) {
                            data = "SI";
                        } else {
                            data = "NO";
                        }
                    }

                    return data;
                },
            },
        ];
        let definicionColumnas = [];
        if (idRol == REACT_APP_ROL_ADMINISTRATIVO ||
            idRol == REACT_APP_ROL_EJECUTIVO ||
            idRol == REACT_APP_ROL_INCLUSION ||
            idRol == REACT_APP_ROL_OPERATIVO ||
            idRol == REACT_APP_ROL_JEFATURA ||
            idRol == REACT_APP_ROL_CONTABILIDAD ||
            idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
            columnas.push({ data: null, name: "Acciones" });
            definicionColumnas.push({
                targets: 11,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) =>
                    ReactDOM.render(
                        <a
                            className="btn btn-primary btn-sm border-radius"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editar(rowData)}
                        >
                            <i className="fa fa-pencil"></i>
                        </a>,
                        td
                    ),
            });
        }
        /**
         * Creacion del DataTable
         **/
        $("#tabla").DataTable({
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                this.api()
                    .column()
                    .each(function () {
                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ListarProgramasCartera?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then(() => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los programas de cartera", "", "error", { button: "Aceptar", });
                        return;
                    }
                },
                type: "POST",
            },
            columns: columnas,
            columnDefs: definicionColumnas,
        });
    }
    /**
     * Valida si se permite calcular la mora legal
     */
    obtenerMoraLegal = () => {
        ObtenerParametro(REACT_APP_CALCULAR_MORA_LEGAL).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Mora Legal", "Ha ocurrido un error al obtener el parámetro para calcular la mora legal. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error");
                    return;
                }
                ObtenerParametro(REACT_APP_OBTENER_PORCENTAJE_MORA_LEGAL).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal("Mora Legal", "Ha ocurrido un error al obtener el porcentaje máximo de mora legal permitido. Por favor recargue de nuevo la página o bien, comuníquese con el adminsitrador.", "error");
                            return;
                        }
                        localStorage.setItem("porcentajeMoralLegal", res.json.valor);
                    }
                });
                localStorage.setItem("calcularMoralLegal", res.json.valor);
            }
        });
    }
    /**
     * 
     * @param {*} columna 
     * @returns Redirecciona a la vista de Editar Programa Cartera
     */
    editar = (columna) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        sessionStorage.setItem("infoProgramaCarteraEditar", JSON.stringify(columna));
        window.location.href = "programa-cartera/" + columna.id;
    };
    /**
     * 
     * @returns Redirecciona a la vista de Crear Programa Cartera
     **/
    crearPrograma = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "programa-cartera/crear";
    };
    /**
     * Se llama inmediatamente antes de que se destruya un componente. 
     * Realice cualquier limpieza necesaria en este método, como solicitudes de red canceladas o la limpieza de cualquier elemento DOM creado en componentDidMount.
     **/
    componentWillUnmount() {
        $(".data-table-wrapper").find("table").DataTable().destroy(true);
    }
    /**
     * 
     * @returns Si se devuelve falso, no se llamará a Component#render, componentWillUpdate y componentDidUpdate.
     **/
    shouldComponentUpdate() {
        return false;
    }
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO &&
                idRol != REACT_APP_ROL_EJECUTIVO &&
                idRol != REACT_APP_ROL_INCLUSION &&
                idRol != REACT_APP_ROL_SEGUIMIENTO &&
                idRol != REACT_APP_ROL_CONTABILIDAD &&
                idRol != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                window.location.href = "/mantenimiento-programas";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                onClick={this.crearPrograma}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Programa
                                Cartera
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_INCLUSION
            case "3": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light"></nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_OPERATIVO
            case "4": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light"></nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_JEFATURA
            case "5": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light"></nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light"></nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Programa Cartera</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento-programas">Mantenimiento Programas</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Programa Cartera
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Entidad Operadora</th>
                                        <th>Nombre Programa</th>
                                        <th>Monto Programa Asignado</th>
                                        <th>Disponible Monto Programa Asignado</th>
                                        <th>Fecha Inicio</th>
                                        <th>Fecha Aprobación</th>
                                        <th>Vigencia Programa</th>
                                        <th>Monto del Aval</th>
                                        <th>Estado</th>
                                        <th>¿Consume Modelo de Riesgo?</th>
                                        <th>Acciones</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }
}
