import React, { Component } from "react";
import {
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
} from "../../environment";
import swal from "sweetalert";
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export default class MantenimientoReportesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indUbicacion: false,
        };
    }
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
    }
    irReporteGeneralAvales = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "/reporte-general-avales";
    };
    irReporteImpactoCualitativo = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "/reporte-impacto-cualitativo";
    };
    irReporteGestionFODEMIPYME = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "/reporte-gestion-fodemipyme";
    };
    irReporteHonramientos = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "/reporte-honramiento";
    };
    irReporteProvisiones = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "/reporte-provisiones";
    };
    irReporteLineaCredito = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        window.location.href = "/reporte-linea-credito";
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO &&
                idRol != REACT_APP_ROL_CONTABILIDAD &&
                idRol != REACT_APP_ROL_SEGUIMIENTO &&
                idRol != REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                            Mantenimiento Reportes
                        </li>
                    </ol>
                </nav>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irReporteGeneralAvales}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-bar-chart"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Reporte General Avales</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irReporteImpactoCualitativo}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-pie-chart"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Reporte Impacto Cualitativo</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irReporteGestionFODEMIPYME}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-area-chart"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Reporte Gestión FODEMIPYME</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irReporteHonramientos}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-line-chart"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Reporte Honramientos</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irReporteProvisiones}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-bar-chart"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Reporte Provisiones</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 mt-4">
                        <div
                            class="card card-menu text-center hover-shadow-lg hover-translate-y-n10"
                            onClick={this.irReporteLineaCredito}
                            style={{ cursor: "pointer" }}
                        >
                            <div class="card-body">
                                <i
                                    class="fa fa-line-chart"
                                    style={{ fontSize: "35px" }}
                                    aria-hidden="true"
                                ></i>
                            </div>
                            <div class="px-4 pb-2">
                                <h5>Reporte Líneas Crédito</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
