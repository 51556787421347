import React, { Component } from "react";
import {
    REACT_APP_API_URL, REACT_APP_CODE,
    REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_ACEPTADO,
    REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE,
    REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_FORMALIZADO,
    REACT_APP_AVAL_ESTADO_ARREGLO_PAGO,
    REACT_APP_AVAL_ESTADO_FINALIZADO,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE,
    REACT_APP_MONEDA_COLONES,
    REACT_APP_TIPO_PROGRAMA_INDIVIDUAL,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_NATURALEZA_LINEA_CREDITO,
    REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO
} from "../../environment";
import swal from "sweetalert";
import ReactDOM from "react-dom";
import { Button, Form, Modal } from "react-bootstrap";
import {
    CargaMensualAval,
    CargaMensualIndividualAval,
    ModificarAval,
} from "../../Services/AvalService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import {
    CrearHornamiento,
    puedeHonar,
} from "../../Services/HonramientoService";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
class LineaCreditoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            idAval: 0,
            indUbicacion: false,
            mostrarModalCargaMasiva: false,
            monto: "",
            numeroOperacion: 0,
            mostrarModalCargaIndividual: false,
            mostrarModalCrearHonramiento: false,
            mostrarModalCancelar: false,
            montoSugerido: 0,
            montoHonramiento: "",
            mensajeHonramiento: "",
            nombreArchivCargaMasiva: null,
            token: "",
            indButton: false,
            programaSeleccionado: "",
            montoCredito: "",
            porcAval: 0,
            saldoCredito: "",
            saldoAval: "",
            simboloMoneda: "",
            naturaleza: 0,
            indMostrarCargaMasiva: true
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        const idRol = localStorage.getItem("idRol");
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idOperadora = localStorage.getItem("idOperadora");
        const morosidadDiaHabil = parseInt(localStorage.getItem("MOROSIDAD_DIA_HABIL"));
        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        this.state.token = tokenContent;
        const userAgent = ObtenerUserAgent();
        sessionStorage.removeItem("infoLineaCreditoEditar");
        sessionStorage.removeItem("idLineaCredito");
        localStorage.removeItem("datatable");
        const fecha = new Date();
        const dia = fecha.getDate();
        const numeroDia = fecha.getDay();
        var finDeSemana = false;

        if (numeroDia == 6 || numeroDia == 0) finDeSemana = true;

        var numDiasTrabajo = 0;
        var limiteDiaHabil = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        var ultimoDiaFechaActual = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);
        while (limiteDiaHabil <= ultimoDiaFechaActual && numDiasTrabajo <= morosidadDiaHabil) {
            if (limiteDiaHabil.getDay() !== 0 && limiteDiaHabil.getDay() !== 6) numDiasTrabajo++;

            limiteDiaHabil = new Date(limiteDiaHabil.getFullYear(), limiteDiaHabil.getMonth(), limiteDiaHabil.getDate() + 1);
        }
        if ((dia >= 1 && dia < limiteDiaHabil.getDate() && finDeSemana) ||
            (dia >= 1 && dia >= limiteDiaHabil.getDate())) {
            this.state.indMostrarCargaMasiva = false;
            this.setState({ indMostrarCargaMasiva: false });
        }
        /**
         * Definicion de las columnas 
         **/
        let columnas = [
            { data: "id", name: "id" },
            { data: "tipoPrograma.valor", name: "tipoPrograma.valor" },
            {
                data: "entidadFinancieraAutorizada.nombreOperadora",
                name: "entidadFinancieraAutorizada.nombreOperadora",
            },
            { data: "moneda.valor", name: "moneda.valor" },
            { data: "nombreSolicitante", name: "nombreSolicitante" },
            {
                data: "numeroIdentificacionSolicitante",
                name: "numeroIdentificacionSolicitante",
            },
            {
                data: "porcentajeTotalAvalSolicitado",
                render: function (porcentajeTotalAvalSolicitado) {
                    return porcentajeTotalAvalSolicitado.toFixed(2);
                },
                name: "porcentajeTotalAvalSolicitado",
            },
            {
                data: "saldoCredito",
                render: function (saldoCredito) {
                    return saldoCredito
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "saldoCredito",
            },
            {
                data: "saldoAval",
                render: function (saldoAval) {
                    return saldoAval
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "saldoAval",
            },
            {
                data: "disponible",
                render: function (disponible) {
                    return disponible
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "disponible",
            },
            {
                data: "estado.valor",
                name: "estado.valor",
            },
            { data: null, name: "Acciones" },
            { data: null, name: "SubCrédito" },
        ];
        /**
         * Mostrar el boton de editar segun el rol y estado del aval
         **/
        let definicionColumnas = [
            {
                targets: 11,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) => {
                    var ejecutivoCreador = [];
                    ejecutivoCreador = rowData.ejecutivoCreador.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    ((idOperadora == REACT_APP_OPERADORA_FODEMIPYME || idOperadora == REACT_APP_OPERADORA_FNA) &&
                        (idRol == REACT_APP_ROL_ADMINISTRATIVO ||
                            idRol == REACT_APP_ROL_EJECUTIVO ||
                            idRol == REACT_APP_ROL_SEGUIMIENTO ||
                            idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)) ||
                        ((idOperadora == REACT_APP_OPERADORA_FODEMIPYME || idOperadora == REACT_APP_OPERADORA_FNA) &&
                            idRol == REACT_APP_ROL_CONTABILIDAD &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO)
                        ? ReactDOM.render(
                            <a
                                disable
                                className="btn btn-primary btn-sm border-radius"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editar(rowData)}
                            >
                                <i className="fa fa-pencil"></i>
                            </a>,
                            td
                        )
                        : ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                            idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                                ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                                ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION) ||
                            (ejecutivoCreador[0].rol.id == REACT_APP_ROL_OPERATIVO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                ejecutivoCreador[0].rol.id == REACT_APP_ROL_OPERATIVO) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado ||
                                    rowData.idJefaturaReciente == idUsuarioLogueado)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD &&
                                idRol == REACT_APP_ROL_JEFATURA) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                rowData.idOperativoReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                rowData.ejecutivoCreador.b2CId == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.idJefaturaReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA) &&
                                (rowData.idInclusorReciente == idUsuarioLogueado ||
                                    rowData.idOperativoReciente == idUsuarioLogueado ||
                                    (rowData.idJefaturaReciente == idUsuarioLogueado ||
                                        rowData.idJefaturaReciente != idUsuarioLogueado))) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.idJefaturaReciente == idUsuarioLogueado) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO &&
                                idRol == REACT_APP_ROL_JEFATURA &&
                                rowData.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO &&
                                (idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_OPERATIVO)) ||
                            ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                                rowData.entidadFinancieraAutorizada.id == idOperadora &&
                                (rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                    rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE))
                            ? ReactDOM.render(
                                <a
                                    disable
                                    className="btn btn-primary btn-sm border-radius"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => this.editar(rowData)}
                                    data-toggle="tooltip" data-placement="top" title="Editar"
                                >
                                    <i className="fa fa-pencil"></i>
                                </a>,
                                td
                            )
                            : ReactDOM.render("", td);
                },
            },
            {
                targets: 12,
                orderable: false,
                createdCell: (td, cellData, rowData, row, col) => {
                    rowData.naturaleza == REACT_APP_NATURALEZA_LINEA_CREDITO &&
                        (rowData.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE)
                        ? ReactDOM.render(
                            <a
                                disable
                                className="btn btn-info btn-sm border-radius"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.abrirSubCreditos(rowData)}
                                data-toggle="tooltip" data-placement="top" title="SubCréditos"
                            >
                                <i className="fa fa-child"></i>
                            </a>,
                            td
                        )
                        : ReactDOM.render("", td);
                },
            },
        ];
        /**
         * Creacion del DataTable
         **/
        $("#tabla").DataTable({
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                this.api()
                    .column()
                    .each(function () {
                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ListarLineasCredito?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener las líneas de crédito", thrown, "error", { button: "Aceptar", });
                        return;
                    }
                },
                type: "POST",
            },
            columns: columnas,
            columnDefs: definicionColumnas,
        });
    }
    /**
     * 
     * @param {*} columna 
     * @returns valida si el aval puede ser honrado
     **/
    validarHonramiento = (columna) => {
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let data = {
            IdAval: parseInt(columna.id),
            IdEjecutivo: idUsuarioCrea,
        };
        this.state.idAval = columna.id;
        this.setState({ idAval: columna.id });
        this.setState({ indButton: false });
        swal("consultando....");
        puedeHonar(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    this.setState({ indButton: false });
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ monto: "" });
                    return;
                }
                if (res.json.puedeHonrar) {
                    this.setState({ montoSugerido: res.json.montoSugerido });
                    this.setState({ montoHonramiento: res.json.montoSugerido });
                    this.setState({ mensajeHonramiento: res.json.mensaje });
                    this.setState({ mostrarModalCrearHonramiento: true });
                } else {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "warning", { button: "Aceptar", });
                }
                this.setState({ indButton: false });
            }
            this.setState({ indButton: false });
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Crear honramiento
     */
    crearHonramiento = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        if (this.state.montoHonramiento === "") {
            swal("Honramiento", "El monto es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.montoHonramiento.length < 0) {
            swal("Honramiento", "El monto no puede ser negativo", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.montoHonramiento.length > 12) {
            swal("Honramiento", "El monto no puede superar 999,999,999,999", "error", { button: "Aceptar", });
            return;
        }
        let data = {
            IdAval: parseInt(this.state.idAval),
            IdEjecutivo: idUsuarioCrea,
            MontoHonramiento: parseInt(this.state.montoHonramiento),
        };
        this.setState({ indButton: true });
        CrearHornamiento(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    this.setState({ indButton: false });
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                this.setState({ mostrarModalCrearHonramiento: false });
                this.setState({ montoHonramiento: 0 });
                swal(fondoSeleccionado.nombreFondo, "¡Honramiento creado con éxito!", "success", { button: "Aceptar", });
                this.setState({ indButton: false });
            }
            this.setState({ indButton: false });
        });
    };
    /**
     * 
     * @param {*} columna
     * @returns Mostrar daros del aval para realizar pago individual 
     **/
    cargarMasivaIndividual = (columna) => {
        this.state.numeroOperacion = columna.numeroOperacion;
        this.setState({ numeroOperacion: columna.numeroOperacion });

        this.state.mostrarModalCargaIndividual = true;
        this.setState({ mostrarModalCargaIndividual: true });

        this.state.programaSeleccionado = columna.programa;
        this.setState({ programaSeleccionado: columna.programa });

        this.state.montoCredito = columna.montoCredito;
        this.setState({ montoCredito: columna.montoCredito });

        this.state.porcAval = columna.porcentajeTotalAvalSolicitado;
        this.setState({ porcAval: columna.porcentajeTotalAvalSolicitado });

        this.state.saldoCredito = columna.saldoCredito;
        this.setState({ saldoCredito: columna.saldoCredito });

        this.state.saldoAval = columna.saldoAval;
        this.setState({ saldoAval: columna.saldoAval });

        this.state.naturaleza = columna.naturaleza;
        this.setState({ naturaleza: columna.naturaleza });

        if (columna.moneda.id == REACT_APP_MONEDA_COLONES) {
            this.state.simboloMoneda = "¢";
            this.setState({ simboloMoneda: "¢" });
        } else {
            this.state.simboloMoneda = "$";
            this.setState({ simboloMoneda: "$" });
        }
    };
    /**
     * Deshabilitar el modal de carga masiva
     **/
    cerrarModalCargaMasiva = () => {
        this.setState({ mostrarModalCargaMasiva: false });
        this.state.nombreArchivCargaMasiva = null;
        this.setState({ nombreArchivCargaMasiva: null });
    };
    /**
     * Deshabilitar el modal de carga individual
     **/
    cerrarModalCargaIndividual = () => {
        this.setState({ mostrarModalCargaIndividual: false });
    };
    /**
     * Deshabilitar el modal de crear honramiento
     **/
    cerrarModalCrearHonramiento = () => {
        this.setState({ mostrarModalCrearHonramiento: false });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State lo digitado en el formulario. 
     **/
    encargarseCambio = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    /**
     * 
     * @param {*} columna 
     * @returns Redirecciona a la vista de listar subcreditos 
     **/
    abrirSubCreditos = (columna) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if (columna.id == null || columna.id == undefined || columna.id < 0) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Número de línea de crédito inválido.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        sessionStorage.setItem("idLineaCredito", columna.id);
        window.location.href = "sub-creditos/" + columna.id;
    };
    /**
     * 
     * @param {*} columna 
     **/
    abrirModalCancelar = (columna) => {
        let idUsuarioLogin = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        let idEstado = REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD;
        let titulo = "¿Estás seguro que desea desestimar esta línea de crédito?";
        let texto = "Una vez desestimado, ¡no podrá revertir este cambio!";
        let mensajeExitoso = "¡Línea de crédito desestimada con éxito!";
        if (idOperadora == REACT_APP_OPERADORA_FODEMIPYME) {
            titulo = "¿Estás seguro que desea rechazar esta línea de crédito?";
            texto = "Una vez rechazado, ¡no podrá revertir este cambio!";
            mensajeExitoso = "¡Línea de crédito rechazada con éxito!";
            idEstado = REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
        }
        let data = {
            Id: columna.id,
            IdEstado: idEstado,
            IdEjecutivo: idUsuarioLogin,
            IdTipoPrograma: columna.tipoPrograma.id,
            IdPrograma: columna.programa.id,
            IdEntidadFinancieraAutorizada: columna.programa.cliente.id,
            IdMoneda: columna.moneda.id,
        };
        swal({
            title: titulo,
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("cancelando....");
                ModificarAval(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        swal(fondoSeleccionado.nombreFondo, mensajeExitoso, "success", { button: "Aceptar", }).then((value) => {
                            let oTable = $("#tabla").DataTable();
                            oTable.draw();
                        });
                    }
                });
            }
        });
    };
    /**
     * 
     * @param {*} columna 
     * @returns Redirecciona a la vista de editar aval
     **/
    editar = (columna) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        sessionStorage.setItem("infoLineaCreditoEditar", JSON.stringify(columna));
        window.location.href = "linea-credito/" + columna.id;
    };
    /**
     * 
     * @param {*} event 
     * @returns Mostrar modal para la carga masiva
     **/
    abrirModalCargarMasiva = (event) => {
        event.preventDefault();
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.state.mostrarModalCargaMasiva = true;
        this.setState({ mostrarModalCargaMasiva: true });
    };
    /**
     * 
     * @param {*} event 
     * @returns Alamacena en el State el archivo de excel de la carga masiva. 
     **/
    encargarseCambioExcel = (event) => {
        if (event.target.files.length === 0) {
            this.state.nombreArchivCargaMasiva = null;
            this.setState({ [event.target.name]: null });
            return;
        }
        if (event.target.files[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            swal(
                "FODEMIPYME",
                "El archivo tiene que ser de formato Excel",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.setState({
            nombreArchivCargaMasiva: event.target.files[0],
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Actualiza el saldo
     **/
    actualizarAvalIndividual = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        if (
            this.state.numeroOperacion === "" ||
            this.state.numeroOperacion == null
        ) {
            swal(
                "Carga Masiva Individual",
                "El número de operación es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.naturaleza != REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
            swal(
                "Carga Masiva Individual",
                "Solo se puede actualizar saldos a los subcréditos",
                "error", { button: "Aceptar", }
            );
            return;
        }
        let data = {
            IdEntidadFinanciera: parseInt(idOperadora),
            IdEjecutivo: idUsuarioCrea,
            NumeroOperacion: this.state.numeroOperacion,
            IdPrograma: parseInt(this.state.programaSeleccionado.id),
        };
        this.setState({ indButton: true });
        CargaMensualIndividualAval(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    this.setState({ indButton: false });
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                swal(
                    fondoSeleccionado.nombreFondo,
                    "¡Línea de crédito actualizada con éxito!",
                    "success", { button: "Aceptar", }
                ).then((value) => {
                    this.cerrarModalCargaIndividual();
                    let oTable = $("#tabla").DataTable();
                    oTable.draw();
                });
                this.setState({ indButton: false });
            }
            this.setState({ indButton: false });
        });
    };
    /**
     * 
     * @param {*} event 
     * @returns Ejecuta carga masiva
     **/
    enviarArchivoExcel = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        if (this.state.nombreArchivCargaMasiva == null) {
            swal(fondoSeleccionado.nombreFondo, "El archivo es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (
            this.state.nombreArchivCargaMasiva.type !=
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
            swal(fondoSeleccionado.nombreFondo, "El archivo tiene que ser de formato Excel", "warning", { button: "Aceptar", });
            return;
        }
        let data = {
            IdEntidadFinanciera: parseInt(idOperadora),
            IdEjecutivo: idUsuarioCrea,
            ArchivoExcel: this.state.nombreArchivCargaMasiva,
        };
        this.setState({ indButton: true });
        CargaMensualAval(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    this.setState({ indButton: false });
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                swal(fondoSeleccionado.nombreFondo, "¡Archivo subido con éxito!", "success", { button: "Aceptar", });
                this.cerrarModalCargaMasiva();
                this.setState({ indButton: false });
            }
            this.setState({ indButton: false });
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.abrirModalCargarMasiva}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Carga Masiva Subcréditos
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCargaMasiva}
                            onHide={this.cerrarModalCargaMasiva}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Masiva Subcréditos</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <Form.Label>
                                    {" "}
                                    <strong>Carga archivo Excel</strong>
                                </Form.Label>
                                <Form.Group>
                                    <Form.File
                                        onChange={this.encargarseCambioExcel}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaMasiva}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.enviarArchivoExcel}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Cargar archivo
                                    {" "}
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_INCLUSION
            case "3": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_OPERATIVO
            case "4": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.abrirModalCargarMasiva}
                                hidden={!this.state.indMostrarCargaMasiva}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Carga Masiva Subcréditos
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCargaMasiva}
                            onHide={this.cerrarModalCargaMasiva}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Masiva Subcréditos</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <Form.Label>
                                    {" "}
                                    <strong>Carga archivo Excel</strong>
                                </Form.Label>
                                <Form.Group>
                                    <Form.File
                                        onChange={this.encargarseCambioExcel}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaMasiva}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.enviarArchivoExcel}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Cargar archivo
                                    {" "}
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.mostrarModalCargaIndividual}
                            onHide={this.cerrarModalCargaIndividual}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Individual</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <p>Monto del crédito:</p>
                                <h5>
                                    {" "}
                                    {this.state.simboloMoneda}
                                    {this.state.montoCredito
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </h5>
                                <p>Porcentaje aval solicitado:</p>
                                <h5> {this.state.porcAval} %</h5>
                                <p>Saldo del crédito:</p>
                                <h5>
                                    {" "}
                                    {this.state.simboloMoneda}
                                    {this.state.saldoCredito
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </h5>
                                <p>Saldo del aval:</p>
                                <h5>
                                    {" "}
                                    {this.state.simboloMoneda}
                                    {this.state.saldoAval
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </h5>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="secondary"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaIndividual}
                                >
                                    Cerrar
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.actualizarAvalIndividual}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Actualizar
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_JEFATURA
            case "5": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.abrirModalCargarMasiva}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Carga Masiva Subcréditos
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <Modal
                            show={this.state.mostrarModalCargaMasiva}
                            onHide={this.cerrarModalCargaMasiva}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Carga Masiva Subcréditos</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {" "}
                                <Form.Label>
                                    {" "}
                                    <strong>Carga archivo Excel</strong>
                                </Form.Label>
                                <Form.Group>
                                    <Form.File
                                        onChange={this.encargarseCambioExcel}
                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    />
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="danger"
                                    disabled={this.state.indButton}
                                    onClick={this.cerrarModalCargaMasiva}
                                >
                                    Cerrar
                                    {" "}
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </Button>
                                <Button
                                    variant="primary"
                                    disabled={this.state.indButton}
                                    onClick={this.enviarArchivoExcel}
                                >
                                    <div
                                        hidden={!this.state.indButton}
                                        className="spinner-grow spinner-grow-sm text-light"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    Cargar archivo
                                    {" "}
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Líneas de Créditos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Líneas de Créditos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Moneda</th>
                                        <th>Nombre Solicitante</th>
                                        <th>Identificación Solicitante</th>
                                        <th>% Aval</th>
                                        <th>Monto del Crédito</th>
                                        <th>Monto del Aval</th>
                                        <th>Disponible Saldo del Crédito</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                        <th>SubCrédito</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }
}
export default LineaCreditoComponent;
