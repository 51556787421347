import React, { Component } from "react";
import {
    Container,
    Button,
    Accordion,
    Card,
    Tabs,
    Tab,
    Row,
    Col,
    Form,
} from "react-bootstrap";
import swal from "sweetalert";
import moment from "moment";
import {
    REACT_APP_CATALOGO_OBTENER_TIPOS_PROGRAMAS,
    REACT_APP_CATALOGO_OBTENER_TIPOS_MONEDA,
    REACT_APP_CATALOGO_OBTENER_TIPOS_IDENTIFICACION,
    REACT_APP_CATALOGO_OBTENER_TIPOS_SECTO_ECONOMICO,
    REACT_APP_CATALOGO_OBTENER_TIPOS_NIVELES,
    REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA,
    REACT_APP_CATALOGO_OBTENER_TIPOS_GARANTIAS,
    REACT_APP_CATALOGO_OBTENER_FUENTE_REFERENCIA_INFORMACION,
    REACT_APP_CATALOGO_OBTENER_ESTADO_CCSS,
    REACT_APP_CATALOGO_OBTENER_TRIBUTACION_DIRECTA,
    REACT_APP_CATALOGO_OBTENER_RIESGO_TRABAJO,
    REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA_PYMPA_MAG,
    REACT_APP_CATALOGO_OBTENER_ESTADO_AVAL,
    REACT_APP_URL_DESCARGAR_ARCHIVO,
    REACT_APP_AVAL_ESTADO_SOLICITADO,
    REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_ACEPTADO,
    REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA,
    REACT_APP_AVAL_ESTADO_EN_ATRASO,
    REACT_APP_AVAL_ESTADO_FINALIZADO,
    REACT_APP_AVAL_ESTADO_IRRECUPERABLE,
    REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_FORMALIZADO,
    REACT_APP_AVAL_ESTADO_ARREGLO_PAGO,
    REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO,
    REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA,
    REACT_APP_AVAL_ESTADO_ATRASO_EFA,
    REACT_APP_TIPO_PROGRAMA_INDIVIDUAL,
    REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL,
    REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_400,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_CODIGO_RESPUESTA_404,
    REACT_APP_CODIGO_RESPUESTA_500,
    REACT_APP_CEDULA_BP,
    REACT_APP_OBTENER_LINEAS_CREDITO,
    REACT_APP_TIPO_IDENTIFICACION_PERSONA_FISICA_NACIONAL,
    REACT_APP_TIPO_IDENTIFICACION_PERSONA_JURIDICA_NACIONAL,
    REACT_APP_TIPO_IDENTIFICACION_EXTRANJERO_RESIDENTE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE,
    REACT_APP_NATURALEZA_LINEA_CREDITO,
    REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_CATALOGO_OBTENER_FNA_TIPO_BENEFICIO,
    REACT_APP_CATALOGO_OBTENER_FNA_MEJORAS_CONDICIONES_PAGO,
    REACT_APP_LONGITUD_CAMPO_MONTOS
} from "../../environment";
import { ObtenerCatalogo, GetGenerico } from "../../Services/GenericoService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import {
    obtenerProvincias,
    ObtenerCodigosCIIU,
    ModificarAval,
    ServicioWSRiesgoFodemipyme,
    ObtenerObservacionesXAval,
    AgregarObservacion,
    EditarObservacion,
    ObtenerUsuarioPorID,
    ListarEjecutivosFodemipyme,
    ListarAdministradoresFodemipyme,
    ActualizacionSaldosIndividual,
} from "../../Services/AvalService";
import NumberFormat from "react-number-format";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
const $ = require("jquery");
const idRol = localStorage.getItem("idRol");
const idUsuarioConectado = localStorage.getItem("idUsuarioCreador");
const nombreUsuarioConectado = localStorage.getItem("usuarioLDAP");
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
const idFondo = localStorage.getItem("idFondo");
const userAgent = ObtenerUserAgent();
class EditarLineaCreditoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            numeroOperacion: "",
            monedasLista: [],
            monedaSeleccionada: "",
            tiposProgramasLista: [],
            tipoProgramaSeleccionado: "",
            tiposSolicitudLista: [
                {
                    id: 0,
                    valor: "Aval",
                },
                {
                    id: 1,
                    valor: "Línea de Crédito",
                },
                {
                    id: 2,
                    valor: "Aval + Línea de Crédito",
                },
            ],
            idNaturaleza: 0,
            tipoSolicitudSeleccionado: "",
            programasLista: [],
            programaSeleccionado: "",
            idProgramaSeleccionado: 0,
            lineasCreditoLista: [],
            lineaCreditoSeleccionada: "",
            estadosLista: [],
            estadoSeleccionado: "",
            fechaCreacion: moment().locale("en").format("YYYY-MM-DD"),
            montoCredito: "",
            idOficio: "",
            nombreSolicitante: "",
            tiposIdentificacionLista: [],
            tiposIdentificacionSeleccionada: "",
            numeroIdentificacionSolicitante: "",
            tablaGarantiasAportadas: [],
            provinciaLista: [],
            provinciaSeleccionada: "",
            cantonLista: [],
            cantonSeleccionado: "",
            distritoLista: [],
            distritoSeleccionado: "",
            direccion: "",
            actividadEconomica: "",
            sectorEconomicoLista: [],
            sectorEconomicoSeleccionado: "",
            codigoCIIU: "",
            codigoCIIULista: [],
            codigoCIIUSeleccionado: "",
            divisionCIIULista: [],
            divisionCIIUSeleccionado: "",
            grupoCIIULista: [],
            grupoCIIUSeleccionado: "",
            seccionCIIULista: [],
            seccionCIIUSeleccionado: "",
            claseCIIUULista: [],
            claseCIIUUSeleccionado: "",
            ciiuuLista: [],
            ciiuSeleccionado: "",
            nivelPagoHistoricoSBDLista: [],
            nivelPagoHistoricoSBDSeleccionado: "",
            puntajeFinalDeudorSBD: "",
            nivelCICLista: [],
            nivelCICSeleccionado: "",
            puntajeCIC: "",
            nivelHistoricoPagoEntidadLista: [],
            nivelHistoricoPagoEntidadSeleccionado: "",
            tamanoEmpresaLista: [],
            tamanoEmpresaSeleccionada: "",
            tipoGarantiaAdicionalLista: [],
            tipoGarantiaAdicionalSeleccionada: "",
            annosExperienciaEmpresa: "",
            impactoEmpleoMujeres: 0,
            impactoEmpleoHombres: 0,
            totalImpactoEmpleo: 0,
            planInversion: "",
            totalIngresoAnual: "",
            totalEgresoAnual: "",
            montoFlujoOperativo: "",
            fuenteReferenciaInformacionIngresosLista: [],
            fuenteReferenciaInformacionIngresosSeleccionado: "",
            fuenteReferenciaInformacionEgresosLista: [],
            fuenteReferenciaInformacionEgresosSeleccionado: "",
            cuotaMensualCreditoCIC: "",
            cuotaMensualOtrosPrestamo: "",
            totalCuotasMensual: "",
            montoAnualCreditoCIC: "",
            montoAnualOtrosPrestamos: "",
            montoTotalCuotasAnuales: "",
            garantias: [],
            montoTotalGarantiasAportadasCliente: 0,
            porcentajeTotalGarantiasAportadasSolicitante: 0,
            montoTotalAvalSolicitado: 0,
            porcentajeTotalAvalSolicitado: 0,
            totalAvalYGarantias: 0,
            porcentajeTotalAvalYGarantias: 0,
            tasaInteresAnual: "",
            plazoCreditoMeses: "",
            cuotaPeriodica: "",
            frecuenciaPeriodica: "",
            cuotaMensualNuevoCredito: "",
            cuotaAnualNuevoCredito: "",
            ejecutivoCreadorLista: [],
            ejecutivoCreadorSeleccionado: "",
            ejecutivoLista: [],
            ejecutivoSeleccionado: "",
            usuariosListaDatatable: [],
            nombreArchivoFotoCedulaPersonaFisica: null,
            indNombreArchivoFotoCedulaPersonaFisica: false,
            nombreArchivoFotoCedulaPersonaJuridica: null,
            indNombreArchivoFotoCedulaPersonaJuridica: false,
            nombreArchivoFotoCedulaRepresentantePersonaJuridica: null,
            indNombreArchivoFotoCedulaRepresentantePersonaJuridica: false,
            nombreArchivoCertificacionCCSS: null,
            indNombreArchivoCertificacionCCSS: null,
            estadoCertificacionCCSSLista: [],
            estadoCertificacionCCSSSeleccionado: "",
            fechaEmisionCertificacionCCSS: moment().locale("en").format("YYYY-MM-DD"),
            nombreArchivoTributacionDirecta: null,
            indNombreArchivoTributacionDirecta: null,
            estadoTributacionDirectaLista: [],
            estadoTributacionDirectaSeleccionada: "",
            nombreArchivoRiesgosTrabajo: null,
            indNombreArchivoRiesgosTrabajo: null,
            riesgosTrabajoLista: [],
            riesgosTrabajoSeleccionado: "",
            nombreArchivoCertificacionPymePympa: null,
            indNombreArchivoCertificacionPymePympa: null,
            fechaVencimientoCertificacionPymePympa: moment()
                .locale("en")
                .format("YYYY-MM-DD"),
            tamanoPymePympaLista: [],
            tamanoPymePympaSeleccionado: "",
            consultaAbiertaCICSUGEF: false,
            consultaAbiertaCICSUGEFLista: [
                {
                    id: 0,
                    valor: "Si",
                },
                {
                    id: 1,
                    valor: "No",
                },
            ],
            consultaAbiertaCICSUGEFSeleccionado: "",
            nombreArchivoActaAprobacionCredito: null,
            indNombreArchivoActaAprobacionCredito: null,
            nombreArchivoCICSugef: null,
            indNombreArchivoCICSugef: null,
            nombreArchivoEstudioRegistralBienesMuebles: null,
            indNombreArchivoEstudioRegistralBienesMuebles: null,
            nombreArchivoExcluirGarantiaBienMuebleInmueble: null,
            indNombreArchivoExcluirGarantiaBienMuebleInmueble: null,
            nombreArchivoFacturaProforma: null,
            indNombreArchivoFacturaProforma: null,
            nombreArchivoInformeAvaluo: null,
            indNombreArchivoInformeAvaluo: null,
            observacion: "",
            historicoEstadosLista: [],
            historicoEstadosSeleccionado: "",
            descripcionGarantia: "",
            valorGarantia: 0,
            porcentajeMaximoAceptacion: "",
            montoMaximoAceptacion: 0,
            montoDefinitivoResponsabilidad: 0,
            porcentajeResponsabilidad: 0,
            indIrPaso2: false,
            indIrPaso3: false,
            llave: "informacionBasica",
            indButton: false,
            tipoGarantiaSeleccionadaTabla: "",
            token: "",
            idConsultaAbierta: 0,
            fechaConstitucion: null,
            idOperadora: 0,
            indNoEditar: false,
            indNoEditarEstado: false,
            indNoEditarEjecutivo: false,
            indNoEditarObservaciones: false,
            indCambioFechaConstitucion: false,
            indCambioFechaVencimienrtoPYMEPYMPA: false,
            tipoCreditoLista: [
                {
                    id: 0,
                    valor: "Normal",
                },
                {
                    id: 1,
                    valor: "Ballon",
                },
            ],
            tipoCreditoSeleccionado: "",
            plazoBallon: "",
            indPlazoBallon: false,
            numeroSolicitudBancaria: "",
            numeroOperacionBancaria: "",
            indEntidadBPFODEMIPYME: false,
            historicoLista: [],
            historicoSeleccionado: "",
            historicoArregloPagoLista: [],
            historicoArregloPagoSeleccionado: "",
            clienteSeleccionado: "",
            nombreArchivoContrato: null,
            indNombreArchivoContrato: null,
            nombreArchivoPagare: null,
            indNombreArchivoPagare: null,
            nombreArchivoComprobantePagoComisionFormalizacion: null,
            indNombreArchivoComprobantePagoComisionFormalizacion: null,
            saldoAval: "",
            saldoCredito: "",
            historicoPagos: [],
            indMostrarHistoricoPagos: false,
            historicoObservaciones: [],
            indMostrarHistoricoObservaciones: false,
            indEditarObservaciones: false,
            idObservacion: 0,
            idUsuarioAsignado: 0,
            usuarioAsignado: "",
            indDocumentacionFormalizacion: true,
            idInclusorReciente: null,
            idJefaturaReciente: null,
            idOperativoReciente: null,
            idEjecutivoFodemipymeReciente: null,
            idAdminFodemipymeReciente: null,
            columnas: [
                {
                    dataField: "estadoOrigen.valor",
                    text: "Estado Origen",
                    sort: false,
                },
                {
                    dataField: "estadoDestino.valor",
                    text: "Estado Destino",
                    sort: false,
                },
                {
                    dataField: "usuarioOrigen.correoElectronico",
                    text: "Usuario Origen",
                    sort: false,
                },
                {
                    dataField: "usuarioDestino.correoElectronico",
                    text: "Usuario Destino",
                    sort: false,
                },
                {
                    dataField: "fechaAsignacion",
                    text: "Fecha Asignación",
                    sort: false,
                },
            ],
            columnasArregloPago: [
                {
                    dataField: "observacion",
                    text: "Detalle Arreglo de Pago",
                    sort: false,
                },
                {
                    dataField: "estadoDestino.valor",
                    text: "Estado",
                    sort: false,
                },
                {
                    dataField: "fechaAsignacion",
                    text: "Fecha Arreglo Pago",
                    sort: false,
                },
            ],
            tipoCambio: 0,
            tieneMora: false,
            dia: null,
            indNumeroOperacion: true,
            finDeSemana: false,
            fechaHabiles: null,
            diaHabilPermitido: 0,
            indicadorActTotalSaldos: false,
            indicadorComisionAdministracion: false,
            titulo: "Línea de Crédito",
            diferenciasMonto: 0,
            diferenciaPorcentaje: 0,
            indicadorModificarMontoCredito: false,
            //FNA
            fnaTipoBeneficionLista: [],
            fnaTipoBeneficioSeleccionado: "",
            mejoraCondicionesLista: [],
            mejoraCondicionesSeleccionado: "",
            indCondicionesPago: false,
            indDescripcionMejoraCondicionesPago: false,
            //Campo 1
            idTiposBeneficios: [],
            idMejoraCondicion: 0,
            descripcionMejoraCondicion: "",
            //Campo 2
            demostroAfectacionCOVID: true,
            valueDemostroAfectacionCOVID: "",
            //Campo 3
            realizaActividadDentroDelPais: true,
            valueRealizaActividadDentroDelPais: "",
            //Campo 4
            compromisoConservacionEmpleo: true,
            valueCompromisoConservacionEmpleo: "",
            //Campo 5
            beneficiaroPerteneceConglomerado: false,
            valueBeneficiaroPerteneceConglomerado: "",
            //Campo 6
            morosidadPrevioCOVIDMenor60: true,
            valueMorosidadPrevioCOVIDMenor60: "",
            //Campo 7
            avalSustituyeGarantia: false,
            valueAvalSustituyeGarantia: "",
            //Campo 8
            clienteConCapacidadPago: true,
            valueClienteConCapacidadPago: "",
            //Campo 9
            archivoCuadroComparativo: null,
            indNombreArchivoCuadroComparativo: false,
            totalMontoPagado: 0,
            totalComisionMensual: 0
        };
    }
    componentDidMount() {
        localStorage.removeItem("programasIndividualesLista");
        localStorage.removeItem("programasCarteraLista");
        localStorage.removeItem("programasCarteraPELista");
        localStorage.removeItem("lineasCreditoDisponibleLista");
        localStorage.removeItem("ejecutivoLista");
        $("#floppy").show();
        $("#guardando").hide();
        $("#programas").hide();
        $("#spinProgramas").show();
        $("#spinUsuarios").show();
        $("#lineasCredito").hide();
        $("#indEstados").show();
        $("#indEjecutivos").show();
        $("#tabla_garantias").show();
        $("#botonModificar").show();
        $("#nombreArchivoInformeAvaluo").hide();
        $("#nombreArchivoFacturaProforma").hide();
        $("#nombreArchivoExcluirGarantiaBienMuebleInmueble").hide();
        $("#nombreArchivoEstudioRegistralBienesMuebles").hide();
        $("#nombreArchivoActaAprobacionCredito").hide();
        $("#nombreArchivoCertificacionPymePympa").hide();
        $("#nombreArchivoRiesgosTrabajo").hide();
        $("#nombreArchivoTributacionDirecta").hide();
        $("#nombreArchivoCertificacionCCSS").hide();
        $("#nombreArchivoFotoCedulaRepresentantePersonaJuridica").hide();
        $("#nombreArchivoFotoCedulaPersonaJuridica").hide();
        $("#nombreArchivoFotoCedulaPersonaFisica").hide();
        $("#nombreArchivoContrato").hide();
        $("#nombreArchivoPagare").hide();
        $("#nombreArchivoComprobantePagoComisionFormalizacion").hide();
        $("#indOcultarGarantias").hide();
        $("#spinMejoraCondiciones").hide();
        $("#nombreArchivoCuadroComparativo").hide();
        $("#nombreArchivoCICSugef").hide();
        const idRol = localStorage.getItem("idRol");
        const fecha = new Date();
        const dia = fecha.getDate();

        const numeroDia = fecha.getDay();
        var mesActual = new Date().getMonth() + 1;
        var fechaTemporal = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
        var diaTrabajo = 7;

        while (diaTrabajo > 0) {
            fechaTemporal = new Date(fechaTemporal.getFullYear(), mesActual - 1, fechaTemporal.getDate() + 1);
            var numeroDiaFechaTemporal = fechaTemporal.getDay();
            if (numeroDiaFechaTemporal > 0 && numeroDiaFechaTemporal < 6) {
                diaTrabajo = diaTrabajo - 1;
            }
        }
        this.state.fechaHabiles = fechaTemporal;
        this.setState({ fechaHabiles: fechaTemporal });

        this.state.diaHabilPermitido = fechaTemporal.getDate();
        this.setState({ diaHabilPermitido: fechaTemporal.getDate() });

        if (numeroDia == 6 || numeroDia == 0) {
            this.state.finDeSemana = true;
            this.setState({ finDeSemana: true });
        }


        const tokenContent = localStorage.getItem("isLoggedIn");
        const infoDataTable = localStorage.getItem("datatable");
        const infoDataTableJson = JSON.parse(infoDataTable);
        var honramientoInfoAval = null;
        if (infoDataTableJson != null) {
            var rutaMatriz = window.location.pathname.split('/');
            var idAval = parseInt(rutaMatriz[2]);

            var listaAvales = [];

            listaAvales = infoDataTableJson.filter(function (
                honramiento
            ) {
                return honramiento.aval.id == idAval;
            });

            honramientoInfoAval = listaAvales[0].aval;
            sessionStorage.setItem("infoLineaCreditoEditar", JSON.stringify(honramientoInfoAval));
        }

        const infoEditar = sessionStorage.getItem("infoLineaCreditoEditar");
        const idOperadora = localStorage.getItem("idOperadora");
        if (!tokenContent) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        this.state.token = tokenContent;
        this.setState({ token: tokenContent });
        this.state.idOperadora = idOperadora;
        this.setState({ idOperadora: idOperadora });
        if (!infoEditar) window.location.href = "/lineas-creditos";
        const monedaLista = localStorage.getItem("monedaLista");
        const fnaTipoBeneficioLista = localStorage.getItem("fnaTipoBeneficioLista");
        const estadosLista = localStorage.getItem("estadosLista");
        const ejecutivoLista = localStorage.getItem("ejecutivoLista");
        const tiposProgramasLista = localStorage.getItem("tiposProgramasLista");
        const tiposIdentificacionLista = localStorage.getItem(
            "tiposIdentificacionLista"
        );
        const sectorEconomicoLista = localStorage.getItem("sectorEconomicoLista");
        const nivelesLista = localStorage.getItem("nivelesLista");
        const tipoGarantiaAdicionalLista = localStorage.getItem(
            "tipoGarantiaAdicionalLista"
        );
        const fuenteReferenciaInformacionLista = localStorage.getItem(
            "fuenteReferenciaInformacionLista"
        );
        const ubicacionGeograficaLista = localStorage.getItem(
            "ubicacionGeograficaLista"
        );
        const estadoCertificacionCCSSLista = localStorage.getItem(
            "estadoCertificacionCCSSLista"
        );
        const estadoTributacionDirectaLista = localStorage.getItem(
            "estadoTributacionDirectaLista"
        );
        const riesgosTrabajoLista = localStorage.getItem("riesgosTrabajoLista");
        const tamanoPymePympaLista = localStorage.getItem("tamanoPymePympaLista");
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const estadosListaJson = JSON.parse(estadosLista);
        const ejecutivoListaJson = JSON.parse(ejecutivoLista);
        const monedaListaJson = JSON.parse(monedaLista);
        const fnaTipoBeneficioListaJson = JSON.parse(fnaTipoBeneficioLista);
        const tiposProgramasListaJson = JSON.parse(tiposProgramasLista);
        const tiposIdentificacionListaJson = JSON.parse(tiposIdentificacionLista);

        const nivelesListaJson = JSON.parse(nivelesLista);
        const tipoGarantiaAdicionalListaJson = JSON.parse(
            tipoGarantiaAdicionalLista
        );
        const fuenteReferenciaInformacionListaJson = JSON.parse(
            fuenteReferenciaInformacionLista
        );
        const estadoCertificacionCCSSListaJson = JSON.parse(
            estadoCertificacionCCSSLista
        );
        const estadoTributacionDirectaListaJson = JSON.parse(
            estadoTributacionDirectaLista
        );
        const riesgosTrabajoListaJson = JSON.parse(riesgosTrabajoLista);
        const tamanoPymePympaListaJson = JSON.parse(tamanoPymePympaLista);
        const ubicacionGeograficaListaJson = JSON.parse(ubicacionGeograficaLista);
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        const sectorEconomicoListaJson = JSON.parse(sectorEconomicoLista);
        const infoEditarJson = JSON.parse(infoEditar);
        if (infoEditarJson != null) {
            this.state.dia = dia;
            this.setState({ dia: dia });

            this.state.idAdminFodemipymeReciente =
                infoEditarJson.idAdminFodemipymeReciente;
            this.setState({
                idAdminFodemipymeReciente: infoEditarJson.idAdminFodemipymeReciente,
            });

            this.state.idEjecutivoFodemipymeReciente =
                infoEditarJson.idEjecutivoFodemipymeReciente;
            this.setState({
                idEjecutivoFodemipymeReciente:
                    infoEditarJson.idEjecutivoFodemipymeReciente,
            });

            this.state.idInclusorReciente = infoEditarJson.idInclusorReciente;
            this.setState({ idInclusorReciente: infoEditarJson.idInclusorReciente });

            this.state.idJefaturaReciente = infoEditarJson.idJefaturaReciente;
            this.setState({ idJefaturaReciente: infoEditarJson.idJefaturaReciente });

            this.state.idOperativoReciente = infoEditarJson.idOperativoReciente;
            this.setState({
                idOperativoReciente: infoEditarJson.idOperativoReciente,
            });

            this.state.idUsuarioAsignado = infoEditarJson.ejecutivoCreador.b2CId;
            this.setState({ idUsuarioAsignado: infoEditarJson.ejecutivoCreador.b2CId });

            this.state.usuarioAsignado = infoEditarJson.ejecutivoCreador;
            this.setState({ usuarioAsignado: infoEditarJson.ejecutivoCreador });

            this.state.plazoBallon = infoEditarJson.plazoBallonMeses;
            this.setState({ plazoBallon: infoEditarJson.plazoBallonMeses });

            this.setState({ indPlazoBallon: infoEditarJson.tienePlazoBallon });

            this.state.tipoCambio = infoEditarJson.tipoCambio;
            this.setState({ tipoCambio: infoEditarJson.tipoCambio });

            this.state.tieneMora = infoEditarJson.tieneMora;
            this.setState({ tieneMora: infoEditarJson.tieneMora });

            let tipoCredito = this.state.tipoCreditoLista;
            this.setState({ tipoCreditoSeleccionado: tipoCredito[0] });
            if (infoEditarJson.tienePlazoBallon) {
                this.setState({ tipoCreditoSeleccionado: tipoCredito[1] });
            }

            this.setState({ saldoAval: infoEditarJson.saldoAval.toFixed(2) });
            this.setState({ saldoCredito: infoEditarJson.saldoCredito.toFixed(2) });

            this.setState({ totalComisionMensual: infoEditarJson.totalComisionMensual.toFixed(2) });
            this.setState({ totalMontoPagado: infoEditarJson.totalMontoPagado.toFixed(2) });

            this.setState({
                clienteSeleccionado: infoEditarJson.entidadFinancieraAutorizada,
            });
            this.setState({
                numeroSolicitudBancaria: infoEditarJson.numeroSolicitudBancaria,
            });
            this.setState({
                numeroOperacionBancaria: infoEditarJson.numeroOperacionBancaria,
            });
            if (infoEditarJson.nombreArchivoFotoCedulaPersonaFisica != null) {
                this.setState({ indNombreArchivoFotoCedulaPersonaFisica: true });
            }
            this.setState({
                nombreArchivoFotoCedulaPersonaFisica:
                    infoEditarJson.nombreArchivoFotoCedulaPersonaFisica,
            });
            if (infoEditarJson.nombreArchivoFotoCedulaPersonaJuridica != null) {
                this.setState({
                    indNombreArchivoFotoCedulaPersonaJuridica: true,
                });
            }
            this.setState({
                nombreArchivoFotoCedulaPersonaJuridica:
                    infoEditarJson.nombreArchivoFotoCedulaPersonaJuridica,
            });
            if (
                infoEditarJson.nombreArchivoFotoCedulaRepresentantePersonaJuridica !=
                null
            ) {
                this.setState({
                    indNombreArchivoFotoCedulaRepresentantePersonaJuridica: true,
                });
            }
            this.setState({
                nombreArchivoFotoCedulaRepresentantePersonaJuridica:
                    infoEditarJson.nombreArchivoFotoCedulaRepresentantePersonaJuridica,
            });
            if (infoEditarJson.nombreArchivoCertificacionCCSS != null) {
                this.setState({
                    indNombreArchivoCertificacionCCSS: true,
                });
            }
            this.setState({
                nombreArchivoCertificacionCCSS:
                    infoEditarJson.nombreArchivoCertificacionCCSS,
            });

            if (infoEditarJson.nombreArchivoCuadroComparativo != null) {
                this.setState({
                    indNombreArchivoCuadroComparativo: true,
                });
            }
            this.setState({
                archivoCuadroComparativo:
                    infoEditarJson.nombreArchivoCuadroComparativo,
            });

            if (infoEditarJson.nombreArchivoTributacionDirecta != null) {
                this.setState({
                    indNombreArchivoTributacionDirecta: true,
                });
            }
            this.setState({
                nombreArchivoTributacionDirecta:
                    infoEditarJson.nombreArchivoTributacionDirecta,
            });
            if (infoEditarJson.nombreArchivoRiesgosTrabajo != null) {
                this.setState({
                    indNombreArchivoRiesgosTrabajo: true,
                });
            }
            this.setState({
                nombreArchivoRiesgosTrabajo: infoEditarJson.nombreArchivoRiesgosTrabajo,
            });
            if (infoEditarJson.nombreArchivoCertificacionPymePympa != null) {
                this.setState({
                    indNombreArchivoCertificacionPymePympa: true,
                });
            }
            this.setState({
                nombreArchivoCertificacionPymePympa:
                    infoEditarJson.nombreArchivoCertificacionPymePympa,
            });
            if (infoEditarJson.nombreArchivoActaAprobacionCredito != null) {
                this.setState({
                    indNombreArchivoActaAprobacionCredito: true,
                });
            }
            this.setState({
                nombreArchivoActaAprobacionCredito:
                    infoEditarJson.nombreArchivoActaAprobacionCredito,
            });

            if (infoEditarJson.nombreArchivoCICSugef != null) {
                this.setState({
                    indNombreArchivoCICSugef: true,
                });
            }
            this.setState({
                nombreArchivoCICSugef:
                    infoEditarJson.nombreArchivoCICSugef,
            });

            if (infoEditarJson.nombreArchivoEstudioRegistralBienesMuebles != null) {
                this.setState({
                    indNombreArchivoEstudioRegistralBienesMuebles: true,
                });
            }
            this.setState({
                nombreArchivoEstudioRegistralBienesMuebles:
                    infoEditarJson.nombreArchivoEstudioRegistralBienesMuebles,
            });
            if (
                infoEditarJson.nombreArchivoExcluirGarantiaBienMuebleInmueble != null
            ) {
                this.setState({
                    indNombreArchivoExcluirGarantiaBienMuebleInmueble: true,
                });
            }
            this.setState({
                nombreArchivoExcluirGarantiaBienMuebleInmueble:
                    infoEditarJson.nombreArchivoExcluirGarantiaBienMuebleInmueble,
            });
            if (infoEditarJson.nombreArchivoFacturaProforma != null) {
                this.setState({
                    indNombreArchivoFacturaProforma: true,
                });
            }
            this.setState({
                nombreArchivoFacturaProforma:
                    infoEditarJson.nombreArchivoFacturaProforma,
            });
            if (infoEditarJson.nombreArchivoInformeAvaluo != null) {
                this.setState({
                    indNombreArchivoInformeAvaluo: true,
                });
            }
            this.setState({
                nombreArchivoInformeAvaluo: infoEditarJson.nombreArchivoInformeAvaluo,
            });

            if (infoEditarJson.nombreArchivoContrato) {
                this.setState({ indNombreArchivoContrato: true });
            }
            this.setState({
                nombreArchivoContrato: infoEditarJson.nombreArchivoContrato,
            });

            if (infoEditarJson.nombreArchivoPagare) {
                this.setState({ indNombreArchivoPagare: true });
            }
            this.setState({
                nombreArchivoPagare: infoEditarJson.nombreArchivoPagare,
            });

            if (infoEditarJson.nombreArchivoComprobantePagoComisionFormalizacion) {
                this.setState({
                    indNombreArchivoComprobantePagoComisionFormalizacion: true,
                });
            }
            this.setState({
                nombreArchivoComprobantePagoComisionFormalizacion:
                    infoEditarJson.nombreArchivoComprobantePagoComisionFormalizacion,
            });
            this.state.id = infoEditarJson.id;
            this.setState({ id: infoEditarJson.id });
            this.setState({ montoCredito: infoEditarJson.montoCredito.toFixed(2) });
            this.state.idOficio = infoEditarJson.oficioCondicionPYMEPYMPA;
            this.setState({
                idOficio: infoEditarJson.oficioCondicionPYMEPYMPA,
            });
            this.setState({
                numeroOperacion: infoEditarJson.numeroOperacion,
            });
            this.setState({
                nombreSolicitante: infoEditarJson.nombreSolicitante,
            });
            this.state.numeroIdentificacionSolicitante =
                infoEditarJson.numeroIdentificacionSolicitante;
            this.setState({
                numeroIdentificacionSolicitante:
                    infoEditarJson.numeroIdentificacionSolicitante,
            });
            this.setState({
                direccion: infoEditarJson.direccion,
            });
            this.setState({
                actividadEconomica: infoEditarJson.actividadEconomica,
            });
            this.state.codigoCIIU = infoEditarJson.codigoCIIU;
            this.setState({
                codigoCIIU: infoEditarJson.codigoCIIU,
            });
            this.state.puntajeFinalDeudorSBD = infoEditarJson.puntajeFinalDeudorSBD;
            this.setState({
                puntajeFinalDeudorSBD: infoEditarJson.puntajeFinalDeudorSBD,
            });
            this.state.puntajeCIC = infoEditarJson.puntajeFinalDeudorCIC;
            this.setState({
                puntajeCIC: infoEditarJson.puntajeFinalDeudorCIC,
            });
            this.state.annosExperienciaEmpresa =
                infoEditarJson.annosExperienciaEmpresa;
            this.setState({
                annosExperienciaEmpresa: infoEditarJson.annosExperienciaEmpresa,
            });
            this.setState({
                impactoEmpleoMujeres: infoEditarJson.impactoEmpleoMujeres,
            });
            this.setState({
                impactoEmpleoHombres: infoEditarJson.impactoEmpleoHombres,
            });
            this.setState({
                totalImpactoEmpleo: infoEditarJson.totalImpactoEmpleo,
            });
            this.setState({
                planInversion: infoEditarJson.planInversion,
            });
            this.setState({
                totalIngresoAnual: infoEditarJson.totalIngresoAnual.toFixed(2),
            });
            this.setState({
                totalEgresoAnual: infoEditarJson.totalEgresoAnual.toFixed(2),
            });
            this.state.montoFlujoOperativo = infoEditarJson.montoFlujoOperativo.toFixed(
                2
            );
            this.setState({
                montoFlujoOperativo: infoEditarJson.montoFlujoOperativo.toFixed(2),
            });
            this.setState({
                cuotaMensualCreditoCIC: infoEditarJson.cuotaMensualCreditoCIC.toFixed(
                    2
                ),
            });
            this.setState({
                montoAnualCreditoCIC: infoEditarJson.montoAnualCreditoCIC.toFixed(2),
            });
            this.setState({
                cuotaMensualOtrosPrestamo: infoEditarJson.cuotaMensualOtrosPrestamo.toFixed(
                    2
                ),
            });
            this.setState({
                montoAnualOtrosPrestamos: infoEditarJson.montoAnualOtrosPrestamos.toFixed(
                    2
                ),
            });
            this.setState({
                totalCuotasMensual: infoEditarJson.totalCuotasMensual.toFixed(2),
            });
            this.state.montoTotalCuotasAnuales = infoEditarJson.montoTotalCuotasAnuales.toFixed(
                2
            );
            this.setState({
                montoTotalCuotasAnuales: infoEditarJson.montoTotalCuotasAnuales.toFixed(
                    2
                ),
            });
            this.setState({
                tablaGarantiasAportadas: infoEditarJson.garantias,
            });

            this.setState({
                historicoPagos: infoEditarJson.pagos,
            });

            if (infoEditarJson.pagos.length > 0) {
                this.setState({ indMostrarHistoricoPagos: true });
            }
            this.setState({
                montoTotalGarantiasAportadasCliente: infoEditarJson.montoTotalGarantiasAportadasCliente.toFixed(
                    2
                ),
            });
            this.setState({
                porcentajeTotalGarantiasAportadasSolicitante: infoEditarJson.porcentajeTotalGarantiasAportadasSolicitante.toFixed(
                    2
                ),
            });
            this.setState({
                montoTotalAvalSolicitado: infoEditarJson.montoTotalAvalSolicitado.toFixed(
                    2
                ),
            });
            this.state.porcentajeTotalAvalSolicitado = infoEditarJson.porcentajeTotalAvalSolicitado.toFixed(
                2
            );
            this.setState({
                porcentajeTotalAvalSolicitado: infoEditarJson.porcentajeTotalAvalSolicitado.toFixed(
                    2
                ),
            });
            this.setState({
                tasaInteresAnual: infoEditarJson.tasaInteresAnual,
            });
            this.setState({
                plazoCreditoMeses: infoEditarJson.plazoCreditoMeses,
            });
            this.setState({
                cuotaPeriodica: infoEditarJson.cuotaPeriodicaNuevoCredito,
            });
            this.setState({
                frecuenciaPeriodica: infoEditarJson.frecuenciaPeriodicaCuota,
            });
            this.setState({
                cuotaMensualNuevoCredito: infoEditarJson.cuotaMensualNuevoCredito,
            });
            this.state.cuotaAnualNuevoCredito = infoEditarJson.cuotaAnualNuevoCredito;
            this.setState({
                cuotaAnualNuevoCredito: infoEditarJson.cuotaAnualNuevoCredito,
            });

            if (infoEditarJson.lineaCredito != null) {
                this.state.lineaCreditoSeleccionada = infoEditarJson.lineaCredito;
            }

            if (infoEditarJson.naturaleza == 1) {
                this.setState({
                    idNaturaleza: 1,
                });
            } else if (infoEditarJson.naturaleza == 2) {
                this.setState({
                    idNaturaleza: 2,
                });
                $("#lineasCredito").show();
                const lineasCreditoLista = localStorage.getItem("lineasCreditoDisponibleLista");
                const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
                if (lineasCreditoListaJson == null) {
                    this.cargarLineasCreditos();
                } else {
                    this.setState({
                        lineasCreditoLista: lineasCreditoListaJson,
                    });
                }
            }
            this.state.programaSeleccionado = infoEditarJson.programa;
            this.setState({ programaSeleccionado: infoEditarJson.programa });
            this.state.idProgramaSeleccionado =
                infoEditarJson.programa.detallePrograma.id;

            if (
                this.state.programaSeleccionado.cliente.cedulaJuridica == REACT_APP_CEDULA_BP
            ) {
                this.state.indEntidadBPFODEMIPYME = true;
                this.setState({ indEntidadBPFODEMIPYME: true });
            } else {
                this.state.indEntidadBPFODEMIPYME = false;
                this.setState({ indEntidadBPFODEMIPYME: false });
            }

            this.setState({
                idProgramaSeleccionado: infoEditarJson.programa.detallePrograma.id,
            });
            this.setState({
                provinciaSeleccionada: infoEditarJson.provincia,
            });
            this.setState({
                cantonSeleccionado: infoEditarJson.canton,
            });
            this.setState({
                distritoSeleccionado: infoEditarJson.distrito,
            });

            if (ubicacionGeograficaListaJson == null) {
                this.cargarUbicacionGeografica(
                    infoEditarJson.provincia.id,
                    infoEditarJson.canton.id
                );
            } else {
                this.setState({
                    provinciaLista: ubicacionGeograficaListaJson,
                });
                let provincia = ubicacionGeograficaListaJson.filter(
                    (x) => x.id == infoEditarJson.provincia.id
                );
                this.setState({
                    cantonLista: provincia[0].cantones,
                });
                let canton = provincia[0].cantones.filter(
                    (x) => x.id == infoEditarJson.canton.id
                );
                this.setState({
                    distritoLista: canton[0].distritos,
                });
            }

            this.setState({
                sectorEconomicoSeleccionado: infoEditarJson.sectorEconomico,
            });
            this.setState({
                nivelPagoHistoricoSBDSeleccionado: infoEditarJson.nivelPagoHistoricoSBD,
            });
            this.state.nivelCICSeleccionado = infoEditarJson.nivelPagoHistoricoCIC;
            this.setState({
                nivelCICSeleccionado: infoEditarJson.nivelPagoHistoricoCIC,
            });
            this.setState({
                nivelHistoricoPagoEntidadSeleccionado:
                    infoEditarJson.nivelHistoricoPagoEntidad,
            });
            if (infoEditarJson.consultaAbiertaCICSUGEF) {
                this.state.consultaAbiertaCICSUGEF = true;
                this.setState({ consultaAbiertaCICSUGE: true });
                this.setState({
                    idConsultaAbierta: 0,
                });
            } else {
                this.state.consultaAbiertaCICSUGEF = false;
                this.setState({ consultaAbiertaCICSUGE: false });
                this.setState({
                    idConsultaAbierta: 1,
                });
            }
            //FNA
            //CAMPO 2
            if (infoEditarJson.demostroAfectacionCOVID) {
                this.state.demostroAfectacionCOVID = true;
                this.setState({ demostroAfectacionCOVID: true });
                this.setState({
                    valueDemostroAfectacionCOVID: "SI",
                });
            } else {
                this.state.demostroAfectacionCOVID = false;
                this.setState({ demostroAfectacionCOVID: false });
                this.setState({
                    valueDemostroAfectacionCOVID: "NO",
                });
            }
            //CAMPO 3
            if (infoEditarJson.realizaActividadDentroDelPais) {
                this.state.realizaActividadDentroDelPais = true;
                this.setState({ realizaActividadDentroDelPais: true });
                this.setState({
                    valueRealizaActividadDentroDelPais: "Dentro del país",
                });
            } else {
                this.state.realizaActividadDentroDelPais = false;
                this.setState({ realizaActividadDentroDelPais: false });
                this.setState({
                    valueRealizaActividadDentroDelPais: "Fuera del país",
                });
            }
            //CAMPO 4
            if (infoEditarJson.compromisoConservacionEmpleo) {
                this.state.compromisoConservacionEmpleo = true;
                this.setState({ compromisoConservacionEmpleo: true });
                this.setState({
                    valueCompromisoConservacionEmpleo: "SI",
                });
            } else {
                this.state.compromisoConservacionEmpleo = false;
                this.setState({ compromisoConservacionEmpleo: false });
                this.setState({
                    valueCompromisoConservacionEmpleo: "NO",
                });
            }
            //CAMPO 5
            if (infoEditarJson.beneficiaroPerteneceConglomerado) {
                this.state.beneficiaroPerteneceConglomerado = true;
                this.setState({ beneficiaroPerteneceConglomerado: true });
                this.setState({
                    valueBeneficiaroPerteneceConglomerado: "SI",
                });
            } else {
                this.state.beneficiaroPerteneceConglomerado = false;
                this.setState({ beneficiaroPerteneceConglomerado: false });
                this.setState({
                    valueBeneficiaroPerteneceConglomerado: "NO",
                });
            }
            //CAMPO 6
            if (infoEditarJson.morosidadPrevioCOVIDMenor60) {
                this.state.morosidadPrevioCOVIDMenor60 = true;
                this.setState({ morosidadPrevioCOVIDMenor60: true });
                this.setState({
                    valueMorosidadPrevioCOVIDMenor60: "SI",
                });
            } else {
                this.state.morosidadPrevioCOVIDMenor60 = false;
                this.setState({ morosidadPrevioCOVIDMenor60: false });
                this.setState({
                    valueMorosidadPrevioCOVIDMenor60: "NO",
                });
            }
            //CAMPO 7
            if (infoEditarJson.avalSustituyeGarantia) {
                this.state.avalSustituyeGarantia = true;
                this.setState({ avalSustituyeGarantia: true });
                this.setState({
                    valueAvalSustituyeGarantia: "Sustituir garantía",
                });
            } else {
                this.state.avalSustituyeGarantia = false;
                this.setState({ avalSustituyeGarantia: false });
                this.setState({
                    valueAvalSustituyeGarantia: "Complementar la garantía existente",
                });
            }
            //CAMPO 8
            if (infoEditarJson.clienteConCapacidadPago) {
                this.state.clienteConCapacidadPago = true;
                this.setState({ clienteConCapacidadPago: true });
                this.setState({
                    valueClienteConCapacidadPago: "SI",
                });
            } else {
                this.state.clienteConCapacidadPago = false;
                this.setState({ clienteConCapacidadPago: false });
                this.setState({
                    valueClienteConCapacidadPago: "NO",
                });
            }
            // END FNA
            this.setState({
                sectorEconomicoSeleccionado: infoEditarJson.sectorEconomico,
            });
            if (sectorEconomicoListaJson == null) {
                this.cargarSectorEconomico();
            } else {
                this.setState({
                    sectorEconomicoLista: sectorEconomicoListaJson,
                });
            }
            this.state.nivelPagoHistoricoSBDSeleccionado =
                infoEditarJson.nivelPagoHistoricoSBD;
            this.setState({
                nivelPagoHistoricoSBDSeleccionado: infoEditarJson.nivelPagoHistoricoSBD,
            });
            this.state.nivelHistoricoPagoEntidadSeleccionado =
                infoEditarJson.nivelHistoricoPagoEntidad;
            this.setState({
                nivelHistoricoPagoEntidadSeleccionado:
                    infoEditarJson.nivelHistoricoPagoEntidad,
            });
            if (nivelesListaJson == null) {
                this.cargarNiveles();
            } else {
                this.setState({
                    nivelPagoHistoricoSBDLista: nivelesListaJson,
                });
                this.setState({
                    nivelCICLista: nivelesListaJson,
                });

                this.setState({
                    nivelHistoricoPagoEntidadLista: nivelesListaJson,
                });
            }
            this.state.tamanoEmpresaSeleccionada = infoEditarJson.tamanoEmpresa;
            this.setState({
                tamanoEmpresaSeleccionada: infoEditarJson.tamanoEmpresa,
            });
            this.cargarTamanoEmpresa();
            this.state.tipoGarantiaAdicionalSeleccionada =
                infoEditarJson.tipoGarantia;
            this.setState({
                tipoGarantiaAdicionalSeleccionada: infoEditarJson.tipoGarantia,
            });
            if (tipoGarantiaAdicionalListaJson == null) {
                this.cargarTipoGarantia();
            } else {
                this.setState({
                    tipoGarantiaAdicionalLista: tipoGarantiaAdicionalListaJson,
                });
                this.setState({
                    tipoGarantiaSeleccionadaTabla: tipoGarantiaAdicionalListaJson[0],
                });
            }
            this.setState({
                monedaSeleccionada: infoEditarJson.moneda,
            });
            if (monedaListaJson == null) {
                this.cargarMoneda();
            } else {
                this.setState({
                    monedasLista: monedaListaJson,
                });
            }
            if (idFondo == REACT_APP_OPERADORA_FNA) {
                let selectecionarOpcion = [];

                for (let i = 0; i < infoEditarJson.beneficiosSeleccionados.length; i++) {
                    selectecionarOpcion.push(infoEditarJson.beneficiosSeleccionados[i].id);
                }
                this.state.idTiposBeneficios = selectecionarOpcion;
                this.setState({ idTiposBeneficios: selectecionarOpcion });

                let tipoBeneficioMejora = selectecionarOpcion.filter(
                    (x) => x == 95
                );
                let tipoBeneficioCualquierOtra = selectecionarOpcion.filter(
                    (x) => x == 96
                );
                if (tipoBeneficioMejora.length > 0) {
                    this.state.indCondicionesPago = true;
                    this.state.mejoraCondicionesSeleccionado = infoEditarJson.mejoraCondicion;
                    this.setState({ mejoraCondicionesSeleccionado: infoEditarJson.mejoraCondicion });
                    const mejoraCondicionesListaJson = JSON.parse(localStorage.getItem("mejoraCondicionesLista"));
                    if (mejoraCondicionesListaJson == null) {
                        this.cargarMejorasCondicionesPago();
                    } else {
                        this.state.mejoraCondicionesLista = mejoraCondicionesListaJson;
                    }
                } else {
                    this.state.indCondicionesPago = false;
                }

                if (tipoBeneficioCualquierOtra.length > 0) {
                    this.state.indDescripcionMejoraCondicionesPago = true;
                    this.setState({ descripcionMejoraCondicion: infoEditarJson.descripcionMejorasCondiciones });
                } else {
                    this.state.indDescripcionMejoraCondicionesPago = false;
                    this.state.descripcionMejoraCondicion = "";
                }

                if (fnaTipoBeneficioListaJson == null) {
                    this.cargarTipoBeneficio();
                } else {
                    this.setState({
                        fnaTipoBeneficionLista: fnaTipoBeneficioListaJson,
                    });
                }
            }
            this.state.tiposIdentificacionSeleccionada =
                infoEditarJson.tipoIdentificacionSolicitante;
            this.setState({
                tiposIdentificacionSeleccionada:
                    infoEditarJson.tipoIdentificacionSolicitante,
            });
            if (tiposIdentificacionListaJson == null) {
                this.cargarTipoIdentificacion();
            } else {
                this.setState({
                    tiposIdentificacionLista: tiposIdentificacionListaJson,
                });
            }
            this.setState({
                fuenteReferenciaInformacionIngresosSeleccionado:
                    infoEditarJson.fuenteReferenciaInformacionIngresos,
            });
            this.setState({
                fuenteReferenciaInformacionEgresosSeleccionado:
                    infoEditarJson.fuenteReferenciaInformacionEgresos,
            });
            if (fuenteReferenciaInformacionListaJson == null) {
                this.cargarFuenteReferencia();
            } else {
                this.setState({
                    fuenteReferenciaInformacionIngresosLista: fuenteReferenciaInformacionListaJson,
                });
                this.setState({
                    fuenteReferenciaInformacionEgresosLista: fuenteReferenciaInformacionListaJson,
                });
            }
            if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
                this.setState({
                    estadoCertificacionCCSSSeleccionado:
                        infoEditarJson.estadoCertificacionCCSS,
                });
                if (estadoCertificacionCCSSListaJson == null) {
                    this.cargarEstadoCCSS();
                } else {
                    this.setState({
                        estadoCertificacionCCSSLista: estadoCertificacionCCSSListaJson,
                    });
                }
                this.setState({
                    fechaVencimientoCertificacionPymePympa: moment(
                        infoEditarJson.fechaVencimientoCertificacionPymePympa
                    )
                        .locale("en")
                        .format("YYYY-MM-DD"),
                });
                this.setState({
                    fechaEmisionCertificacionCCSS: moment(
                        infoEditarJson.fechaEmisionCertificacionCCSS
                    )
                        .locale("en")
                        .format("YYYY-MM-DD"),
                });
                this.setState({
                    estadoTributacionDirectaSeleccionada:
                        infoEditarJson.estadoTributacionDirecta,
                });
                if (estadoTributacionDirectaListaJson == null) {
                    this.cargarTributacionDirecta();
                } else {
                    this.setState({
                        estadoTributacionDirectaLista: estadoTributacionDirectaListaJson,
                    });
                }
                this.setState({
                    riesgosTrabajoSeleccionado: infoEditarJson.riesgosTrabajo,
                });
                if (riesgosTrabajoListaJson == null) {
                    this.cargarRiesgoTrabajo();
                } else {
                    this.setState({
                        riesgosTrabajoLista: riesgosTrabajoListaJson,
                    });
                }
                this.setState({
                    tamanoPymePympaSeleccionado: infoEditarJson.tamanoPymePympa,
                });
                if (tamanoPymePympaListaJson == null) {
                    this.cargarTrabajoEmpresaPYMPAMAG();
                } else {
                    this.setState({
                        tamanoPymePympaLista: tamanoPymePympaListaJson,
                    });
                }
            }

            this.state.tipoProgramaSeleccionado = infoEditarJson.tipoPrograma;
            this.setState({
                tipoProgramaSeleccionado: infoEditarJson.tipoPrograma,
            });
            if (tiposProgramasListaJson == null) {
                this.cargarTipoPrograma();
            } else {
                this.setState({
                    tiposProgramasLista: tiposProgramasListaJson,
                });
                $("#programas").show();
                $("#spinProgramas").hide();
                if (infoEditarJson.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                    this.cargarProgramaIndividual();
                }
                if (infoEditarJson.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL) {
                    this.cargarProgramaCartera();
                }
                if (infoEditarJson.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) {
                    this.cargarProgramaCarteraPE();
                }
            }
            this.state.estadoSeleccionado = infoEditarJson.estado;
            this.setState({ estadoSeleccionado: infoEditarJson.estado });
            if (idRol != REACT_APP_ROL_INCLUSION) {
                this.state.indNoEditar = true;
                this.setState({ indNoEditar: true });
                this.state.indNoEditarEstado = false;
                this.state.indNoEditarEjecutivo = false;
                this.setState({ indNoEditarEstado: false });
                this.setState({ indNoEditarEjecutivo: false });
                this.state.indNoEditarObservaciones = false;
                this.setState({ indNoEditarObservaciones: false });
            }
            this.state.historicoLista = infoEditarJson.historicoEstados;
            this.setState({ historicoLista: infoEditarJson.historicoEstados });

            this.state.historicoArregloPagoLista = infoEditarJson.historicoEstados.filter(function (destino) {
                return destino.estadoDestino.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO;
            });
            this.setState({
                historicoArregloPagoLista: infoEditarJson.historicoEstados.filter(function (destino) {
                    return destino.estadoDestino.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO;
                })
            });

            if (
                infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO)
            ) {
                this.state.indNumeroOperacion = false;
                this.setState({ indNumeroOperacion: false });

                swal(
                    fondoSeleccionado.nombreFondo,
                    "Por favor validar que el número de operación sea el correcto!",
                    "warning", { button: "Aceptar", }
                );

            }
            if (codigosCIIUListaJson == null) {
                this.cargarCodigosCIIU(infoEditarJson.codigoCIIU);
            } else {
                let seccionLista = [];
                let divisionLista = [];
                let grupoLista = [];
                let claseLista = [];
                let codigoCIIU = [];
                codigoCIIU = codigosCIIUListaJson.filter(
                    (x) => x.codigo == infoEditarJson.codigoCIIU
                );
                codigosCIIUListaJson.filter(function (value) {
                    seccionLista.push(value.seccion);
                });
                let seccionSinRepetidos = this.sinRepetidos(seccionLista);
                this.setState({ seccionCIIULista: seccionSinRepetidos });

                this.state.seccionCIIUSeleccionado = codigoCIIU[0].seccion;
                this.setState({
                    seccionCIIUSeleccionado: codigoCIIU[0].seccion,
                });

                divisionLista = codigosCIIUListaJson
                    .filter((seccion) => {
                        return seccion.seccion == this.state.seccionCIIUSeleccionado;
                    })
                    .map((x) => {
                        return x.division;
                    });
                let divisionSinRepetidos = this.sinRepetidos(divisionLista);
                this.setState({ divisionCIIULista: divisionSinRepetidos });
                this.state.divisionCIIUSeleccionado = codigoCIIU[0].division;
                this.setState({
                    divisionCIIUSeleccionado: codigoCIIU[0].division,
                });

                grupoLista = codigosCIIUListaJson
                    .filter((division) => {
                        return division.division == this.state.divisionCIIUSeleccionado;
                    })
                    .map((x) => {
                        return x.grupo;
                    });
                let grupoSinRepetidos = this.sinRepetidos(grupoLista);
                this.setState({
                    grupoCIIULista: grupoSinRepetidos,
                });

                this.state.grupoCIIUSeleccionado = codigoCIIU[0].grupo;
                this.setState({
                    grupoCIIUSeleccionado: codigoCIIU[0].grupo,
                });

                claseLista = codigosCIIUListaJson
                    .filter((grupo) => {
                        return grupo.grupo == this.state.grupoCIIUSeleccionado;
                    })
                    .map((x) => {
                        return x.clase;
                    });
                let claseSinRepetidos = this.sinRepetidos(claseLista);
                this.setState({
                    claseCIIUULista: claseSinRepetidos,
                });

                this.state.claseCIIUUSeleccionado = codigoCIIU[0].clase;
                this.setState({
                    claseCIIUUSeleccionado: codigoCIIU[0].clase,
                });
            }
            if (ejecutivoListaJson == null) {
                if (
                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO &&
                    (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                ) {
                    this.cargarEjecutivosFodemipyme();
                } else if (
                    (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                        this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL &&
                        (idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_INCLUSION)) ||
                    (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO &&
                        this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL &&
                        (idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_INCLUSION)) ||
                    (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                        this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL &&
                        idRol == REACT_APP_ROL_JEFATURA) ||
                    ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                        this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) && idRol == REACT_APP_ROL_JEFATURA) ||
                    (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                        this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL &&
                        (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)) ||
                    ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) &&
                        (idRol == REACT_APP_ROL_OPERATIVO || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_INCLUSION))
                ) {
                    this.cargarAdministradoresFodemipyme();
                } else {
                    this.cargarUsuarios();
                }
            }
            var ejecutivo = infoEditarJson.historicoEstados;
            let ultimoRegistro = ejecutivo[ejecutivo.length - 1];
            let usuarioOrigen = ultimoRegistro.usuarioOrigen;
            if (estadosListaJson == null) {
                this.cargarEstado();
            } else {
                if (idOperadora != REACT_APP_OPERADORA_FNA && idOperadora != REACT_APP_OPERADORA_FODEMIPYME) {
                    $("#indEstados").hide();
                    $("#indEjecutivos").hide();
                    var nuevoEstados = [];
                    var ultimoUsuarioOrigen = [];
                    ultimoUsuarioOrigen = ultimoRegistro.usuarioOrigen.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    if (infoEditarJson.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                                });
                            }
                            if (idRol == REACT_APP_ROL_INCLUSION && ultimoUsuarioOrigen[0].rol.id == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO;
                                });
                            }
                            if (
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                this.state.idUsuarioAsignado != idUsuarioConectado
                            ) {
                                let texto =
                                    nombreUsuarioConectado +
                                    ", usted debe asignarse esta línea de crédito para continuar con el flujo.";
                                swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD;
                                });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME) {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO || estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO) {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return (
                                    estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO ||
                                    estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME ||
                                    estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                                    estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO
                                );
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = false;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: false });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                    }
                    if (
                        infoEditarJson.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                        infoEditarJson.tipoPrograma.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA
                    ) {
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                                });
                            }
                            if (idRol == REACT_APP_ROL_INCLUSION && ultimoUsuarioOrigen[0].rol.id == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                                });
                            }
                            if (
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                this.state.idUsuarioAsignado != idUsuarioConectado
                            ) {
                                let texto =
                                    nombreUsuarioConectado +
                                    ", usted debe asignarse esta línea de crédito para continuar con el flujo.";
                                swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD;
                                });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditarEstado = true;
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEstado: true });
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indNoEditarObservaciones = true;
                                this.setState({ indNoEditarObservaciones: true });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                                });
                            }
                            var usuarioAsignadoPermiso = [];
                            usuarioAsignadoPermiso = this.state.usuarioAsignado.permisos.filter(function (permiso) {
                                return (
                                    permiso.fondo.id == idFondo
                                );
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA && usuarioAsignadoPermiso[0].rol.id != REACT_APP_ROL_JEFATURA) {
                                let texto =
                                    nombreUsuarioConectado +
                                    ", usted debe asignarse esta línea de crédito para continuar con el flujo.";
                                swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return (
                                        estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                                        estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO ||
                                        estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD ||
                                        estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO
                                    );
                                });
                            }
                            if (
                                idRol == REACT_APP_ROL_JEFATURA &&
                                this.state.idUsuarioAsignado != idUsuarioConectado
                            ) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                this.state.indNoEditarObservaciones = false;
                                this.setState({ indNoEditarObservaciones: false });
                                this.state.indNoEditarEjecutivo = false;
                                this.setState({ indNoEditarEjecutivo: false });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                this.state.indNoEditarObservaciones = false;
                                this.setState({ indNoEditarObservaciones: false });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = false;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: false });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = estadosListaJson.filter(function (estado) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                    }
                } else {
                    let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        var nuevoEstados = [];

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").show();
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD;
                            });
                        }

                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        var nuevoEstados = [];
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").show();
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (
                        infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                        infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                        infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                        infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE
                    ) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        this.setState({
                            estadosLista: estadosListaJson,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                        var nuevoEstados = [];

                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });

                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                            });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.idUsuarioAsignado == idUsuarioConectado) {
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        }

                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                        var nuevoEstados = [];
                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").show();
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                        var nuevoEstados = [];
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        var nuevoEstados = [];
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO) {
                        var nuevoEstados = [];
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return (
                                estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO ||
                                estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME ||
                                estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                                estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO
                            );
                        });
                        if (idRol == REACT_APP_ROL_SEGUIMIENTO) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            var nuevoEstados = [];
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO;
                            });
                        }
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME) {
                        var nuevoEstados = [];
                        if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                            $("#botonModificar").hide();
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indButton = true;
                            this.setState({ indButton: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").show();
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                        }

                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                        });
                        if (
                            (idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                            idUsuarioLogueado == infoEditarJson.ejecutivoCreador.b2CId
                        ) {
                            this.state.indNoEditarEjecutivo = false;
                            this.setState({ indNoEditarEjecutivo: false });
                            this.state.indDocumentacionFormalizacion = false;
                            this.setState({
                                indDocumentacionFormalizacion: false,
                            });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                        }

                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            $("#botonModificar").show();
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            this.state.indNoEditarEjecutivo = false;
                            this.setState({ indNoEditarEjecutivo: false });
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO;
                        });
                        if (
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                            idUsuarioLogueado != infoEditarJson.ejecutivoCreador.b2CId
                        ) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                            idUsuarioLogueado == infoEditarJson.ejecutivoCreador.b2CId
                        ) {
                            this.state.indDocumentacionFormalizacion = false;
                            this.setState({
                                indDocumentacionFormalizacion: false,
                            });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                        }
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                        if (
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                            infoEditarJson.ejecutivoCreador.b2CId == idUsuarioLogueado
                        ) {
                            this.state.indNoEditar = false;
                            this.setState({ indNoEditar: false });
                            this.state.indNoEditarEstado = false;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: false });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = false;
                            this.setState({ indNoEditarObservaciones: false });
                        } else {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = false;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                        }
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                            });
                        }

                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                            this.state.indNoEditarEstado = false;
                            this.setState({ indNoEditarEstado: false });
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                        } else {
                            nuevoEstados = estadosListaJson.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO;
                            });
                        }
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (infoEditarJson.estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        nuevoEstados = estadosListaJson.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                }
            }

            let tipoSolicitud = this.state.tiposSolicitudLista.filter(
                (x) => x.id == infoEditarJson.naturaleza
            );
            this.setState({
                tipoSolicitudSeleccionado: tipoSolicitud[0],
            });
            if (tipoSolicitud[0].id == "2") {
                this.setState({ titulo: "SubCrédito" });
            }
        }
        this.ObtenerObservacionesXAval();
    }

    ObtenerObservacionesXAval = () => {
        var data = {
            IdAval: this.state.id,
        };
        ObtenerObservacionesXAval(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.json.observaciones.length > 0) {
                    this.setState({ indMostrarHistoricoObservaciones: true });
                }
                this.setState({ historicoObservaciones: res.json.observaciones });
            }
        });
    };
    AgregarObservaciones = () => {
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        if (this.state.observacion === "") {
            swal(
                "Paso 3 - Agregar observación",
                "La observación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        var data = {
            IdAval: this.state.id,
            IdEjecutivo: idUsuarioCrea,
            Observacion: this.state.observacion,
        };
        this.setState({ indButton: true });
        AgregarObservacion(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ indButton: false });
                    return;
                }
                if (res.json.indicadorExitoso) {
                    swal(
                        "Observaciones",
                        "¡Observación creada con éxito!",
                        "success", { button: "Aceptar", }
                    ).then((value) => {
                        this.ObtenerObservacionesXAval();
                        this.setState({ indButton: false });
                    });
                    this.setState({ observacion: "" });
                    this.ObtenerObservacionesXAval();
                } else {
                    this.setState({ indButton: false });
                    swal("Observaciones", res.json.mensaje, "success", { button: "Aceptar", });
                }
            }
        });
    };
    EditarObservaciones = () => {
        if (this.state.observacion === "") {
            swal(
                "Paso 3 - Agregar observación",
                "La observación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        var data = {
            Id: this.state.idObservacion,
            IdAval: this.state.id,
            IdEjecutivo: idUsuarioConectado,
            Observacion: this.state.observacion,
        };
        EditarObservacion(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    this.setState({ indButton: false });
                    return;
                }
                if (res.json.indicadorExitoso) {
                    this.setState({ indButton: false });
                    swal(
                        "Observaciones",
                        "¡Observación editada con éxito!",
                        "success", { button: "Aceptar", }
                    ).then((value) => {
                        this.ObtenerObservacionesXAval();
                    });
                    this.setState({ observacion: "" });
                    this.ObtenerObservacionesXAval();
                    this.CancelarEdicionObservacion();
                } else {
                    this.setState({ indButton: false });
                    swal("Observaciones", res.json.mensaje, "success", { button: "Aceptar", });
                }
            }
        });
    };
    MostrarEditarObservaciones = (observacion, index) => {
        this.setState({ indEditarObservaciones: true });
        this.setState({ observacion: observacion.observaciones });
        this.setState({ idObservacion: observacion.id });
    };
    CancelarEdicionObservacion = () => {
        this.setState({ indEditarObservaciones: false });
        this.setState({ observacion: "" });
        this.setState({ idObservacion: 0 });
    };
    servicioWSRiesgoFodemipyme = () => {
        $("#spinWSRiesgo").show();
        var data = {
            ClaseCIIU: this.state.claseCIIUUSeleccionado,
            CodigoCIIU: this.state.codigoCIIU,
            DivisionCIIU: this.state.divisionCIIUSeleccionado,
            GrupoCIIU: this.state.grupoCIIUSeleccionado,
            SeccionCIIU: this.state.seccionCIIUSeleccionado,
            AnnosExperienciaEmpresa: this.state.annosExperienciaEmpresa,
            CuotaAnualNuevoCredito: this.state.cuotaAnualNuevoCredito,
            MontoFlujoOperativo: this.state.montoFlujoOperativo,
            MontoTotalCuotasAnuales: this.state.montoTotalCuotasAnuales,
            IdNivelPagoHistoricoCIC: this.state.nivelCICSeleccionado.id,
            PuntajeFinalDeudorCIC: this.state.puntajeCIC,
            IdNivelPagoHistoricoSBD: this.state.nivelPagoHistoricoSBDSeleccionado.id,
            IdNivelHistoricoPagoEntidad: this.state
                .nivelHistoricoPagoEntidadSeleccionado.id,
            NumeroIdentificacionSolicitante: this.state
                .numeroIdentificacionSolicitante,
            OficioCondicionPYMEPYMPA: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.idOficio : null,
            IdTipoIdentificacionSolicitante: this.state
                .tiposIdentificacionSeleccionada.id,
            PuntajeFinalDeudorSBD: this.state.puntajeFinalDeudorSBD,
            IdTamanoEmpresa: this.state.tamanoEmpresaSeleccionada.id,
            IdTipoGarantia: this.state.tipoGarantiaAdicionalSeleccionada.id,
            PorcentajeTotalAvalSolicitado: this.state.porcentajeTotalAvalSolicitado,
            NombreOperadora: this.state.programaSeleccionado.cliente.nombreOperadora,
        };
        if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
            swal("Consultando modelo de riesgo.....");
        }
        ServicioWSRiesgoFodemipyme(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Modelo de Riesgo - Fodemipyme", res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinWSRiesgo").hide();
                    return;
                }
                if (res.json.tieneError) {
                    if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                        swal("Modelo de Riesgo - Fodemipyme", res.json.mensaje, "warning", { button: "Aceptar", }).then(
                            (value) => {
                                swal(
                                    fondoSeleccionado.nombreFondo,
                                    "El aval se puede condicionar para corregirlo o bien, rechazarlo.",
                                    "warning", { button: "Aceptar", }
                                );
                            }
                        );
                    } else {
                        swal(fondoSeleccionado.nombreFondo, "No es posible registrar la solicitud de aval debido a que se está superando el monto máximo de aval permitido por FODEMIPYME para esta solicitud", "warning", { button: "Aceptar", }).then(
                            (value) => {
                                swal(
                                    fondoSeleccionado.nombreFondo,
                                    "El aval se puede condicionar para corregirlo o bien, desestimarlo.",
                                    "warning", { button: "Aceptar", }
                                );
                            }
                        );
                    }
                    this.cambioEstadosXWSRiesgo();
                    return;
                }
                let texto =
                    "El porcentaje de aval permitido de acuerdo al análisis del Modelo de Riesgo es de " +
                    res.json.resultadoPorcentajeAval +
                    "%. Siempre y cuando no supere el tope del AVAL vigente, establecido por FODEMIPYME.";

                if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                    swal("Modelo de Riesgo - Fodemipyme", texto, "warning", { button: "Aceptar", });
                }

                $("#spinWSRiesgo").hide();
            }
        });
    };
    cambioEstadosXWSRiesgo = () => {
        const estadosLista = localStorage.getItem("estadosLista");
        const estadosListaJson = JSON.parse(estadosLista);
        var nuevoEstados = [];
        if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
            if (idRol == REACT_APP_ROL_EJECUTIVO) {
                nuevoEstados = estadosListaJson.filter(function (estado) {
                    return estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                });
            }
        }
        if (
            this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
            this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA
        ) {
            if (idRol == REACT_APP_ROL_OPERATIVO) {
                nuevoEstados = estadosListaJson.filter(function (estado) {
                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                });
            }
        }

        this.estadosLista = nuevoEstados;
        this.estadoSeleccionado = nuevoEstados[1];
        this.setState({
            estadoSeleccionado: nuevoEstados[1],
            estadosLista: nuevoEstados,
        });
        this.cambioEjecutivoXEstado();
    };
    cargarEstado = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO_AVAL,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
                if (this.state.idOperadora != REACT_APP_OPERADORA_FODEMIPYME && this.state.idOperadora != REACT_APP_OPERADORA_FNA) {
                    $("#indEstados").hide();
                    $("#indEjecutivos").hide();

                    var nuevoEstados = [];
                    var ejecutivo = this.state.historicoLista;
                    let ultimoRegistro = ejecutivo[ejecutivo.length - 1];
                    if (idRol != REACT_APP_ROL_INCLUSION) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = false;
                        this.state.indNoEditarEjecutivo = false;
                        this.setState({ indNoEditarEstado: false });
                        this.setState({ indNoEditarEjecutivo: false });
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                    }
                    var ultimoUsuarioOrigen = [];
                    ultimoUsuarioOrigen = ultimoRegistro.usuarioOrigen.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                                });
                            }
                            if (idRol == REACT_APP_ROL_INCLUSION && ultimoUsuarioOrigen[0].rol.id == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO;
                                });
                            }
                            if (
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                this.state.idUsuarioAsignado != idUsuarioConectado
                            ) {
                                let texto =
                                    nombreUsuarioConectado +
                                    ", usted debe asignarse esta línea de crédito para continuar con el flujo.";
                                swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD;
                                });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME) {
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO || estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = false;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: false });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                            });
                            var usuarioAsignadoPermiso = [];
                            usuarioAsignadoPermiso = this.state.usuarioAsignado.permisos.filter(function (permiso) {
                                return (
                                    permiso.fondo.id == idFondo
                                );
                            });
                            if (
                                usuarioAsignadoPermiso[0].rol.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME && idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
                            ) {
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                this.state.indNoEditarObservaciones = false;
                                this.setState({ indNoEditarObservaciones: false });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                    }
                    if (
                        this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                        this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA
                    ) {
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                                });
                            }
                            if (idRol == REACT_APP_ROL_INCLUSION && ultimoUsuarioOrigen[0].rol.id == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                                });
                            }
                            if (
                                idRol == REACT_APP_ROL_OPERATIVO &&
                                this.state.idUsuarioAsignado != idUsuarioConectado
                            ) {
                                let texto =
                                    nombreUsuarioConectado +
                                    ", usted debe asignarse esta línea de crédito para continuar con el flujo.";
                                swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD;
                                });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditarEstado = true;
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEstado: true });
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indNoEditarObservaciones = true;
                                this.setState({ indNoEditarObservaciones: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO;
                                });
                            }
                            var usuarioAsignadoPermiso = [];
                            usuarioAsignadoPermiso = this.state.usuarioAsignado.permisos.filter(function (permiso) {
                                return (
                                    permiso.fondo.id == idFondo
                                );
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA && usuarioAsignadoPermiso[0].rol.id != REACT_APP_ROL_JEFATURA) {
                                let texto =
                                    nombreUsuarioConectado +
                                    ", usted debe asignarse esta línea de crédito para continuar con el flujo.";
                                swal(fondoSeleccionado.nombreFondo, texto, "warning", { button: "Aceptar", });
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return (
                                        estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                                        estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO ||
                                        estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD ||
                                        estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO
                                    );
                                });
                            }
                            if (
                                idRol == REACT_APP_ROL_JEFATURA &&
                                this.state.idUsuarioAsignado != idUsuarioConectado
                            ) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FINALIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditarEstado = true;
                                this.setState({ indNoEditarEstado: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                            });
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                this.state.indNoEditarObservaciones = false;
                                this.setState({ indNoEditarObservaciones: false });
                                this.state.indNoEditarEjecutivo = false;
                                this.setState({ indNoEditarEjecutivo: false });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEstado = true;
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEstado: true });
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indNoEditarObservaciones = true;
                                this.setState({ indNoEditarObservaciones: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO;
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indDocumentacionFormalizacion = false;
                                this.setState({ indDocumentacionFormalizacion: false });
                                this.state.indNoEditarObservaciones = false;
                                this.setState({ indNoEditarObservaciones: false });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEstado = false;
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEstado: false });
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indNoEditarObservaciones = true;
                                this.setState({ indNoEditarObservaciones: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                this.state.indNoEditar = true;
                                this.setState({ indNoEditar: true });
                                this.state.indNoEditarEstado = true;
                                this.state.indNoEditarEjecutivo = true;
                                this.setState({ indNoEditarEstado: true });
                                this.setState({ indNoEditarEjecutivo: true });
                                this.state.indNoEditarObservaciones = true;
                                this.setState({ indNoEditarObservaciones: true });
                                nuevoEstados = res.json.catalogoValores.filter(function (
                                    estado
                                ) {
                                    return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                                });
                            }
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                            this.state.indNoEditar = true;
                            this.setState({ indNoEditar: true });
                            this.state.indNoEditarEstado = true;
                            this.state.indNoEditarEjecutivo = true;
                            this.setState({ indNoEditarEstado: true });
                            this.setState({ indNoEditarEjecutivo: true });
                            this.state.indNoEditarObservaciones = true;
                            this.setState({ indNoEditarObservaciones: true });
                            nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                                return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                            });
                            this.setState({
                                estadosLista: nuevoEstados,
                            });
                        }
                    }
                    localStorage.setItem(
                        "estadosLista",
                        JSON.stringify(res.json.catalogoValores)
                    );
                    return;
                }
                localStorage.setItem(
                    "estadosLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                    if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    var nuevoEstados = [];
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        $("#botonModificar").show();
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                    if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    var nuevoEstados = [];
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        $("#botonModificar").show();
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO) {
                    var nuevoEstados = [];
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return (
                            estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO ||
                            estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME ||
                            estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                            estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO
                        );
                    });

                    if (idRol == REACT_APP_ROL_SEGUIMIENTO) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                        var nuevoEstados = [];
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO;
                        });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_SOLICITADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (
                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE
                ) {
                    $("#botonModificar").hide();
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indButton = true;
                    this.setState({ indButton: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    this.setState({
                        estadosLista: res.json.catalogoValores,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                    var nuevoEstados = [];

                    if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.idUsuarioAsignado == idUsuarioConectado) {
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ACEPTADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                    var nuevoEstados = [];
                    if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        $("#botonModificar").show();
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD || estado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO || estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                    var nuevoEstados = [];
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    var nuevoEstados = [];
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME) {
                    var nuevoEstados = [];
                    if (idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                        $("#botonModificar").hide();
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indButton = true;
                        this.setState({ indButton: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        $("#botonModificar").show();
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                    }

                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                    });

                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    if (
                        (idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                        idUsuarioLogueado == this.state.usuarioAsignado.b2CId
                    ) {
                        this.state.indNoEditarEjecutivo = false;
                        this.setState({ indNoEditarEjecutivo: false });
                        this.state.indDocumentacionFormalizacion = false;
                        this.setState({
                            indDocumentacionFormalizacion: false,
                        });
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        $("#botonModificar").show();
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        this.state.indNoEditarEjecutivo = false;
                        this.setState({ indNoEditarEjecutivo: false });
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO;
                    });
                    if (
                        (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                        idUsuarioLogueado != this.state.usuarioAsignado.b2CId
                    ) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                        this.setState({
                            estadosLista: nuevoEstados,
                        });
                    }
                    if (
                        (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                        idUsuarioLogueado == this.state.usuarioAsignado.b2CId
                    ) {
                        this.state.indDocumentacionFormalizacion = false;
                        this.setState({
                            indDocumentacionFormalizacion: false,
                        });
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                    if (
                        (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                        this.state.usuarioAsignado.b2CId == idUsuarioLogueado
                    ) {
                        this.state.indNoEditar = false;
                        this.setState({ indNoEditar: false });
                        this.state.indNoEditarEstado = false;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: false });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = false;
                        this.setState({ indNoEditarObservaciones: false });
                    } else {
                        this.state.indNoEditar = true;
                        this.setState({ indNoEditar: true });
                        this.state.indNoEditarEstado = true;
                        this.state.indNoEditarEjecutivo = true;
                        this.setState({ indNoEditarEstado: true });
                        this.setState({ indNoEditarEjecutivo: true });
                        this.state.indNoEditarObservaciones = true;
                        this.setState({ indNoEditarObservaciones: true });
                    }
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || estado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    if (idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                        this.state.indNoEditarEstado = false;
                        this.setState({ indNoEditarEstado: false });
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO || estado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME;
                        });
                    } else {
                        nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                            return estado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO;
                        });
                    }
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                    this.state.indNoEditar = true;
                    this.setState({ indNoEditar: true });
                    this.state.indNoEditarEstado = true;
                    this.state.indNoEditarEjecutivo = true;
                    this.setState({ indNoEditarEstado: true });
                    this.setState({ indNoEditarEjecutivo: true });
                    this.state.indNoEditarObservaciones = true;
                    this.setState({ indNoEditarObservaciones: true });
                    nuevoEstados = res.json.catalogoValores.filter(function (estado) {
                        return estado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA;
                    });
                    this.setState({
                        estadosLista: nuevoEstados,
                    });
                }
            }
        });
    };
    sinRepetidos = (lista) => {
        var objeto = {};
        return lista.filter(function (item) {
            return objeto.hasOwnProperty(item) ? false : (objeto[item] = true);
        });
    };
    cargarTrabajoEmpresaPYMPAMAG = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA_PYMPA_MAG,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tamanoPymePympaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tamanoPymePympaLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarRiesgoTrabajo = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_RIESGO_TRABAJO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "riesgosTrabajoLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    riesgosTrabajoLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarTributacionDirecta = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TRIBUTACION_DIRECTA,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "estadoTributacionDirectaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    estadoTributacionDirectaLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarEstadoCCSS = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_ESTADO_CCSS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "estadoCertificacionCCSSLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    estadoCertificacionCCSSLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarMejorasCondicionesPago = () => {
        $("#spinMejoraCondiciones").show();
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_FNA_MEJORAS_CONDICIONES_PAGO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener las mejroas en la condiciones de pago", res.json.mensaje, "error", { button: "Aceptar", });
                    $("#spinMejoraCondiciones").hide();
                    return;
                }
                $("#spinMejoraCondiciones").hide();
                localStorage.setItem(
                    "mejoraCondicionesLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    mejoraCondicionesLista: res.json.catalogoValores,
                    mejoraCondicionesSeleccionado: res.json.catalogoValores[0]
                });
            }
        });
    };
    cargarTipoBeneficio = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_FNA_TIPO_BENEFICIO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener los tipos de beneficio", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "fnaTipoBeneficioLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    fnaTipoBeneficionLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarMoneda = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_MONEDA,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "monedaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({ monedasLista: res.json.catalogoValores });
            }
        });
    };
    cargarTipoPrograma = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_PROGRAMAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#programas").hide();
                    $("#spinProgramas").show();
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    $("#programas").hide();
                    $("#spinProgramas").show();
                    return;
                }
                $("#programas").show();
                $("#spinProgramas").hide();
                localStorage.setItem(
                    "tiposProgramasLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tiposProgramasLista: res.json.catalogoValores,
                });
                if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                    this.cargarProgramaIndividual();
                }
                if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL) {
                    this.cargarProgramaCartera();
                }
                if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) {
                    this.cargarProgramaCarteraPE();
                }
            }
        });
    };
    cargarTipoIdentificacion = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_IDENTIFICACION,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tiposIdentificacionLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tiposIdentificacionLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarSectorEconomico = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_SECTO_ECONOMICO,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "sectorEconomicoLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    sectorEconomicoLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarNiveles = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_NIVELES,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "nivelesLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    nivelPagoHistoricoSBDLista: res.json.catalogoValores,
                });
                this.setState({
                    nivelCICLista: res.json.catalogoValores,
                });
                this.setState({
                    nivelHistoricoPagoEntidadLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarTamanoEmpresa = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TAMANO_EMPRESA,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tamanoEmpresaLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tamanoEmpresaLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarTipoGarantia = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_TIPOS_GARANTIAS,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "tipoGarantiaAdicionalLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    tipoGarantiaAdicionalLista: res.json.catalogoValores,
                });
                this.setState({
                    tipoGarantiaSeleccionadaTabla: res.json.catalogoValores[0],
                });
            }
        });
    };
    cargarFuenteReferencia = () => {
        var data = {
            idCatalogo: REACT_APP_CATALOGO_OBTENER_FUENTE_REFERENCIA_INFORMACION,
        };
        ObtenerCatalogo(data).then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "fuenteReferenciaInformacionLista",
                    JSON.stringify(res.json.catalogoValores)
                );
                this.setState({
                    fuenteReferenciaInformacionIngresosLista: res.json.catalogoValores,
                });
                this.setState({
                    fuenteReferenciaInformacionEgresosLista: res.json.catalogoValores,
                });
            }
        });
    };
    cargarUbicacionGeografica = (idProvincia, idCanton) => {
        obtenerProvincias().then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "ubicacionGeograficaLista",
                    JSON.stringify(res.json.provincias)
                );
                this.setState({
                    provinciaLista: res.json.provincias,
                });
                let provincia = res.json.provincias.filter((x) => x.id == idProvincia);
                this.setState({
                    cantonLista: provincia[0].cantones,
                });
                let canton = provincia[0].cantones.filter((x) => x.id == idCanton);
                this.setState({
                    distritoLista: canton[0].distritos,
                });
            }
        });
    };
    cargarCodigosCIIU = (codigCIIU) => {
        ObtenerCodigosCIIU().then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_400) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_500) {
                    swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "codigosCIIULista",
                    JSON.stringify(res.json.codigos)
                );
                let seccionLista = [];
                let divisionLista = [];
                let grupoLista = [];
                let claseLista = [];
                let codigoCIIU = [];

                res.json.codigos.filter(function (value) {
                    seccionLista.push(value.seccion);
                });

                codigoCIIU = res.json.codigos.filter((x) => x.codigo == codigCIIU);

                let seccionSinRepetidos = this.sinRepetidos(seccionLista);
                this.setState({ seccionCIIULista: seccionSinRepetidos });

                this.state.seccionCIIUSeleccionado = codigoCIIU[0].seccion;
                this.setState({
                    seccionCIIUSeleccionado: codigoCIIU[0].seccion,
                });

                divisionLista = res.json.codigos
                    .filter((seccion) => {
                        return seccion.seccion == this.state.seccionCIIUSeleccionado;
                    })
                    .map((x) => {
                        return x.division;
                    });
                let divisionSinRepetidos = this.sinRepetidos(divisionLista);
                this.setState({ divisionCIIULista: divisionSinRepetidos });

                this.state.divisionCIIUSeleccionado = codigoCIIU[0].division;
                this.setState({
                    divisionCIIUSeleccionado: codigoCIIU[0].division,
                });

                grupoLista = res.json.codigos
                    .filter((division) => {
                        return division.division == this.state.divisionCIIUSeleccionado;
                    })
                    .map((x) => {
                        return x.grupo;
                    });
                let grupoSinRepetidos = this.sinRepetidos(grupoLista);
                this.setState({
                    grupoCIIULista: grupoSinRepetidos,
                });

                this.state.grupoCIIUSeleccionado = codigoCIIU[0].grupo;
                this.setState({
                    grupoCIIUSeleccionado: codigoCIIU[0].grupo,
                });

                claseLista = res.json.codigos
                    .filter((grupo) => {
                        return grupo.grupo == this.state.grupoCIIUSeleccionado;
                    })
                    .map((x) => {
                        return x.clase;
                    });
                let claseSinRepetidos = this.sinRepetidos(claseLista);
                this.setState({
                    claseCIIUULista: claseSinRepetidos,
                });

                this.state.claseCIIUUSeleccionado = codigoCIIU[0].clase;
                this.setState({
                    claseCIIUUSeleccionado: codigoCIIU[0].clase,
                });
            }
        });
    };
    cargarProgramaIndividual = () => {
        $("#tabla2").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 500,
            ajax: {
                url: `/api/ListarProgramasIndividuales?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_404) {
                        swal("Error al obtener los programas individuales", "", "error", { button: "Aceptar", });
                    }
                },
                success: (data) => {
                    if (data != null) {
                        localStorage.setItem(
                            "programasIndividualesLista",
                            JSON.stringify(data.data)
                        );
                        this.setState({
                            programasLista: data.data,
                        });
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    };
    cargarProgramaCartera = () => {
        $("#tabla3").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 500,
            ajax: {
                url: `/api/ListarProgramasCartera?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_404) {
                        swal("Error al obtener los programas de cartera", "", "error", { button: "Aceptar", });
                    }
                },
                success: (data) => {
                    if (data != null) {
                        localStorage.setItem(
                            "programasCarteraLista",
                            JSON.stringify(data.data)
                        );
                        this.setState({
                            programasLista: data.data,
                        });
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    };
    cargarProgramaCarteraPE = () => {
        $("#tabla4").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 500,
            ajax: {
                url: `/api/ListarProgramasCarteraPerdidaEsperada?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_404) {
                        swal(
                            "Error al obtener los programas de cartera de pérdida esperada",
                            "",
                            "error", { button: "Aceptar", }
                        );
                    }
                },
                success: (data) => {
                    if (data != null) {
                        localStorage.setItem(
                            "programasCarteraPELista",
                            JSON.stringify(data.data)
                        );
                        this.setState({
                            programasLista: data.data,
                        });
                    }
                },
                type: "POST",
            },
            columns: [{ data: "id", name: "id" }],
        });
    };
    cargarLineasCreditos = () => {
        GetGenerico(REACT_APP_OBTENER_LINEAS_CREDITO).then(
            (res) => {
                if (res) {
                    if (res.json.lineasCredito.length > 0) {
                        localStorage.setItem(
                            "lineasCreditoDisponibleLista",
                            JSON.stringify(res.lineasCredito)
                        );
                        this.setState({
                            lineasCreditoLista: res.json.lineasCredito,
                        });
                    }
                }
            }
        );
    };
    cargarAdministradoresFodemipyme = () => {
        ListarAdministradoresFodemipyme().then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_401) {
                    swal({
                        title: "¡Sesión Expiró!",
                        text: "Por favor vuelva a iniciar sesión",
                        icon: "warning",
                        button: "Aceptar",
                    }).then((value) => {
                        BorrarLocalStorage();
                        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                    });
                    return;
                }
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener los usuarios", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                var filtroUsuarios = [];
                if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO && idRol == REACT_APP_ROL_ADMINISTRATIVO) {
                    filtroUsuarios = res.json.administradoresFodemipyme.filter(function (usuario) {
                        var usuarioSelecionado = [];
                        usuarioSelecionado = usuario.permisos.filter(function (permiso) {
                            return (
                                permiso.fondo.id == idFondo
                            );
                        });
                        return (
                            usuarioSelecionado[0].rol.id == REACT_APP_ROL_ADMINISTRATIVO
                        );
                    });
                } else {
                    filtroUsuarios = res.json.administradoresFodemipyme;
                }

                localStorage.setItem(
                    "administradoresFodemipymeLista",
                    JSON.stringify(filtroUsuarios)
                );
                let usuarioAsignado = this.state.usuarioAsignado;
                this.state.ejecutivoSeleccionado = usuarioAsignado;

                this.setState({
                    ejecutivoLista: filtroUsuarios,
                    ejecutivoSeleccionado: this.state.ejecutivoSeleccionado,
                });
                $("#spinUsuarios").hide();
            }
        });
    };
    cargarEjecutivosFodemipyme = () => {
        ListarEjecutivosFodemipyme().then((res) => {
            if (res) {
                if (res.codigo == REACT_APP_CODIGO_RESPUESTA_401) {
                    swal({
                        title: "¡Sesión Expiró!",
                        text: "Por favor vuelva a iniciar sesión",
                        icon: "warning",
                        button: "Aceptar",
                    }).then((value) => {
                        BorrarLocalStorage();
                        window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                    });
                    return;
                }
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error al obtener los usuarios", res.json.mensaje, "error", { button: "Aceptar", });
                    return;
                }
                localStorage.setItem(
                    "ejecutivosFodemipymeLista",
                    JSON.stringify(res.json.ejecutivosFondo)
                );
                let usuarioAsignado = this.state.usuarioAsignado;
                this.state.ejecutivoSeleccionado = usuarioAsignado;

                this.setState({
                    ejecutivoLista: res.json.ejecutivosFondo,
                    ejecutivoSeleccionado: this.state.ejecutivoSeleccionado,
                });
                $("#spinUsuarios").hide();
            }
        });
    };
    cargarUsuarios = () => {
        $("#tabla6").DataTable({
            processing: true,
            serverSide: true,
            pageLength: 1000,
            ajax: {
                url: `/api/ListarUsuarios?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_404) swal("Error al obtener los usuarios", "", "error", { button: "Aceptar", });

                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_400) swal("Error al obtener los usuarios", "", "error", { button: "Aceptar", });

                    $("#spinUsuarios").show();
                },
                success: (data) => {
                    if (data != null) {
                        $("#spinUsuarios").hide();
                        this.state.usuariosListaDatatable = data.data;
                        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
                        let idRol = localStorage.getItem("idRol");
                        var filtroUsuarios = [];
                        let idCliente = this.state.programaSeleccionado.cliente.id;
                        var ejecutivo = this.state.historicoLista;
                        let origen = ejecutivo[0].usuarioOrigen;
                        let ultimoRegistro = ejecutivo[ejecutivo.length - 1];
                        let usuarioOrigen = ultimoRegistro.usuarioOrigen;
                        let usuarioDestino = ultimoRegistro.usuarioDestino;
                        let idUsuarioAsignado = this.state.idUsuarioAsignado;
                        let usuarioAsignado = this.state.usuarioAsignado;
                        let usuarioAsignadoAval = this.state.usuarioAsignado.permisos.filter(function (permiso) {
                            return (
                                permiso.fondo.id == idFondo
                            );
                        });
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_JEFATURA && usuarioAsignadoAval[0].rol.id == REACT_APP_ROL_INCLUSION) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            } else if (idRol == REACT_APP_ROL_JEFATURA && usuarioAsignadoAval[0].rol.id == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_OPERATIVO
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        (usuarioSeleccionado[0].rol.id == REACT_APP_ROL_OPERATIVO || usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION) &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == usuarioDestino.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == usuarioDestino.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado[0].estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                            if (idRol == REACT_APP_ROL_SEGUIMIENTO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                            if ((idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) && this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
                                    );
                                });
                            }
                            if (
                                (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_CONTABILIDAD) &&
                                (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                                    this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA)
                            ) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSelecionado = [];
                                    usuarioSelecionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSelecionado[0].rol.id == REACT_APP_ROL_JEFATURA &&
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                    usuarioSeleccionado[0].estado.id == 1 &&
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME && idRol == REACT_APP_ROL_EJECUTIVO) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado[0].estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO) {
                            if (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].rol.id == idRol &&
                                        usuarioSeleccionado[0].usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA &&
                                (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                                    this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA)) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_JEFATURA
                                    );
                                });
                                var nuevoFiltro = [];
                                nuevoFiltro = filtroUsuarios.filter(function (filtro) {
                                    return (
                                        filtro.b2CId == idUsuarioLogueado
                                    );
                                });

                                usuarioAsignado = nuevoFiltro[0];
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA &&
                                this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_SEGUIMIENTO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO &&
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) &&
                            (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                                this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA)
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                return (
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO) {
                            if (idRol == REACT_APP_ROL_SEGUIMIENTO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado[0].rol.id == REACT_APP_ROL_EJECUTIVO &&
                                    usuario.b2CId == idUsuarioLogueado
                                );
                            });
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                return (
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE) {
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_OPERATIVO
                                    );
                                });
                            } else {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    return (
                                        usuario.b2CId == usuarioAsignado.b2CId
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        (usuario.b2CId == idUsuarioLogueado || usuario.b2CId == origen.b2CId)
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            }
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                            if (idRol == REACT_APP_ROL_INCLUSION) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION &&
                                        usuario.b2CId == idUsuarioLogueado
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_OPERATIVO) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        (usuario.b2CId == idUsuarioLogueado || usuario.b2CId == origen.b2CId)
                                    );
                                });
                            }
                            if (idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME) {
                                filtroUsuarios = data.data.filter(function (usuario) {
                                    var usuarioSeleccionado = [];
                                    usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                        return (
                                            permiso.fondo.id == idFondo
                                        );
                                    });
                                    return (
                                        usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                        usuarioSeleccionado[0].rol.id == REACT_APP_ROL_INCLUSION
                                    );
                                });
                            }
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                            idRol == REACT_APP_ROL_SEGUIMIENTO &&
                            this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                return (
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                            (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO) &&
                            (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                                this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA)
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente
                                );
                            });
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
                            idRol == REACT_APP_ROL_JEFATURA &&
                            (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                                this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA)
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado[0].entidadFinancieraAutorizada.id == idCliente &&
                                    usuarioSeleccionado[0].rol.id == REACT_APP_ROL_JEFATURA
                                );
                            });
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO &&
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO || idRol == REACT_APP_ROL_JEFATURA || idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME)
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                return (
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO &&
                            (idRol == REACT_APP_ROL_ADMINISTRATIVO || idRol == REACT_APP_ROL_SEGUIMIENTO)
                        ) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                return (
                                    usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSelecionado = [];
                                usuarioSelecionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSelecionado[0].estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado.estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSelecionado = [];
                                usuarioSelecionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSelecionado[0].estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA) {
                            filtroUsuarios = data.data.filter(function (usuario) {
                                var usuarioSeleccionado = [];
                                usuarioSeleccionado = usuario.permisos.filter(function (permiso) {
                                    return (
                                        permiso.fondo.id == idFondo
                                    );
                                });
                                return (
                                    usuarioSeleccionado.estado.id == 1 && usuario.b2CId == usuarioAsignado.b2CId
                                );
                            });
                        }
                        if (filtroUsuarios.length == 1) {
                            this.state.ejecutivoSeleccionado = filtroUsuarios[0];
                        } else {
                            this.state.ejecutivoSeleccionado = usuarioAsignado;
                        }

                        this.setState({
                            ejecutivoLista: filtroUsuarios,
                            ejecutivoSeleccionado: this.state.ejecutivoSeleccionado,
                            usuariosListaDatatable: data.data
                        });

                        if (
                            this.state.claseCIIUUSeleccionado != "" ||
                            this.state.divisionCIIUSeleccionado != "" ||
                            this.state.grupoCIIUSeleccionado != "" ||
                            this.state.seccionCIIUSeleccionado != ""
                        ) {
                            if (
                                ((this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
                                    this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) &&
                                    idRol == REACT_APP_ROL_OPERATIVO &&
                                    this.state.idUsuarioAsignado == idUsuarioConectado &&
                                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD &&
                                    this.state.programaSeleccionado.detallePrograma.detallePrograma
                                        .indicadorConsumoWSRiesgo) ||
                                (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL &&
                                    idRol == REACT_APP_ROL_EJECUTIVO &&
                                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO &&
                                    this.state.programaSeleccionado.detallePrograma.detallePrograma
                                        .indicadorConsumoWSRiesgo)
                            ) {
                                this.servicioWSRiesgoFodemipyme();
                            }
                        }
                    }
                },
                type: "POST",
            },
            columns: [{ data: "b2CId", name: "b2CId" }],
        });
    };
    cambioEstado = (event) => {
        let estado = this.state.estadosLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ estadoSeleccionado: estado[0] });
    };
    cambiarTab = (k) => {
        this.setState({ llave: k });
    };
    cambioMoneda = (event) => {
        let moneda = this.state.monedasLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ monedaSeleccionada: moneda[0] });
    };
    cambioTipoPrograma = (event) => {
        let programa = this.state.tiposProgramasLista.filter(
            (x) => x.id == event.target.value
        );

        this.setState({ tipoProgramaSeleccionado: programa[0] });
        if (event.target.value == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
            const programasIndividualesLista = localStorage.getItem(
                "programasIndividualesLista"
            );
            const programasIndividualesListaJson = JSON.parse(
                programasIndividualesLista
            );
            if (programasIndividualesListaJson != null) {
                this.setState({
                    programasLista: programasIndividualesListaJson,
                });
                this.setState({
                    programaSeleccionado: programasIndividualesListaJson[0],
                });
            }
        }
        if (event.target.value == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL) {
            const programasCarteraLista = localStorage.getItem(
                "programasCarteraLista"
            );
            const programasCarteraListaJson = JSON.parse(programasCarteraLista);
            if (programasCarteraListaJson == null) {
                this.cargarProgramaCartera();
            } else {
                this.setState({
                    programasLista: programasCarteraListaJson,
                });
                this.setState({
                    programaSeleccionado: programasCarteraListaJson[0],
                });
            }
        }
        if (event.target.value == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA) {
            const programasCarteraPELista = localStorage.getItem(
                "programasCarteraPELista"
            );
            const programasCarteraPEListaJson = JSON.parse(programasCarteraPELista);
            if (programasCarteraPEListaJson == null) {
                this.cargarProgramaCarteraPE();
            } else {
                this.setState({
                    programasLista: programasCarteraPEListaJson,
                });
                this.setState({
                    programaSeleccionado: programasCarteraPEListaJson[0],
                });
            }
        }
    };
    cambioPrograma = (event) => {
        let programa = this.state.programasLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ programaSeleccionado: programa[0] });
    };
    cambioTipoSolicitud = (event) => {
        let tipoSolicitud = this.state.tiposSolicitudLista.filter(
            (x) => x.id == event.target.value
        );
        if (tipoSolicitud[0].id == "2") {
            $("#lineasCredito").show();
            const lineasCreditoLista = localStorage.getItem("lineasCreditoDisponibleLista");
            const lineasCreditoListaJson = JSON.parse(lineasCreditoLista);
            if (lineasCreditoListaJson == null) {
                this.cargarLineasCreditos();
            } else {
                this.setState({
                    lineasCreditoLista: lineasCreditoListaJson,
                });
                this.setState({ lineaCreditoSeleccionada: lineasCreditoListaJson[0] });
            }
            this.state.idNaturaleza = 1;
            this.setState({ idNaturaleza: 1 });
        } else {
            $("#lineasCredito").hide();
            this.state.idNaturaleza = 0;
            this.setState({ idNaturaleza: 0 });
        }

        this.setState({ tipoSolicitudSeleccionado: tipoSolicitud[0] });
    };
    cambioLineaCredito = (event) => {
        let lineaCredito = this.state.lineasCreditoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ lineaCreditoSeleccionada: lineaCredito[0] });
    };
    cambioEstadoAval = (event) => {
        let estado = this.state.estadosLista.filter(
            (x) => x.id == event.target.value
        );
        this.state.estadoSeleccionado = estado[0];
        this.setState({ estadoSeleccionado: estado[0] });
        this.cambioEjecutivoXEstado();
    };
    cambioEjecutivoXEstado = () => {
        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
        const ejecutivoListaJson = this.state.usuariosListaDatatable;

        let ejecutivos = [];
        if (
            idRol == REACT_APP_ROL_OPERATIVO &&
            (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO)
        ) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (
            this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_NORMAL ||
            this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_CARTERA_PERDIDA_ESPERADA
        ) {
            if (idRol == REACT_APP_ROL_INCLUSION && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                let usuarioReciente = this.state.idOperativoReciente;
                if (this.state.idJefaturaReciente != null) {
                    usuarioReciente = this.state.idJefaturaReciente;
                }
                ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == usuarioReciente);
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == this.state.idJefaturaReciente
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == idUsuarioLogueado
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == idUsuarioLogueado
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == this.state.idInclusorReciente
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
        }
        if (this.state.tipoProgramaSeleccionado.id == REACT_APP_TIPO_PROGRAMA_INDIVIDUAL) {
            if (idRol == REACT_APP_ROL_INCLUSION && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD) {
                let usuarioReciente = this.state.idOperativoReciente;
                if (this.state.idEjecutivoFodemipymeReciente != null) {
                    usuarioReciente = this.state.idEjecutivoFodemipymeReciente;
                }
                ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == usuarioReciente);
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_INCLUSION && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO) {
                let usuarioReciente = this.state.idOperativoReciente;
                if (this.state.idEjecutivoFodemipymeReciente != null) {
                    usuarioReciente = this.state.idEjecutivoFodemipymeReciente;
                    var data = {
                        IdUsuario: usuarioReciente,
                    };

                    ObtenerUsuarioPorID(data).then((res) => {
                        if (res) {
                            if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                                swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                                return;
                            }
                            ejecutivos.push(res.json.usuario);
                            this.setState({
                                ejecutivoLista: ejecutivos,
                                ejecutivoSeleccionado: res.json.usuario,
                            });
                        }
                    });
                } else {
                    ejecutivos = ejecutivoListaJson.filter(
                        (x) => x.b2CId == usuarioReciente
                    );
                    this.state.ejecutivoLista = ejecutivos;
                    this.setState({
                        ejecutivoLista: ejecutivos,
                        ejecutivoSeleccionado: ejecutivos[0],
                    });
                }
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == this.state.idUsuarioAsignado
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == idUsuarioLogueado
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == idUsuarioLogueado
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
            if (idRol == REACT_APP_ROL_ADMINISTRATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) {
                ejecutivos = ejecutivoListaJson.filter(
                    (x) => x.b2CId == this.state.idInclusorReciente
                );
                this.state.ejecutivoLista = ejecutivos;
                this.setState({
                    ejecutivoLista: ejecutivos,
                    ejecutivoSeleccionado: ejecutivos[0],
                });
            }
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_CONDICIONADO_ENTIDAD) {
            ejecutivos = ejecutivoListaJson.filter(
                (x) => x.b2CId == this.state.idInclusorReciente
            );
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (idRol == REACT_APP_ROL_OPERATIVO && this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (
            idRol == REACT_APP_ROL_JEFATURA &&
            (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO)
        ) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (
            idRol == REACT_APP_ROL_EJECUTIVO &&
            (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME ||
                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ACEPTADO)
        ) {
            ejecutivos = ejecutivoListaJson.filter((x) => x.b2CId == idUsuarioLogueado);
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DEBE_CONDICIONADO_FODEMIPYME) {
            ejecutivos = ejecutivoListaJson.filter(
                (x) => x.b2CId == this.state.idInclusorReciente
            );
            this.state.ejecutivoLista = ejecutivos;
            this.setState({
                ejecutivoLista: ejecutivos,
                ejecutivoSeleccionado: ejecutivos[0],
            });
        }
    };
    cambioEjecutivo = (event) => {
        let ejecutivo = this.state.ejecutivoLista.filter(
            (x) => x.b2CId == event.target.value
        );
        this.setState({ ejecutivoSeleccionado: ejecutivo[0] });
    };
    cambioTipoIdentificacion = (event) => {
        let identificacion = this.state.tiposIdentificacionLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tiposIdentificacionSeleccionada: identificacion[0] });
    };
    cambioProvincia = (event) => {
        let provincia = this.state.provinciaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ provinciaSeleccionada: provincia[0] });
        this.setState({
            cantonLista: provincia[0].cantones,
        });
        this.setState({
            cantonSeleccionado: provincia[0].cantones[0],
        });
        this.setState({
            distritoLista: provincia[0].cantones[0].distritos,
        });
        this.setState({
            distritoSeleccionado: provincia[0].cantones[0].distritos[0],
        });
    };
    cambioCanton = (event) => {
        let canton = this.state.cantonLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ cantonSeleccionado: canton[0] });

        this.setState({
            distritoLista: canton[0].distritos,
        });
        this.setState({
            distritoSeleccionado: canton[0].distritos[0],
        });
    };
    cambioDistrito = (event) => {
        let distrito = this.state.distritoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ distritoSeleccionado: distrito[0] });
    };
    cambioSectorEconomico = (event) => {
        let sector = this.state.sectorEconomicoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ sectorEconomicoSeleccionado: sector[0] });
    };
    cambioCodigoCIIUU = (event) => {
        let codigoCIIUU = this.state.codigoCIIULista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ codigoCIIUSeleccionado: codigoCIIUU[0] });
    };
    cambionAfectacionCOVID19 = (event) => {
        if (event.target.value === "SI") {
            this.state.demostroAfectacionCOVID = true;
        } else {
            this.state.demostroAfectacionCOVID = false;
            swal(
                "Parámetros de Análisis - Campo 2",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    cambionActividadDentroPais = (event) => {
        if (event.target.value === "Dentro del país") {
            this.state.realizaActividadDentroDelPais = true;
        } else {
            this.state.realizaActividadDentroDelPais = false;
            swal(
                "Parámetros de Análisis - Campo 3",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    cambionConservacionEmpleo = (event) => {
        if (event.target.value === "SI") {
            this.state.compromisoConservacionEmpleo = true;
        } else {
            this.state.compromisoConservacionEmpleo = false;
            swal(
                "Parámetros de Análisis - Campo 4",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    cambionBeneficiarioConglomerado = (event) => {
        if (event.target.value === "SI") {
            this.state.beneficiaroPerteneceConglomerado = true;
            swal(
                "Parámetros de Análisis - Campo 5",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        } else {
            this.state.beneficiaroPerteneceConglomerado = false;
        }
        this.setState(this.state);
    };
    cambionMorosidadPrevioCOVID = (event) => {
        if (event.target.value === "SI") {
            this.state.morosidadPrevioCOVIDMenor60 = true;
        } else {
            this.state.morosidadPrevioCOVIDMenor60 = false;
            swal(
                "Parámetros de Análisis - Campo 6",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    cambioAvalSustituyeGarantia = (event) => {
        if (event.target.value === "Sustituir garantía") {
            this.state.avalSustituyeGarantia = true;
            swal(
                "Parámetros de Análisis - Campo 7",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        } else {
            this.state.avalSustituyeGarantia = false;
        }
        this.setState(this.state);
    };
    cambioClienteCapacidadPago = (event) => {
        if (event.target.value === "SI") {
            this.state.clienteConCapacidadPago = true;
        } else {
            this.state.clienteConCapacidadPago = false;
            swal(
                "Parámetros de Análisis - Campo 8",
                "La selección realizada hace que esta solicitud no pueda ser beneficiaria de un aval de FNA",
                "warning", { button: "Aceptar", }
            );
        }
        this.setState(this.state);
    };
    cambioSesionCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let divisionLista = [];
        let grupoLista = [];
        let claseLista = [];
        let codigoLista = [];
        let seccionCIIUU = this.state.seccionCIIULista.filter(
            (x) => x == event.target.value
        );
        divisionLista = codigosCIIUListaJson
            .filter((seccion) => {
                return seccion.seccion == seccionCIIUU[0];
            })
            .map((x) => {
                return x.division;
            });
        let divisionSinRepetidos = this.sinRepetidos(divisionLista);
        this.setState({ divisionCIIULista: divisionSinRepetidos });
        this.setState({
            divisionCIIUSeleccionado: divisionSinRepetidos[0],
        });
        this.setState({ seccionCIIUSeleccionado: seccionCIIUU[0] });
        grupoLista = codigosCIIUListaJson
            .filter((division) => {
                return division.division == divisionSinRepetidos[0];
            })
            .map((x) => {
                return x.grupo;
            });
        let grupoSinRepetidos = this.sinRepetidos(grupoLista);
        this.setState({
            grupoCIIULista: grupoSinRepetidos,
        });
        this.setState({
            grupoCIIUSeleccionado: grupoSinRepetidos[0],
        });
        claseLista = codigosCIIUListaJson
            .filter((grupo) => {
                return grupo.grupo == grupoSinRepetidos[0];
            })
            .map((x) => {
                return x.clase;
            });
        let claseSinRepetidos = this.sinRepetidos(claseLista);
        this.setState({
            claseCIIUULista: claseSinRepetidos,
        });
        this.setState({
            claseCIIUUSeleccionado: claseSinRepetidos[0],
        });
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseSinRepetidos[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
    };
    cambioDivisionCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let grupoLista = [];
        let claseLista = [];
        let codigoLista = [];
        let divisionCIIUU = this.state.divisionCIIULista.filter(
            (x) => x == event.target.value
        );
        grupoLista = codigosCIIUListaJson
            .filter((division) => {
                return division.division == divisionCIIUU[0];
            })
            .map((x) => {
                return x.grupo;
            });
        let grupoSinRepetidos = this.sinRepetidos(grupoLista);
        this.setState({
            grupoCIIULista: grupoSinRepetidos,
        });
        this.setState({
            grupoCIIUSeleccionado: grupoSinRepetidos[0],
        });
        claseLista = codigosCIIUListaJson
            .filter((grupo) => {
                return grupo.grupo == grupoSinRepetidos[0];
            })
            .map((x) => {
                return x.clase;
            });
        let claseSinRepetidos = this.sinRepetidos(claseLista);
        this.setState({
            claseCIIUULista: claseSinRepetidos,
        });
        this.setState({
            claseCIIUUSeleccionado: claseSinRepetidos[0],
        });
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseSinRepetidos[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
        this.setState({ divisionCIIUSeleccionado: divisionCIIUU[0] });
    };
    cambioGrupoCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let claseLista = [];
        let codigoLista = [];
        let grupoCIIUU = this.state.grupoCIIULista.filter(
            (x) => x == event.target.value
        );
        claseLista = codigosCIIUListaJson
            .filter((grupo) => {
                return grupo.grupo == grupoCIIUU[0];
            })
            .map((x) => {
                return x.clase;
            });
        let claseSinRepetidos = this.sinRepetidos(claseLista);
        this.setState({
            claseCIIUULista: claseSinRepetidos,
        });
        this.setState({
            claseCIIUUSeleccionado: claseSinRepetidos[0],
        });
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseSinRepetidos[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
        this.setState({ grupoCIIUSeleccionado: grupoCIIUU[0] });
    };
    cambioClaseCIIUU = (event) => {
        const codigosCIIULista = localStorage.getItem("codigosCIIULista");
        const codigosCIIUListaJson = JSON.parse(codigosCIIULista);
        let codigoLista = [];
        let claseCIIUU = this.state.claseCIIUULista.filter(
            (x) => x == event.target.value
        );
        codigoLista = codigosCIIUListaJson
            .filter((clase) => {
                return clase.clase == claseCIIUU[0];
            })
            .map((x) => {
                return x.codigo;
            });
        let codigoSinRepetidos = this.sinRepetidos(codigoLista);
        this.setState({
            codigoCIIU: codigoSinRepetidos[0],
        });
        this.setState({ claseCIIUUSeleccionado: claseCIIUU[0] });
    };
    cambioCIIUU = (event) => {
        let ciiuu = this.state.ciiuuLista.filter((x) => x == event.target.value);
        this.setState({ ciiuSeleccionado: ciiuu[0] });
    };
    cambioNivelComportamientoPagoSBD = (event) => {
        let nivelSBD = this.state.nivelPagoHistoricoSBDLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ nivelPagoHistoricoSBDSeleccionado: nivelSBD[0] });
    };
    cambioNivelCIC = (event) => {
        let nivelCIC = this.state.nivelCICLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ nivelCICSeleccionado: nivelCIC[0] });
    };
    cambioNivelHistoricoPagoEntidad = (event) => {
        let nivelHistorico = this.state.nivelHistoricoPagoEntidadLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ nivelHistoricoPagoEntidadSeleccionado: nivelHistorico[0] });
    };
    cambioTamanoEmpresa = (event) => {
        let tamanoEmpresa = this.state.tamanoEmpresaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tamanoEmpresaSeleccionada: tamanoEmpresa[0] });
    };
    cambioTipoGarantiaAdicional = (event) => {
        let tipoGarantia = this.state.tipoGarantiaAdicionalLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ tipoGarantiaAdicionalSeleccionada: tipoGarantia[0] });
    };
    cambioTipoCredito = (event) => {
        let tipoCredito = this.state.tipoCreditoLista.filter(
            (x) => x.id == event.target.value
        );
        if (tipoCredito[0].id == 1) {
            this.state.indPlazoBallon = true;
            this.setState({ indPlazoBallon: true });
        } else {
            this.state.indPlazoBallon = false;
            this.setState({ indPlazoBallon: false });
            this.state.plazoBallon = "";
            this.setState({ plazoBallon: "" });
            this.calcularCuotaNivelada();
        }
        this.setState({ tipoCreditoSeleccionado: tipoCredito[0] });
    };
    calcularCuotaNivelada = () => {
        // tasa anual
        var t = this.state.tasaInteresAnual;
        // plazo meses
        var p = this.state.plazoCreditoMeses;
        if (this.state.indPlazoBallon) {
            p = this.state.plazoBallon;
        }
        p = "0" + p;
        // frecuencia pago
        var fp = 1;
        //monto
        var monto = Number(this.state.montoCredito)
            ? this.state.montoCredito
            : parseFloat(this.state.montoCredito.replace(/,/g, ""));

        var ft = 0;

        var cuotaMensual = 0;

        var divisionPotencia = p / fp;

        ft = (t / 1200) * 1;
        ft = ft.toFixed(14);
        ft = parseFloat(ft);

        var sumaFT = ft + 1;

        var potencia = sumaFT ** divisionPotencia;

        var resultadoNumerador = ft * potencia;

        var resultadoDenominador = potencia - 1;

        var divisionFormula = resultadoNumerador / resultadoDenominador;

        var cuotaMensual = divisionFormula * monto;
        this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
        this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
        this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
        this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
        let cuotaAnual = cuotaMensual * 12;
        this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
    };
    cambioMejoraCondicionesPagos = (event) => {
        let mejoraCondicionPago = this.state.mejoraCondicionesLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({ mejoraCondicionesSeleccionado: mejoraCondicionPago[0] });
    };
    cambioFNATipoBeneficio = (event) => {
        this.state.idTiposBeneficios = Array.from(event.target.selectedOptions, (item) => item.value);

        let tipoBeneficioMejora = this.state.idTiposBeneficios.filter(
            (x) => x == 95
        );
        let tipoBeneficioCualquierOtra = this.state.idTiposBeneficios.filter(
            (x) => x == 96
        );
        if (tipoBeneficioMejora.length > 0) {
            this.state.indCondicionesPago = true;
            const mejoraCondicionesListaJson = JSON.parse(localStorage.getItem("mejoraCondicionesLista"));
            if (mejoraCondicionesListaJson == null) {
                this.cargarMejorasCondicionesPago();
            } else {
                this.state.mejoraCondicionesLista = mejoraCondicionesListaJson;
                this.state.mejoraCondicionesSeleccionado = mejoraCondicionesListaJson[0];
            }
        } else {
            this.state.indCondicionesPago = false;
        }

        if (tipoBeneficioCualquierOtra.length > 0) {
            this.state.indDescripcionMejoraCondicionesPago = true;
        } else {
            this.state.indDescripcionMejoraCondicionesPago = false;
            this.state.descripcionMejoraCondicion = "";
        }
        this.setState(this.state);
    };
    cambioTipoGarantiaTabla = (event) => {
        let tipoGarantia = this.state.tipoGarantiaAdicionalLista.filter(
            (x) => x.id == event.target.value
        );
        this.state.tipoGarantiaSeleccionadaTabla = tipoGarantia[0];
        this.setState({ tipoGarantiaSeleccionadaTabla: tipoGarantia[0] });
    };
    cambioFuenteReferenciaIngreso = (event) => {
        let referenciaIngresos = this.state.fuenteReferenciaInformacionIngresosLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            fuenteReferenciaInformacionIngresosSeleccionado: referenciaIngresos[0],
        });
    };
    cambioFuenteReferenciaEgreso = (event) => {
        let referenciaEgresos = this.state.fuenteReferenciaInformacionEgresosLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            fuenteReferenciaInformacionEgresosSeleccionado: referenciaEgresos[0],
        });
    };
    cambioEjecutivoCreador = (event) => {
        let ejecutivo = this.state.ejecutivoCreadorLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            ejecutivoCreadorSeleccionado: ejecutivo[0],
        });
    };
    cambioCertificacionCCSS = (event) => {
        let estadoCCSS = this.state.estadoCertificacionCCSSLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            estadoCertificacionCCSSSeleccionado: estadoCCSS[0],
        });
    };
    cambioEstadoTributacion = (event) => {
        let estado = this.state.estadoTributacionDirectaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            estadoTributacionDirectaSeleccionada: estado[0],
        });
    };
    cambioRiesgoTrabajo = (event) => {
        let riesgo = this.state.riesgosTrabajoLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            riesgosTrabajoSeleccionado: riesgo[0],
        });
    };
    cambioTamanoPymePymPa = (event) => {
        let tamano = this.state.tamanoPymePympaLista.filter(
            (x) => x.id == event.target.value
        );
        this.setState({
            tamanoPymePympaSeleccionado: tamano[0],
        });
    };
    cambionConsultaCICSUGEF = (event) => {
        if (event.target.value === "0") {
            this.state.consultaAbiertaCICSUGEF = true;
            this.setState({ consultaAbiertaCICSUGE: true });
            this.state.idConsultaAbierta = parseInt(event.target.value);
            this.setState({ idConsultaAbierta: parseInt(event.target.value) });
        } else {
            this.state.consultaAbiertaCICSUGEF = false;
            this.setState({ consultaAbiertaCICSUGE: false });
            this.state.idConsultaAbierta = parseInt(event.target.value);
            this.setState({ idConsultaAbierta: parseInt(event.target.value) });
        }
    };
    encargarseCambio = (event) => {
        if (event.target.name === "indicadorActTotalSaldos") {
            this.setState({ [event.target.name]: event.target.checked });
            this.setState({ ["indicadorComisionAdministracion"]: false });
            return;
        }
        if (event.target.name === "indicadorComisionAdministracion") {
            this.setState({ [event.target.name]: event.target.checked });
            this.setState({ ["indicadorActTotalSaldos"]: false });
            return;
        }
        if (event.target.name === "fechaVencimientoCertificacionPymePympa") {
            this.setState({ indCambioFechaVencimienrtoPYMEPYMPA: true });
        }
        if (event.target.name === "cuotaMensualCreditoCIC") {
            let cuotaMensulaCICFloat = parseFloat(
                event.target.value.replace(/,/g, "")
            ).toFixed(2);

            let cuotaMensualPrestamosFloat = Number(
                this.state.cuotaMensualOtrosPrestamo
            )
                ? this.state.cuotaMensualOtrosPrestamo
                : this.state.cuotaMensualOtrosPrestamo === 0
                    ? this.state.cuotaMensualOtrosPrestamo
                    : parseFloat(
                        this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")
                    ).toFixed(2);

            let totalCuotasMensual =
                parseFloat(cuotaMensulaCICFloat) +
                parseFloat(cuotaMensualPrestamosFloat);
            totalCuotasMensual = parseFloat(totalCuotasMensual.toFixed(2));
            let totalCuotaAnualCIC = parseFloat(cuotaMensulaCICFloat) * 12;
            this.setState({ totalCuotasMensual: totalCuotasMensual });
            this.setState({ montoAnualCreditoCIC: totalCuotaAnualCIC.toFixed(2) });

            var totalCuotasAnualCICFixed = parseFloat(totalCuotaAnualCIC.toFixed(2));

            var totalCuotasAnualPrestamosFixed = parseFloat(
                this.state.montoAnualOtrosPrestamos
            );

            var sumaTotalCuotasAnual =
                totalCuotasAnualPrestamosFixed + totalCuotasAnualCICFixed;

            this.setState({
                montoTotalCuotasAnuales: sumaTotalCuotasAnual,
            });
        }
        if (event.target.name === "cuotaMensualOtrosPrestamo") {
            let cuotaPrestamosFloat = parseFloat(
                event.target.value.replace(/,/g, "")
            ).toFixed(2);
            let cuotaCICFloat = Number(this.state.cuotaMensualCreditoCIC)
                ? this.state.cuotaMensualCreditoCIC
                : parseFloat(
                    this.state.cuotaMensualCreditoCIC.replace(/,/g, "")
                ).toFixed(2);
            let totalCuotasMensual =
                parseFloat(cuotaPrestamosFloat) + parseFloat(cuotaCICFloat);
            totalCuotasMensual = parseFloat(totalCuotasMensual.toFixed(2));
            let totalCuotaAnualOtrosPrestamos = parseFloat(cuotaPrestamosFloat) * 12;
            this.setState({ totalCuotasMensual: totalCuotasMensual });
            this.setState({
                montoAnualOtrosPrestamos: totalCuotaAnualOtrosPrestamos.toFixed(2),
            });

            var totalCuotasAnualPrestamosFixed = parseFloat(
                totalCuotaAnualOtrosPrestamos.toFixed(2)
            );

            var totalCuotasAnualCICFixed = parseFloat(
                this.state.montoAnualCreditoCIC
            );

            var sumaTotalCuotasAnual =
                totalCuotasAnualPrestamosFixed + totalCuotasAnualCICFixed;

            this.setState({
                montoTotalCuotasAnuales: sumaTotalCuotasAnual,
            });
        }
        if (event.target.name === "totalIngresoAnual") {
            let ingresoFloat = parseFloat(event.target.value.replace(/,/g, ""));
            let egresoFloat = Number(this.state.totalEgresoAnual)
                ? this.state.totalEgresoAnual
                : parseFloat(this.state.totalEgresoAnual.replace(/,/g, ""));

            let totalFlujo = ingresoFloat - egresoFloat;
            totalFlujo = parseFloat(totalFlujo.toFixed(2));
            this.setState({ montoFlujoOperativo: totalFlujo });
        }
        if (event.target.name === "totalEgresoAnual") {
            let ingresoFloat = Number(this.state.totalIngresoAnual)
                ? this.state.totalIngresoAnual
                : parseFloat(this.state.totalIngresoAnual.replace(/,/g, ""));

            let egresoFloat = parseFloat(event.target.value.replace(/,/g, ""));
            let totalFlujo = ingresoFloat - egresoFloat;
            totalFlujo = parseFloat(totalFlujo.toFixed(2));
            this.setState({ montoFlujoOperativo: totalFlujo });
        }
        if (event.target.name === "impactoEmpleoMujeres") {
            let totalImpacto =
                parseInt(event.target.value) +
                parseInt(this.state.impactoEmpleoHombres);
            this.setState({ totalImpactoEmpleo: totalImpacto });
        }
        if (event.target.name === "impactoEmpleoHombres") {
            let totalImpacto =
                parseInt(event.target.value) +
                parseInt(this.state.impactoEmpleoMujeres);
            this.setState({ totalImpactoEmpleo: totalImpacto });
        }
        if (event.target.name === "valorGarantia") {
            let producto =
                parseFloat(event.target.value.replace(/,/g, "")).toFixed(2) *
                parseFloat(this.state.porcentajeMaximoAceptacion).toFixed(2);

            let montoMaximoAceptacion = producto / 100;
            this.setState({
                montoMaximoAceptacion: montoMaximoAceptacion,
            });
        }
        if (event.target.name === "porcentajeMaximoAceptacion") {
            let producto =
                parseFloat(event.target.value) *
                parseFloat(this.state.valorGarantia.replace(/,/g, "")).toFixed(2);
            let montoMaximoAceptacion = producto / 100;
            this.setState({
                montoMaximoAceptacion: montoMaximoAceptacion.toFixed(2),
            });
        }
        if (event.target.name === "montoDefinitivoResponsabilidad") {
            let porc = parseFloat(event.target.value.replace(/,/g, "")).toFixed(2);
            porc = parseFloat(porc);
            let x = porc * 0.999999999999999;
            let montoCredito = Number(this.state.montoCredito)
                ? this.state.montoCredito
                : parseFloat(this.state.montoCredito.replace(/,/g, ""));
            let y = x.toFixed(2) / montoCredito;
            let z = y * 100;

            this.setState({ porcentajeResponsabilidad: z.toFixed(2) });
        }
        if (event.target.name === "cuotaMensualNuevoCredito") {
            let cuotaAnual = parseInt(event.target.value) * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual });
        }
        if (event.target.name === "montoCredito") {
            if (!this.state.indicadorModificarMontoCredito && this.state.tipoSolicitudSeleccionado.id != 2) {
                this.state.indicadorModificarMontoCredito = true;
                swal("Editar Línea de Crédito", "Estimado usuaria(o), recordarle que si se modifica el monto del crédito debe de llenar de nuevo la tabla de las garantías aportadas (Paso 2. Garantías Aportadas por el Cliente), debido a que los cálculos de dicha tabla se basan en el monto del crédito.", "warning", { button: "Aceptar", }, {
                    button: "Aceptar",
                });
            }

            this.state.montoCredito = event.target.value;

            if (this.state.tipoSolicitudSeleccionado.id == 2) {
                let totalGarantiasAportadas = parseFloat(this.state.montoCredito.replace(/,/g, "")) * (this.state.porcentajeTotalGarantiasAportadasSolicitante / 100);

                totalGarantiasAportadas = totalGarantiasAportadas.toFixed(2);

                this.state.montoTotalGarantiasAportadasCliente = totalGarantiasAportadas;

                let totalAval =
                    parseFloat(this.state.montoCredito.replace(/,/g, "")) -
                    this.state.montoTotalGarantiasAportadasCliente;

                totalAval = totalAval.toFixed(2);
                this.state.montoTotalAvalSolicitado = totalAval;
                this.setState({ montoTotalGarantiasAportadasCliente: totalGarantiasAportadas });
                this.setState({ montoTotalAvalSolicitado: totalAval });
            } else {
                let montoCredito = Number(this.state.montoCredito)
                    ? this.state.montoCredito
                    : parseFloat(this.state.montoCredito.replace(/,/g, ""));
                let totalAval =
                    montoCredito - this.state.montoTotalGarantiasAportadasCliente;
                this.state.montoTotalAvalSolicitado = totalAval;
                this.setState({ montoTotalAvalSolicitado: totalAval });
            }

            if (this.state.montoCredito === "") {
                this.state.indIrPaso2 = true;
                this.setState({ indIrPaso2: true });
            } else {
                this.state.indIrPaso2 = false;
                this.setState({ indIrPaso2: false });
            }
            this.calcularCuotaNivelada();
        }
        if (event.target.name === "tasaInteresAnual") {
            // tasa anual
            var t = event.target.value;
            // plazo meses
            var p = this.state.plazoCreditoMeses;
            if (this.state.indPlazoBallon) {
                p = this.state.plazoBallon;
            }
            p = "0" + p;
            // frecuencia pago
            var fp = 1;
            //monto
            var monto = Number(this.state.montoCredito)
                ? this.state.montoCredito
                : parseFloat(this.state.montoCredito.replace(/,/g, ""));

            var ft = 0;

            var cuotaMensual = 0;

            var divisionPotencia = p / fp;

            ft = (t / 1200) * 1;
            ft = ft.toFixed(14);
            ft = parseFloat(ft);

            var sumaFT = ft + 1;

            var potencia = sumaFT ** divisionPotencia;

            var resultadoNumerador = ft * potencia;

            var resultadoDenominador = potencia - 1;

            var divisionFormula = resultadoNumerador / resultadoDenominador;

            var cuotaMensual = divisionFormula * monto;
            this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
            this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
            this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
            this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
            let cuotaAnual = cuotaMensual * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
        }
        if (event.target.name === "plazoCreditoMeses") {
            // tasa anual
            var t = this.state.tasaInteresAnual;
            // plazo meses
            var p = event.target.value;
            if (this.state.indPlazoBallon) {
                p = this.state.plazoBallon;
            }
            p = "0" + p;
            // frecuencia pago
            var fp = 1;
            //monto
            var monto = Number(this.state.montoCredito)
                ? this.state.montoCredito
                : parseFloat(this.state.montoCredito.replace(/,/g, ""));

            var ft = 0;

            var cuotaMensual = 0;

            var divisionPotencia = p / fp;

            ft = (t / 1200) * 1;
            ft = ft.toFixed(14);
            ft = parseFloat(ft);

            var sumaFT = ft + 1;

            var potencia = sumaFT ** divisionPotencia;

            var resultadoNumerador = ft * potencia;

            var resultadoDenominador = potencia - 1;

            var divisionFormula = resultadoNumerador / resultadoDenominador;

            var cuotaMensual = divisionFormula * monto;
            this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
            this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
            this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
            this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
            let cuotaAnual = cuotaMensual * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
        }
        if (event.target.name === "plazoBallon") {
            // tasa anual
            var t = this.state.tasaInteresAnual;
            // plazo meses
            var p = event.target.value;
            p = "0" + p;
            // frecuencia pago
            var fp = 1;
            //monto
            var monto = Number(this.state.montoCredito)
                ? this.state.montoCredito
                : parseFloat(this.state.montoCredito.replace(/,/g, ""));

            var ft = 0;

            var cuotaMensual = 0;

            var divisionPotencia = p / fp;

            ft = (t / 1200) * 1;
            ft = ft.toFixed(14);
            ft = parseFloat(ft);

            var sumaFT = ft + 1;

            var potencia = sumaFT ** divisionPotencia;

            var resultadoNumerador = ft * potencia;

            var resultadoDenominador = potencia - 1;

            var divisionFormula = resultadoNumerador / resultadoDenominador;

            var cuotaMensual = divisionFormula * monto;
            this.state.cuotaPeriodica = cuotaMensual.toFixed(2);
            this.setState({ cuotaPeriodica: cuotaMensual.toFixed(2) });
            this.state.cuotaMensualNuevoCredito = cuotaMensual.toFixed(2);
            this.setState({ cuotaMensualNuevoCredito: cuotaMensual.toFixed(2) });
            let cuotaAnual = cuotaMensual * 12;
            this.setState({ cuotaAnualNuevoCredito: cuotaAnual.toFixed(2) });
        }
        if (
            event.target.name === "numeroIdentificacionSolicitante" &&
            event.target.value.length > 17
        ) {
            swal(
                "SAAG",
                "Ha sobrepasado la cantidad máxima de caracteres permitidos para el número de identificación",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    encargarseCambioArchivos = (event) => {
        if (event.target.files.length === 0) {
            this.setState({ [event.target.name]: null });
            return;
        }
        if (event.target.files[0].type != "application/pdf") {
            swal(
                "Paso 3 - Documentación Adjunta",
                "El archivo tiene que ser de tipo PDF",
                "error", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.files[0] });
    };
    encargarseCambioArchivosFNA = (event) => {
        if (event.target.files.length === 0) {
            this.setState({ [event.target.name]: null });
            return;
        }
        if (event.target.files[0].type != "application/pdf") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El archivo tiene que ser de tipo PDF",
                "error", { button: "Aceptar", }
            );
            return;
        }
        this.setState({ [event.target.name]: event.target.files[0] });
    };
    agregarColumna = (event) => {
        event.preventDefault();
        const numeros = new RegExp(/^[0-9,.]*$/);
        const soloNumerosValorGarantia = numeros.test(this.state.valorGarantia);
        const soloNumerosMontoMaximoAceptacion = numeros.test(
            this.state.montoMaximoAceptacion
        );
        const soloNumerosMontoDefinitivoResponsabilidad = numeros.test(
            this.state.montoDefinitivoResponsabilidad
        );
        if (
            this.state.montoCredito === "" || Number(this.state.montoCredito)
                ? this.state.montoCredito == 0
                : parseFloat(this.state.montoCredito.replace(/,/g, "")) == 0
        ) {
            swal("Información Básica", "El monto del crédito es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.descripcionGarantia === "") {
            swal("Garantías Aportadas", "La descripción es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            return;
        }
        if (this.state.descripcionGarantia.toString().length < 5) {
            swal(
                "Garantías Aportadas",
                "La descripción debe contener un mínimo de 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.descripcionGarantia.toString().length > 100) {
            swal(
                "Garantías Aportadas",
                "La descripción debe contener un máximo de 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            return;
        }
        if (this.state.valorGarantia === "" || this.state.valorGarantia == 0) {
            swal("Garantías Aportadas", "Valor es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (!soloNumerosValorGarantia) {
            swal("Garantías Aportadas", "Valor no tiene un formato válido", "error", { button: "Aceptar", });
            return;
        }
        var valorGarantiaFloat = parseFloat(
            this.state.valorGarantia.replace(/,/g, "")
        ).toFixed(2);
        valorGarantiaFloat = parseFloat(valorGarantiaFloat);
        if (valorGarantiaFloat > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Garantías Aportadas",
                "El valor no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.montoMaximoAceptacion === "" ||
            this.state.montoMaximoAceptacion == 0
        ) {
            swal(
                "Garantías Aportadas",
                "Monto Máximo Aceptación es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (!soloNumerosMontoMaximoAceptacion) {
            swal("Garantías Aportadas", "Monto Máximo Aceptación válido", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.montoMaximoAceptacion > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Garantías Aportadas",
                "Monto Máximo Aceptación no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.montoDefinitivoResponsabilidad === "" ||
            this.state.montoDefinitivoResponsabilidad == 0
        ) {
            swal(
                "Garantías Aportadas",
                "Monto Definitivo Responsabilidad es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (!soloNumerosMontoDefinitivoResponsabilidad) {
            swal(
                "Garantías Aportadas",
                "Monto Definitivo Responsabilidad no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        montoDefinitivoResponsabilidadFloat = parseFloat(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        ).toFixed(2);

        montoDefinitivoResponsabilidadFloat = parseFloat(
            montoDefinitivoResponsabilidadFloat
        );
        if (montoDefinitivoResponsabilidadFloat > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Garantías Aportadas",
                "Monto Definitivo Responsabilidad no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.porcentajeResponsabilidad === "" ||
            this.state.porcentajeResponsabilidad == 0
        ) {
            swal(
                "Garantías Aportadas",
                "Porcentaje Responsabilidad es requerido",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.porcentajeResponsabilidad < 0) {
            swal(
                "Garantías Aportadas",
                "Porcentaje Responsabilidad no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.porcentajeResponsabilidad > 100) {
            swal(
                "Garantías Aportadas",
                "Porcentaje Responsabilidad no puede ser mayor a 100",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (
            this.state.porcentajeMaximoAceptacion === "" ||
            this.state.porcentajeMaximoAceptacion == 0
        ) {
            swal("Garantías Aportadas", "% Máximo Aceptación es requerido", "error", { button: "Aceptar", });
            return;
        }
        if (this.state.porcentajeMaximoAceptacion < 0) {
            swal(
                "Garantías Aportadas",
                "% Máximo Aceptación no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            return;
        }
        if (this.state.porcentajeMaximoAceptacion > 100) {
            swal(
                "Garantías Aportadas",
                "% Máximo Aceptación no puede ser mayor a 100",
                "error", { button: "Aceptar", }
            );
            return;
        }
        var montoDefinitivoResponsabilidadFloat = parseFloat(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        ).toFixed(2);

        montoDefinitivoResponsabilidadFloat = parseFloat(
            montoDefinitivoResponsabilidadFloat
        );

        var montoMaximoAceptacionFloat = Number(this.state.montoMaximoAceptacion)
            ? this.state.montoMaximoAceptacion
            : parseFloat(
                this.state.montoMaximoAceptacion.replace(/,/g, "")
            ).toFixed(2);

        montoMaximoAceptacionFloat = parseFloat(montoMaximoAceptacionFloat);

        if (montoDefinitivoResponsabilidadFloat > montoMaximoAceptacionFloat) {
            swal(
                "Garantías Aportadas",
                "El monto definitivo de responsabilidad no puede ser mayor al monto máximo de aceptación",
                "error", { button: "Aceptar", }
            );
            return;
        }

        if (montoDefinitivoResponsabilidadFloat !== montoMaximoAceptacionFloat) {
            swal(
                "Garantías Aportadas",
                "Si el monto definitivo de responsabilidad es distinto al monto máximo de aceptación, por favor dejar una observación indicando el motivo de la diferencia entre dichos montos.",
                "warning", { button: "Aceptar", }
            );
        }

        var nuevoRow = {
            tipoGarantia: this.state.tipoGarantiaSeleccionadaTabla,
            descripcion: this.state.descripcionGarantia,
            valor: parseFloat(this.state.valorGarantia.replace(/,/g, "")).toFixed(2),
            porcentajeMaximoAceptacion: this.state.porcentajeMaximoAceptacion,
            montoMaximoAceptacion: this.state.montoMaximoAceptacion,
            montoDefinitivoResponsabilidad: parseFloat(
                this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
            ).toFixed(2),
            porcentajeResponsabilidad: this.state.porcentajeResponsabilidad,
        };
        let totalGarantiasAportadas =
            this.state.montoTotalGarantiasAportadasCliente == ""
                ? 0
                : this.state.montoTotalGarantiasAportadasCliente;
        let montoDefinitivoFloat = parseFloat(
            this.state.montoDefinitivoResponsabilidad.replace(/,/g, "")
        ).toFixed(2);
        let montoTotalGarantias =
            parseFloat(totalGarantiasAportadas) + parseFloat(montoDefinitivoFloat);

        let montoCredito = Number(this.state.montoCredito)
            ? this.state.montoCredito
            : parseFloat(this.state.montoCredito.replace(/,/g, ""));
        if (montoTotalGarantias > montoCredito) {
            swal(
                "Garantías Aportadas",
                "El monto total de las garantías aportadas por el cliente no puede superar el monto del crédito",
                "error", { button: "Aceptar", }
            );
            return;
        }

        montoTotalGarantias = parseFloat(montoTotalGarantias).toFixed(2);

        this.setState({ montoTotalGarantiasAportadasCliente: montoTotalGarantias });

        let porcTotalGarantias =
            parseFloat(this.state.porcentajeTotalGarantiasAportadasSolicitante) +
            parseFloat(this.state.porcentajeResponsabilidad);

        porcTotalGarantias = parseFloat(porcTotalGarantias).toFixed(2);

        this.state.porcentajeTotalGarantiasAportadasSolicitante = porcTotalGarantias;
        this.setState({
            porcentajeTotalGarantiasAportadasSolicitante: porcTotalGarantias,
        });

        let totalAval =
            parseFloat(this.state.montoCredito.replace(/,/g, "")) -
            montoTotalGarantias;

        totalAval = totalAval.toFixed(2);

        this.state.montoTotalAvalSolicitado = totalAval;
        this.setState({ montoTotalAvalSolicitado: totalAval });

        let totalPorcAval =
            100 - this.state.porcentajeTotalGarantiasAportadasSolicitante;

        totalPorcAval = totalPorcAval.toFixed(2);

        this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });

        let diferenciasMonto = totalAval - totalAval;
        this.setState({ diferenciasMonto: diferenciasMonto });

        let diferenciaPorcentaje = 100 - porcTotalGarantias - totalPorcAval;

        diferenciaPorcentaje = diferenciaPorcentaje.toFixed(2);
        this.setState({ diferenciaPorcentaje: diferenciaPorcentaje });

        $("#tabla_garantias").show();
        this.state.tablaGarantiasAportadas.push(nuevoRow);
        if (this.state.tablaGarantiasAportadas.length > 0) {
            this.state.indIrPaso3 = false;
            this.setState({ indIrPaso3: false });
        } else {
            this.state.indIrPaso3 = true;
            this.setState({ indIrPaso3: true });
        }

        var actualizarLista = this.state.tablaGarantiasAportadas;
        this.state.tipoGarantiaSeleccionadaTabla = this.state.tipoGarantiaAdicionalLista[0];
        this.setState({
            tipoGarantiaSeleccionadaTabla: this.state.tipoGarantiaAdicionalLista[0],
        });
        this.setState({ tablaGarantiasAportadas: actualizarLista });
        this.setState({ descripcionGarantia: "" });
        this.setState({ valorGarantia: "" });
        this.setState({ porcentajeMaximoAceptacion: "" });
        this.setState({ montoMaximoAceptacion: "" });
        this.setState({ montoDefinitivoResponsabilidad: "" });
        this.setState({ porcentajeResponsabilidad: 0 });
    };
    borrarColumna = (index) => {
        let columnaEliminar = this.state.tablaGarantiasAportadas[index];
        let montoTotalGarantias =
            this.state.montoTotalGarantiasAportadasCliente -
            parseFloat(columnaEliminar.montoDefinitivoResponsabilidad);

        montoTotalGarantias = montoTotalGarantias.toFixed(2);

        this.setState({ montoTotalGarantiasAportadasCliente: montoTotalGarantias });

        let porcTotalGarantias =
            this.state.porcentajeTotalGarantiasAportadasSolicitante -
            parseFloat(columnaEliminar.porcentajeResponsabilidad);
        this.setState({
            porcentajeTotalGarantiasAportadasSolicitante: porcTotalGarantias.toFixed(
                2
            ),
        });
        let montoCredito = Number(this.state.montoCredito)
            ? this.state.montoCredito
            : parseFloat(this.state.montoCredito.replace(/,/g, ""));
        let totalAval = montoCredito - montoTotalGarantias;

        totalAval = totalAval.toFixed(2);

        this.setState({ montoTotalAvalSolicitado: totalAval });

        let totalPorcAval = 100 - porcTotalGarantias;

        totalPorcAval = totalPorcAval.toFixed(2);

        this.setState({ porcentajeTotalAvalSolicitado: totalPorcAval });

        let diferenciasMonto = totalAval - totalAval;
        this.setState({ diferenciasMonto: diferenciasMonto });

        let diferenciaPorcentaje = 100 - porcTotalGarantias - totalPorcAval;

        diferenciaPorcentaje = diferenciaPorcentaje.toFixed(2);
        this.setState({ diferenciaPorcentaje: diferenciaPorcentaje });

        this.state.tablaGarantiasAportadas.splice(index, 1);
        if (this.state.tablaGarantiasAportadas.length > 0) {
            this.state.indIrPaso3 = false;
            this.setState({ indIrPaso3: false });
        } else {
            this.state.indIrPaso3 = true;
            this.setState({ indIrPaso3: true });
        }
        var nuevaLista = this.state.tablaGarantiasAportadas;
        if (nuevaLista.length == 0) {
            $("#tabla_garantias").hide();
        }
        this.setState({ tablaGarantiasAportadas: nuevaLista });
    };
    actualizarSaldos = (event) => {
        event.preventDefault();
        let idUsuarioCrea = localStorage.getItem("idUsuarioCreador");
        let idOperadora = localStorage.getItem("idOperadora");
        if (!this.state.indicadorActTotalSaldos && !this.state.indicadorComisionAdministracion) {
            swal(
                "Actualización de saldos",
                "Debe de escoger algunas de las opciones para actualizar saldos",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        let texto = "Está seguro de realizar la actualización total de saldos (Monto Comisión de Administración)?";
        if (this.state.indicadorActTotalSaldos) {
            texto = "Está seguro de realizar la actualización total de saldos (cuota + monto comisión de administración)";
        }
        swal({
            title: "Estás seguro/segura?",
            text: texto,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                let data = {
                    IdEntidadFinanciera: parseInt(idOperadora),
                    IdEjecutivo: idUsuarioCrea,
                    NumeroOperacion: this.state.numeroOperacion,
                    IdPrograma: parseInt(this.state.programaSeleccionado.id),
                    IndPagoTotal: this.state.indicadorActTotalSaldos ? true : false
                };
                ActualizacionSaldosIndividual(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, res.json.mensaje, "error", { button: "Aceptar", });
                            return;
                        }
                        swal(
                            fondoSeleccionado.nombreFondo,
                            "Saldos actualizados con éxito!",
                            "success", { button: "Aceptar", }
                        ).then((value) => {
                            window.location.href = "/lineas-creditos";
                        });
                    }
                });
            } else {
            }
        });
    };
    descargarArchivo = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    descargarArchivoFotoCedulaPersonaFisica = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoFotoCedulaPersonaFisica").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoFotoCedulaPersonaJuridica = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoFotoCedulaPersonaJuridica").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoFotoCedulaRepresentantePersonaJuridica = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoFotoCedulaRepresentantePersonaJuridica").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoCertificacionCCSS = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoCertificacionCCSS").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoTributacionDirecta = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoTributacionDirecta").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoRiesgosTrabajo = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoRiesgosTrabajo").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoCertificacionPymePympa = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoCertificacionPymePympa").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoCICSugef = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoCICSugef").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoActaAprobacionCredito = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoActaAprobacionCredito").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoEstudioRegistralBienesMuebles = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoEstudioRegistralBienesMuebles").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoExcluirGarantiaBienMuebleInmueble = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoExcluirGarantiaBienMuebleInmueble").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };

    descargarArchivoFacturaProforma = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoFacturaProforma").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    descargarArchivoInformeAvaluo = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoInformeAvaluo").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    descargarArchivoContrato = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoContrato").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    descargarArchivoPagare = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoPagare").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    descargarArchivoComprobantePagoComisionFormalizacion = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoComprobantePagoComisionFormalizacion").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    descargarArchivoCuadroComparativo = (nombreArchivo) => {
        if (nombreArchivo == "") {
            swal(
                "Descarga de Archivos",
                "Hubo un error al descargar el archivo. Intente mas tarde",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        if ($("#nombreArchivoCuadroComparativo").is(":visible")) return;

        const urlDescarga = `https://${window.location.host}/api/DescargarArchivo?nombreArchivo=${nombreArchivo}&fondo=${localStorage.getItem("idFondo")}`;
        window.location.assign(urlDescarga);
    };
    mostrarInputArchivoInformeAvaluo = (event) => {
        event.preventDefault();
        $("#nombreArchivoInformeAvaluo").show();
    };

    mostrarInputArchivoContrato = (event) => {
        event.preventDefault();
        $("#nombreArchivoContrato").show();
    };

    mostrarInputArchivoPagare = (event) => {
        event.preventDefault();
        $("#nombreArchivoPagare").show();
    };

    mostrarInputArchivoComprobantePagoComisionFormalizacion = (event) => {
        event.preventDefault();
        $("#nombreArchivoComprobantePagoComisionFormalizacion").show();
    };
    mostrarInputArchivoFacturaProforma = (event) => {
        event.preventDefault();
        $("#nombreArchivoFacturaProforma").show();
    };
    mostrarInputArchivoExcluirGarantiaBienMuebleInmueble = (event) => {
        event.preventDefault();
        $("#nombreArchivoExcluirGarantiaBienMuebleInmueble").show();
    };
    mostrarInputArchivoEstudioRegistralBienesMuebles = (event) => {
        event.preventDefault();
        $("#nombreArchivoEstudioRegistralBienesMuebles").show();
    };
    mostrarInputArchivoActaAprobacionCredito = (event) => {
        event.preventDefault();
        $("#nombreArchivoActaAprobacionCredito").show();
    };
    mostrarInputArchivoCICSugef = (event) => {
        event.preventDefault();
        $("#nombreArchivoCICSugef").show();
    };
    mostrarInputArchivoCertificacionPymePympa = (event) => {
        event.preventDefault();
        $("#nombreArchivoCertificacionPymePympa").show();
    };
    mostrarInputArchivoRiesgosTrabajo = (event) => {
        event.preventDefault();
        $("#nombreArchivoRiesgosTrabajo").show();
    };
    mostrarInputArchivoTributacionDirecta = (event) => {
        event.preventDefault();
        $("#nombreArchivoTributacionDirecta").show();
    };
    mostrarInputArchivoCertificacionCCSS = (event) => {
        event.preventDefault();
        $("#nombreArchivoCertificacionCCSS").show();
    };

    mostrarInputArchivoFotoCedulaRepresentantePersonaJuridica = (event) => {
        event.preventDefault();
        $("#nombreArchivoFotoCedulaRepresentantePersonaJuridica").show();
    };
    mostrarInputArchivoFotoCedulaPersonaJuridica = (event) => {
        event.preventDefault();
        $("#nombreArchivoFotoCedulaPersonaJuridica").show();
    };
    mostrarInputArchivoFotoCedulaPersonaFisica = (event) => {
        event.preventDefault();
        $("#nombreArchivoFotoCedulaPersonaFisica").show();
    };
    mostrarInputArchivoCuandroComparativo = (event) => {
        event.preventDefault();
        $("#nombreArchivoCuadroComparativo").show();
    };
    modificarAval = (event) => {
        event.preventDefault();
        $("#guardando").show();
        $("#floppy").hide();
        const numeros = new RegExp(/^[0-9,.]*$/);
        const soloNumerosMontoCredito = numeros.test(this.state.montoCredito);
        const soloNumerosIngresoAnual = numeros.test(this.state.totalIngresoAnual);
        const soloNumerosEgresoAnual = numeros.test(this.state.totalEgresoAnual);
        const soloNumerosFlujoOperativo = numeros.test(
            this.state.montoFlujoOperativo
        );
        const soloNumerosCuotaMensualCIC = numeros.test(
            this.state.cuotaMensualCreditoCIC
        );
        const soloNumerosCuotaMensualOtrosPrestamos = numeros.test(
            this.state.cuotaMensualOtrosPrestamo
        );
        const soloNumerosTotalCuotaMensual = numeros.test(
            this.state.totalCuotasMensual
        );
        const soloNumerosTotalAnualCreditoCIC = numeros.test(
            this.state.montoAnualCreditoCIC
        );
        const soloNumerosTotalAnualOtrosPrestamos = numeros.test(
            this.state.montoAnualOtrosPrestamos
        );
        const soloNumerosTotalCuotasAnuales = numeros.test(
            this.state.montoTotalCuotasAnuales
        );
        const letras = new RegExp(/^[a-zA-ZáéíñóúüÁÉÍÑÓÚÜ 0-9 ,.-]*$/);
        const soloLetrasSolicitante = letras.test(this.state.nombreSolicitante);

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO) {
            if (
                this.state.nombreArchivoContrato === "" ||
                this.state.nombreArchivoContrato === null
            ) {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "El contrato de aval es requerido para formalizar el aval",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (
                this.state.nombreArchivoPagare === "" ||
                this.state.nombreArchivoPagare === null
            ) {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "El pagaré de aval es requerido para formalizar el aval",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (
                this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
            ) {
                if (this.state.nombreArchivoComprobantePagoComisionFormalizacion === "" ||
                    this.state.nombreArchivoComprobantePagoComisionFormalizacion === null) {
                    swal(
                        "Paso 3 - Documentación Adjunta",
                        "El comprobante de pago de la comisión de formalización es requerido para formalizar el aval",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    $("#floppy").show();
                    return;
                }
            }
        }
        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO) {
            if (
                this.state.nombreArchivoContrato === "" ||
                this.state.nombreArchivoContrato === null
            ) {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "El contrato de aval es requerido para formalizar el arreglo de pago",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (
                this.state.nombreArchivoPagare === "" ||
                this.state.nombreArchivoPagare === null
            ) {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "El pagaré de aval es requerido para formalizar el arreglo de pago",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (
                this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
            ) {
                if (this.state.nombreArchivoComprobantePagoComisionFormalizacion === "" ||
                    this.state.nombreArchivoComprobantePagoComisionFormalizacion === null) {
                    swal(
                        "Paso 3 - Documentación Adjunta",
                        "El comprobante de pago de la comisión de formalización es requerido para formalizar el arreglo de pago",
                        "error", { button: "Aceptar", }
                    );
                    $("#guardando").hide();
                    $("#floppy").show();
                    return;
                }
            }
        }
        if (
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
        ) {
            if (this.state.nombreArchivoContrato != null || this.state.nombreArchivoPagare != null) {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Por favor cambiar el estado a Formalizado",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO && this.state.nombreArchivoComprobantePagoComisionFormalizacion != null) {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Por favor cambiar el estado a Formalizado",
                    "warning", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD
        ) {
            if (this.state.nombreArchivoContrato != null || this.state.nombreArchivoPagare != null) {
                this.setState({ nombreArchivoContrato: null });
                this.setState({ nombreArchivoPagare: null });
            }
            if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO && this.state.nombreArchivoComprobantePagoComisionFormalizacion != null) {
                this.setState({ nombreArchivoComprobantePagoComisionFormalizacion: null });
            }
        }
        if (this.state.tipoProgramaSeleccionado === "") {
            swal("Información Básica", "El tipo de programa es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.tipoProgramaSeleccionado != "" &&
            this.state.programaSeleccionado === ""
        ) {
            swal("Información Básica", "El programa es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }

        if (
            this.state.montoCredito === "" || Number(this.state.montoCredito)
                ? this.state.montoCredito == 0
                : parseFloat(this.state.montoCredito.replace(/,/g, "")) == 0
        ) {
            swal("Información Básica", "El monto del crédito es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosMontoCredito) {
            swal(
                "Información Básica",
                "El monto del crédito no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.totalIngresoAnual)
                ? this.state.totalIngresoAnual > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(this.state.montoCredito.replace(/,/g, "")).toFixed(2) >
                REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Información Básica",
                "El monto del crédito no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.montoCredito)
                ? this.state.montoCredito < 0
                : parseFloat(this.state.montoCredito.replace(/,/g, "")) < 0
        ) {
            swal(
                "Información Básica",
                "El monto del crédito no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nombreSolicitante === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El nombre del cliente es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nombreSolicitante.length < 5) {
            swal(
                "Paso 1 - Información Cliente",
                "El nombre del cliente debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nombreSolicitante.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "El nombre del cliente debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloLetrasSolicitante) {
            swal(
                "Paso 1 - Información Cliente",
                "Nombre del cliente inválido. Caracteres permitidos: , . -",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tiposIdentificacionSeleccionada === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El tipo de identificación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.numeroIdentificacionSolicitante === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El número de identificación es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tiposIdentificacionSeleccionada.id === REACT_APP_TIPO_IDENTIFICACION_PERSONA_FISICA_NACIONAL) {
            if (Number(this.state.numeroIdentificacionSolicitante)
                ? this.state.numeroIdentificacionSolicitante.toString().length !== 9
                : this.state.numeroIdentificacionSolicitante.length !== 9) {
                swal(
                    "Paso 1 - Información Cliente",
                    "La Cédula Física Nacional debe de contener 9 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.tiposIdentificacionSeleccionada.id === REACT_APP_TIPO_IDENTIFICACION_PERSONA_JURIDICA_NACIONAL) {
            if (Number(this.state.numeroIdentificacionSolicitante)
                ? this.state.numeroIdentificacionSolicitante.toString().length !== 10
                : this.state.numeroIdentificacionSolicitante.length !== 10) {
                swal(
                    "Paso 1 - Información Cliente",
                    "La Cédula Jurídica debe de contener 10 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.tiposIdentificacionSeleccionada.id === REACT_APP_TIPO_IDENTIFICACION_EXTRANJERO_RESIDENTE) {
            if (Number(this.state.numeroIdentificacionSolicitante)
                ? this.state.numeroIdentificacionSolicitante.toString().length !== 12
                : this.state.numeroIdentificacionSolicitante.length !== 12) {
                swal(
                    "Paso 1 - Información Cliente",
                    "La Cédula Extrajero Residente debe de contener 12 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.tablaGarantiasAportadas.length == 0) {
            swal(
                "Paso 2 - Garantías Aportadas",
                "Las garantías aportads por el cliente son requeridas",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (this.state.riesgosTrabajoSeleccionado === "") {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Tipo de riesgo de trabajo es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.estadoTributacionDirectaSeleccionada === "") {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Tipo de tributación directa es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.estadoCertificacionCCSSSeleccionado === "") {
                swal(
                    "Paso 3 - Documentación Adjunta",
                    "Estado con la CCSS es requerido",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }

        if (this.state.fuenteReferenciaInformacionIngresosSeleccionado === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Fuente de referencia información para los ingresos es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.fuenteReferenciaInformacionEgresosSeleccionado === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Fuente de referencia información para los egresos es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo === "") {
            swal("Paso 1 - CIC SUGEF", "El total de impacto es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El total de impacto no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo.length > 5) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El total de impacto no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nivelPagoHistoricoSBDSeleccionado === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El nivel de comportamiento de pago SBD es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.puntajeFinalDeudorSBD === "" ||
            this.state.puntajeFinalDeudorSBD == 0
        ) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final del deudor SBD es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (parseInt(this.state.puntajeFinalDeudorSBD) < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final del deudor SBD no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeFinalDeudorSBD > 9) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final del deudor SBD no puede ser mayor a 9",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nivelCICSeleccionado === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El nivel CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeCIC === "" || this.state.puntajeCIC == 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeCIC < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final CIC no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.puntajeCIC > 9) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El puntaje final CIC no puede ser mayor a 9",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.nivelHistoricoPagoEntidadSeleccionado === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El Nivel Histórico de Pago Entidad es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tamanoEmpresaSeleccionada === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El tamaño de la empresa es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalIngresoAnual === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Ingresos anuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosIngresoAnual) {
            swal(
                "Paso 1 - Información Financiera",
                "El monto ingresos anuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.totalIngresoAnual)
                ? this.state.totalIngresoAnual > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(this.state.totalIngresoAnual.replace(/,/g, "")).toFixed(
                    2
                ) > REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Financiera",
                "Ingresos anuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalEgresoAnual === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Egresos anuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            Number(this.state.totalEgresoAnual)
                ? this.state.totalEgresoAnual > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(this.state.totalEgresoAnual.replace(/,/g, "")).toFixed(2) >
                REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Financiera",
                "Egresos anuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosEgresoAnual) {
            swal(
                "Paso 1 - Información Financiera",
                "El monto egresos anuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoFlujoOperativo === "") {
            swal(
                "Paso 1 - Información Financiera",
                "Flujo operativo es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosFlujoOperativo) {
            swal(
                "Paso 1 - Información Financiera",
                "El monto flujo operativo no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoFlujoOperativo > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Financiera",
                "Flujo operativo no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.cuotaMensualCreditoCIC === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de créditos en el CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosCuotaMensualCIC) {
            swal(
                "Paso 1 - Información Financiera",
                "Las cuotas mensuales de créditos en el CIC no tienen un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.cuotaMensualCreditoCIC)
                ? this.state.cuotaMensualCreditoCIC > REACT_APP_LONGITUD_CAMPO_MONTOS
                : parseFloat(
                    this.state.cuotaMensualCreditoCIC.replace(/,/g, "")
                ).toFixed(2) > REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de créditos en el CIC no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.cuotaMensualOtrosPrestamo === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de otros préstamos es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosCuotaMensualOtrosPrestamos) {
            swal(
                "Paso 1 - Información Financiera",
                "Las cuotas mensuales de otros préstamos no tienen un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (
            Number(this.state.cuotaMensualOtrosPrestamo)
                ? this.state.cuotaMensualOtrosPrestamo > REACT_APP_LONGITUD_CAMPO_MONTOS
                : this.state.cuotaMensualOtrosPrestamo === 0
                    ? this.state.cuotaMensualOtrosPrestamo > REACT_APP_LONGITUD_CAMPO_MONTOS
                    : parseFloat(
                        this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")
                    ).toFixed(2) > REACT_APP_LONGITUD_CAMPO_MONTOS
        ) {
            swal(
                "Paso 1 - Información Crediticia",
                "Las cuotas mensuales de otros préstamos no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalCuotasMensual === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto de las sumatorias de las cuotas actuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalCuotaMensual) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto de las sumatorias de las cuotas actuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.totalCuotasMensual > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto de las sumatorias de las cuotas actuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.montoAnualCreditoCIC === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual crédito CIC es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalAnualCreditoCIC) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto anual crédito CIC no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoAnualCreditoCIC > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual crédito CIC no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.montoAnualOtrosPrestamos === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual otros préstamos es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalAnualOtrosPrestamos) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto anual de otros préstamos no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoAnualOtrosPrestamos > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto anual otros préstamos no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.montoTotalCuotasAnuales === "") {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto total de cuotas anuales es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (!soloNumerosTotalCuotasAnuales) {
            swal(
                "Paso 1 - Información Financiera",
                "Monto total de cuotas anuales no tiene un formato válido",
                "error", { button: "Aceptar", }
            );
            $("#floppy").show();
            $("#guardando").hide();
            return;
        }
        if (this.state.montoTotalCuotasAnuales > REACT_APP_LONGITUD_CAMPO_MONTOS) {
            swal(
                "Paso 1 - Información Crediticia",
                "Monto total de cuotas anuales no puede superar los 999,999,999,999",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.tipoGarantiaAdicionalSeleccionada === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El tipo de garantía adicional al aval es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.annosExperienciaEmpresa === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "Los años de experiencia de la empresa son requeridos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.annosExperienciaEmpresa < 0) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "Los años de experiencia de la empresa no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.annosExperienciaEmpresa.length > 3) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "Los años de experiencia de la empresa no pueden superar los 3 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.totalImpactoEmpleo === "" ||
            this.state.totalImpactoEmpleo == null
        ) {
            swal("Paso 1 - CIC SUGEF", "El total de impacto es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo < 0) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El total de impacto no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.totalImpactoEmpleo.length > 5) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El total de impacto no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.impactoEmpleoMujeres === "" ||
            this.state.impactoEmpleoMujeres == null
        ) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (mujeres) es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoMujeres < 0) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (mujeres) no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoMujeres.length > 5) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (mujeres) no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (
            this.state.impactoEmpleoHombres === "" ||
            this.state.impactoEmpleoHombres == null
        ) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (hombres) es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoHombres < 0) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (hombres) no puede ser negativo",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.impactoEmpleoHombres.length > 5) {
            swal(
                "Paso 1 - CIC SUGEF",
                "El impacto de empleo (hombres) no es válido. Excede los 5 dígitos",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.planInversion === "") {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El plan de inversión es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.planInversion.length < 5) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El plan de inversión debe de contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.planInversion.length > 100) {
            swal(
                "Paso 1 - Parámetros de Análisis",
                "El plan de inversión debe de contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.direccion === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La dirección es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.direccion.length < 5) {
            swal(
                "Paso 1 - Información Cliente",
                "La dirección debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.direccion.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "La dirección debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.actividadEconomica === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La actividad económica es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.actividadEconomica.length < 5) {
            swal(
                "Paso 1 - Información Cliente",
                "La actividad económica debe contener mínimo 5 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.actividadEconomica.length > 100) {
            swal(
                "Paso 1 - Información Cliente",
                "La actividad económica debe contener máximo 100 caracteres",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.sectorEconomicoSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El sector económico es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.seccionCIIUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La sección CIIU es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.divisionCIIUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La división CIIU es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.grupoCIIUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El grupo CIIU es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.claseCIIUUSeleccionado === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La clase CIIU es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.codigoCIIU === "") {
            swal(
                "Paso 1 - Información Cliente",
                "El código CIIU es requerido",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.provinciaSeleccionada === "") {
            swal(
                "Paso 1 - Información Cliente",
                "La provincia es requerida",
                "error", { button: "Aceptar", }
            );
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.cantonSeleccionado === "") {
            swal("Paso 1 - Información Cliente", "El cantón es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (this.state.distritoSeleccionado === "") {
            swal("Paso 1 - Información Cliente", "El distrito es requerido", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        if (idFondo == REACT_APP_OPERADORA_FODEMIPYME) {
            if (this.state.idOficio === "") {
                swal("Información Básica", "El id de oficio es requerido", "error", { button: "Aceptar", });
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.idOficio.length < 5) {
                swal(
                    "Información Básica",
                    "El id de oficio debe tener mínimo 5 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
            if (this.state.idOficio.length > 100) {
                swal(
                    "Información Básica",
                    "El id de oficio debe tener máximo 100 caracteres",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (this.state.indPlazoBallon) {
            if (this.state.plazoBallon == "") {
                swal(
                    "Garantías aportadas por el cliente",
                    "El plazo ballon es requerido, si el tipo de crédito seleccionado es Ballon",
                    "error", { button: "Aceptar", }
                );
                $("#guardando").hide();
                $("#floppy").show();
                return;
            }
        }
        if (
            this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO &&
            this.state.lineaCreditoSeleccionada === ""
        ) {
            swal("Información Básica", "La línea de crédito es requerida", "error", { button: "Aceptar", });
            $("#guardando").hide();
            $("#floppy").show();
            return;
        }
        var detalleArregloPago = null;

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) {
            detalleArregloPago = "Moneda: " + this.state.monedaSeleccionada.valor + "," + " Nombre del Solicitante: " + this.state.nombreSolicitante
                + "," + " Identificación del Solicitante: " + this.state.numeroIdentificacionSolicitante + ","
                + " Monto Aval: " + parseFloat(this.state.montoTotalAvalSolicitado) + ","
                + " Porcentaje Aval: " + parseFloat(
                    this.state.porcentajeTotalAvalSolicitado
                )
                + "," + " Saldo del Credito: " + this.state.saldoCredito
                + ", " + "Saldo del Aval: " + this.state.saldoAval;
        }

        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
        let idRol = localStorage.getItem("idRol");
        var ejecutivoSeleccionado = [];
        ejecutivoSeleccionado = this.state.ejecutivoSeleccionado.permisos.filter(function (permiso) {
            return (
                permiso.fondo.id == idFondo
            );
        });

        let idEjecutivoNuevo = this.state.ejecutivoSeleccionado.b2CId;

        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO && idRol == REACT_APP_ROL_OPERATIVO ||
            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME && idRol == REACT_APP_ROL_ADMINISTRATIVO
        ) {
            idEjecutivoNuevo = idUsuarioLogueado;
        }

        let data = {
            Id: parseInt(this.state.id),
            NumeroOperacion: this.state.numeroOperacion,
            IdLineaCredito:
                this.state.lineaCreditoSeleccionada != ""
                    ? parseInt(this.state.lineaCreditoSeleccionada.id)
                    : null,
            IdTipoPrograma: parseInt(this.state.tipoProgramaSeleccionado.id),
            IdPrograma: parseInt(this.state.programaSeleccionado.id),
            IdEntidadFinancieraAutorizada: this.state.clienteSeleccionado.id,
            IdMoneda: parseInt(this.state.monedaSeleccionada.id),
            Naturaleza: parseInt(this.state.tipoSolicitudSeleccionado.id),
            OficioCondicionPYMEPYMPA: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.idOficio : null,
            MontoCredito: Number(this.state.montoCredito)
                ? this.state.montoCredito
                : parseFloat(this.state.montoCredito.replace(/,/g, "")),
            NombreSolicitante: this.state.nombreSolicitante,
            IdTipoIdentificacionSolicitante: parseInt(
                this.state.tiposIdentificacionSeleccionada.id
            ),
            NumeroIdentificacionSolicitante: parseInt(
                this.state.numeroIdentificacionSolicitante
            ),
            IdProvincia: parseInt(this.state.provinciaSeleccionada.id),
            IdCanton: parseInt(this.state.cantonSeleccionado.id),
            IdDistrito: parseInt(this.state.distritoSeleccionado.id),
            Direccion: this.state.direccion,
            ActividadEconomica: this.state.actividadEconomica,
            IdSectorEconomico: parseInt(this.state.sectorEconomicoSeleccionado.id),
            CodigoCIIU: this.state.codigoCIIU,
            ClaseCIIU: this.state.claseCIIUUSeleccionado,
            DivisionCIIU: this.state.divisionCIIUSeleccionado,
            GrupoCIIU: this.state.grupoCIIUSeleccionado,
            SeccionCIIU: this.state.seccionCIIUSeleccionado,
            IdEjecutivo: idEjecutivoNuevo,
            IdEstado:
                ejecutivoSeleccionado[0].rol.id == REACT_APP_ROL_JEFATURA &&
                    this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_SOLICITADO_REVISION_ENTIDAD
                    ? REACT_APP_AVAL_ESTADO_ACEPTADO
                    : parseInt(this.state.estadoSeleccionado.id),
            IdNivelPagoHistoricoSBD: parseInt(
                this.state.nivelPagoHistoricoSBDSeleccionado.id
            ),
            PuntajeFinalDeudorSBD: parseFloat(
                this.state.puntajeFinalDeudorSBD
            ).toFixed(2),
            IdNivelCIC: parseInt(this.state.nivelCICSeleccionado.id),
            PuntajeCIC: parseFloat(this.state.puntajeCIC).toFixed(2),
            IdNivelHistoricoPagoEntidad: parseInt(
                this.state.nivelHistoricoPagoEntidadSeleccionado.id
            ),
            IdTamanoEmpresa: parseInt(this.state.tamanoEmpresaSeleccionada.id),
            IdTipoGarantia: parseInt(this.state.tipoGarantiaAdicionalSeleccionada.id),
            AnnosExperienciaEmpresa: parseInt(this.state.annosExperienciaEmpresa),
            ImpactoEmpleoMujeres: parseInt(this.state.impactoEmpleoMujeres),
            ImpactoEmpleoHombres: parseInt(this.state.impactoEmpleoHombres),
            TotalImpactoEmpleo: parseInt(this.state.totalImpactoEmpleo),
            PlanInversion: this.state.planInversion,
            TotalIngresoAnual: Number(this.state.totalIngresoAnual)
                ? this.state.totalIngresoAnual
                : parseFloat(this.state.totalIngresoAnual.replace(/,/g, "")),
            TotalEgresoAnual: Number(this.state.totalEgresoAnual)
                ? this.state.totalEgresoAnual
                : parseFloat(this.state.totalEgresoAnual.replace(/,/g, "")),
            MontoFlujoOperativo: this.state.montoFlujoOperativo,
            IdFuenteReferenciaInformacionIngresos: parseInt(
                this.state.fuenteReferenciaInformacionIngresosSeleccionado.id
            ),
            IdFuenteReferenciaInformacionEgresos: parseInt(
                this.state.fuenteReferenciaInformacionEgresosSeleccionado.id
            ),
            CuotaMensualCreditoCIC: Number(this.state.cuotaMensualCreditoCIC)
                ? this.state.cuotaMensualCreditoCIC
                : parseFloat(this.state.cuotaMensualCreditoCIC.replace(/,/g, "")),
            CuotaMensualOtrosPrestamo: Number(this.state.cuotaMensualOtrosPrestamo)
                ? this.state.cuotaMensualOtrosPrestamo
                : this.state.cuotaMensualOtrosPrestamo === 0
                    ? this.state.cuotaMensualOtrosPrestamo
                    : parseFloat(this.state.cuotaMensualOtrosPrestamo.replace(/,/g, "")),
            TotalCuotasMensual: this.state.totalCuotasMensual,
            MontoAnualCreditoCIC: this.state.montoAnualCreditoCIC,
            MontoAnualOtrosPrestamos: this.state.montoAnualOtrosPrestamos,
            MontoTotalCuotasAnuales: this.state.montoTotalCuotasAnuales,
            Garantias: this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO ? null : this.state.tablaGarantiasAportadas,
            MontoTotalGarantiasAportadasCliente: parseFloat(
                this.state.montoTotalGarantiasAportadasCliente
            ),
            PorcentajeTotalGarantiasAportadasSolicitante: parseFloat(
                this.state.porcentajeTotalGarantiasAportadasSolicitante
            ),
            MontoTotalAvalSolicitado: parseFloat(this.state.montoTotalAvalSolicitado),
            PorcentajeTotalAvalSolicitado: parseFloat(
                this.state.porcentajeTotalAvalSolicitado
            ),
            TasaInteresAnual: this.state.tasaInteresAnual,
            PlazoCreditoMeses: parseInt(this.state.plazoCreditoMeses),
            FrecuenciaPeriodicaCuota: parseInt(this.state.frecuenciaPeriodica),
            CuotaPeriodicaNuevoCredito: Number(this.state.cuotaMensualNuevoCredito)
                ? this.state.cuotaMensualNuevoCredito
                : parseFloat(this.state.cuotaMensualNuevoCredito.replace(/,/g, "")),
            CuotaAnualNuevoCredito: this.state.cuotaAnualNuevoCredito,
            NombreArchivoFotoCedulaPersonaFisica: this.state
                .nombreArchivoFotoCedulaPersonaFisica,
            NombreArchivoFotoCedulaPersonaJuridica: this.state
                .nombreArchivoFotoCedulaPersonaJuridica,
            NombreArchivoFotoCedulaRepresentantePersonaJuridica: this.state
                .nombreArchivoFotoCedulaRepresentantePersonaJuridica,
            NombreArchivoCertificacionCCSS: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.nombreArchivoCertificacionCCSS : null,
            IdEstadoCertificacionCCSS: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(
                this.state.estadoCertificacionCCSSSeleccionado.id
            ) : null,
            FechaEmisionCertificacionCCSS: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.fechaEmisionCertificacionCCSS : null,
            NombreArchivoTributacionDirecta: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state
                .nombreArchivoTributacionDirecta : null,
            IdEstadoTributacionDirecta: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(
                this.state.estadoTributacionDirectaSeleccionada.id
            ) : null,
            NombreArchivoRiesgosTrabajo: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.nombreArchivoRiesgosTrabajo : null,
            IdRiesgosTrabajo: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(this.state.riesgosTrabajoSeleccionado.id) : null,
            NombreArchivoCertificacionPymePympa: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.nombreArchivoCertificacionPymePympa : null,
            FechaVencimientoCertificacionPymePympa: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? this.state.indCambioFechaVencimienrtoPYMEPYMPA ? this.state.fechaVencimientoCertificacionPymePympa : null : null,
            IdTamanoPymePympa: idFondo == REACT_APP_OPERADORA_FODEMIPYME ? parseInt(this.state.tamanoPymePympaSeleccionado.id) : null,
            ConsultaAbiertaCICSUGEF: this.state.consultaAbiertaCICSUGEF,
            NombreArchivoActaAprobacionCredito: this.state
                .nombreArchivoActaAprobacionCredito,
            NombreArchivoEstudioRegistralBienesMuebles: this.state
                .nombreArchivoEstudioRegistralBienesMuebles,
            NombreArchivoExcluirGarantiaBienMuebleInmueble: this.state
                .nombreArchivoExcluirGarantiaBienMuebleInmueble,
            NombreArchivoFacturaProforma: this.state.nombreArchivoFacturaProforma,
            NombreArchivoInformeAvaluo: this.state.nombreArchivoInformeAvaluo,
            NombreArchivoContrato: this.state.nombreArchivoContrato,
            NombreArchivoPagare: this.state.nombreArchivoPagare,
            NombreArchivoComprobantePagoComisionFormalizacion: this.state
                .nombreArchivoComprobantePagoComisionFormalizacion,
            NombreArchivoCICSugef: this.state
                .nombreArchivoCICSugef,
            TienePlazoBallon: this.state.indPlazoBallon,
            PlazoBallonMeses: this.state.indPlazoBallon
                ? parseInt(this.state.plazoBallon)
                : "",
            ObservacionArregloPago: detalleArregloPago,
            //FNA
            IdTiposBeneficios: idFondo == REACT_APP_OPERADORA_FNA ? this.state.idTiposBeneficios : null,
            IdMejoraCondicion: idFondo == REACT_APP_OPERADORA_FNA ? this.state.indCondicionesPago ? this.state.mejoraCondicionesSeleccionado.id : null : null,
            DescripcionMejoraCondicion: idFondo == REACT_APP_OPERADORA_FNA ? this.state.indDescripcionMejoraCondicionesPago ? this.state.descripcionMejoraCondicion : null : null,
            DemostroAfectacionCOVID: idFondo == REACT_APP_OPERADORA_FNA ? this.state.demostroAfectacionCOVID : null,
            RealizaActividadDentroDelPais: idFondo == REACT_APP_OPERADORA_FNA ? this.state.realizaActividadDentroDelPais : null,
            CompromisoConservacionEmpleo: idFondo == REACT_APP_OPERADORA_FNA ? this.state.compromisoConservacionEmpleo : null,
            BeneficiaroPerteneceConglomerado: idFondo == REACT_APP_OPERADORA_FNA ? this.state.beneficiaroPerteneceConglomerado : null,
            MorosidadPrevioCOVIDMenor60: idFondo == REACT_APP_OPERADORA_FNA ? this.state.morosidadPrevioCOVIDMenor60 : null,
            AvalSustituyeGarantia: idFondo == REACT_APP_OPERADORA_FNA ? this.state.avalSustituyeGarantia : null,
            ClienteConCapacidadPago: idFondo == REACT_APP_OPERADORA_FNA ? this.state.clienteConCapacidadPago : null,
            ArchivoCuadroComparativo: idFondo == REACT_APP_OPERADORA_FNA ? this.state.archivoCuadroComparativo : null,
        };
        let tipoSolicitudTexto = "línea de crédito";
        let respuestaSolicitud = "¡Línea de crédito editada con éxito!";
        let redireccionarVista = "/lineas-creditos";
        if (this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO) {
            tipoSolicitudTexto = "aval + línea de crédito";
            respuestaSolicitud = "¡Aval + Línea de crédito editada con éxito!";
            var idLinea = this.state.lineaCreditoSeleccionada != ""
                ? parseInt(this.state.lineaCreditoSeleccionada.id)
                : null;
            redireccionarVista = "/sub-creditos/" + idLinea;
        }

        let texto = "Estado de línea de crédito: " + this.state.estadoSeleccionado.valor;
        if ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO) && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO) {
            texto = "Estado de " + tipoSolicitudTexto + ": " + this.state.estadoSeleccionado.valor + " Recuerde adjuntar el contrato y el pagaré en formato PDF";
        } else {
            texto = "Estado de " + tipoSolicitudTexto + ": " + this.state.estadoSeleccionado.valor;
        }


        if (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO &&
            (idRol == REACT_APP_ROL_INCLUSION || idRol == REACT_APP_ROL_OPERATIVO)) {
            swal({
                title: "Estás seguro/segura?",
                text: "El número de operación actualmente es: " + this.state.numeroOperacion + ". Si es el correcto, darle clic al botón SÍ para continuar sino proceda a modificarlo en el Paso 1. Información Básica.",
                icon: "warning",
                buttons: ["NO", "SÍ"],
                dangerMode: false,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        swal({
                            title: "Estás seguro/segura?",
                            text: texto,
                            icon: "warning",
                            buttons: ["NO", "SÍ"],
                            dangerMode: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                                this.setState({ indButton: true });
                                ModificarAval(data).then((res) => {
                                    if (res) {
                                        swal(
                                            fondoSeleccionado.nombreFondo,
                                            respuestaSolicitud,
                                            "success", { button: "Aceptar", }
                                        ).then((value) => {
                                            window.location.href = redireccionarVista;
                                        });
                                    }
                                    $("#guardando").hide();
                                    $("#floppy").show();
                                    this.setState({ indButton: false });
                                });
                            } else {
                                this.setState({ indButton: false });
                                $("#guardando").hide();
                                $("#floppy").show();
                            }
                        });
                    } else {
                        this.setState({ indButton: false });
                        $("#guardando").hide();
                        $("#floppy").show();
                    }
                });
        } else {
            swal({
                title: "Estás seguro/segura?",
                text: texto,
                icon: "warning",
                buttons: ["NO", "SÍ"],
                dangerMode: true,
            }).then((willDelete) => {
                if (willDelete) {
                    this.setState({ indButton: true });
                    ModificarAval(data).then((res) => {
                        if (res) {
                            swal(
                                fondoSeleccionado.nombreFondo,
                                respuestaSolicitud,
                                "success", { button: "Aceptar", }
                            ).then((value) => {
                                window.location.href = redireccionarVista;
                            });
                        }
                        $("#guardando").hide();
                        $("#floppy").show();
                        this.setState({ indButton: false });
                    });
                } else {
                    this.setState({ indButton: false });
                    $("#guardando").hide();
                    $("#floppy").show();
                }
            });
        }


    };

    render() {
        const idRol = localStorage.getItem("idRol");
        const idFondo = localStorage.getItem("idFondo");
        if (!idRol) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        return (
            <div class="container text-center">
                <table id="tabla1"></table>
                <table id="tabla2"></table>
                <table id="tabla3"></table>
                <table id="tabla4"></table>
                <table id="tabla5"></table>
                <table id="tabla6"></table>
                <center>
                    <h2>
                        Editar {this.state.titulo}: <b>#{this.state.id}</b>
                    </h2>
                </center>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a href="/admin">Inicio</a>
                        </li>
                        {this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_AVAL_LINEA_CREDITO ? (
                            <li class="breadcrumb-item">
                                <a href={'/sub-creditos/' + this.state.lineaCreditoSeleccionada.id}>SubCréditos</a>
                            </li>
                        ) : (
                            <li class="breadcrumb-item">
                                <a href="/lineas-creditos">Líneas de Créditos</a>
                            </li>
                        )}
                        <li class="breadcrumb-item active" aria-current="page">
                            Editar {this.state.titulo}
                        </li>
                    </ol>
                </nav>
                <div class="row">
                    <div class="col-md-12">
                        <Tabs
                            activeKey={this.state.llave}
                            transition={false}
                            onSelect={(k) => this.cambiarTab(k)}
                            id="noanim-tab-example"
                        >
                            <Tab
                                eventKey="informacionBasica"
                                title="Paso 1. Información Básica"
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row>
                                        <Col md={12}>
                                            {this.state.tipoCambio ? (
                                                <Form.Row>
                                                    <Col xs={12} md={4}></Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Label>
                                                            Tipo de cambio: <b>{this.state.tipoCambio}</b>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col xs={12} md={4}></Col>
                                                </Form.Row>
                                            ) : (
                                                <Form.Row></Form.Row>
                                            )}
                                            <Form.Row>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Elija Moneda</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioMoneda}
                                                            value={this.state.monedaSeleccionada.id}
                                                            disabled
                                                        >
                                                            {this.state.monedasLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Elija Programa</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioTipoPrograma}
                                                            value={this.state.tipoProgramaSeleccionado.id}
                                                            disabled
                                                        >
                                                            {this.state.tiposProgramasLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Tipo Solicitud</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioTipoSolicitud}
                                                            value={this.state.idNaturaleza}
                                                            disabled
                                                        >
                                                            {this.state.tiposSolicitudLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col xs={12} md={4}>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group id="programas">
                                                        <Form.Label>Programas</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioPrograma}
                                                            value={this.state.programaSeleccionado.id}
                                                            disabled
                                                        >
                                                            {this.state.programasLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.detallePrograma.id} - {" "}
                                                                        {data.cliente.nombreOperadora} - {" "}
                                                                        {data.detallePrograma.nombrePrograma}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <span class="input-group-addon" id="spinProgramas">
                                                        <i class="fa fa-refresh fa-spin"></i>
                                                    </span>
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group id="lineasCredito">
                                                        <Form.Label>Líneas de Crédito</Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.cambioLineaCredito}
                                                            value={this.state.lineaCreditoSeleccionada.id}
                                                            disabled
                                                        >
                                                            {this.state.lineasCreditoLista.map(function (
                                                                data,
                                                                key
                                                            ) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.id} - {data.nombreSolicitante}
                                                                    </option>
                                                                );
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <hr />
                                            <Form.Row>
                                                <Col xs={12} md={4}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label>Monto Crédito</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                name="montoCredito"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                min="0"
                                                                max="999999999999"
                                                                placeholder="0"
                                                                value={this.state.montoCredito}
                                                                required
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Monto Crédito</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                name="montoCredito"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                min="0"
                                                                max="999999999999"
                                                                placeholder="0"
                                                                value={this.state.montoCredito}
                                                                required
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4} hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label>
                                                                ID Oficio Condición PYME o PYMPA
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="idOficio"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                placeholder="Id Oficio"
                                                                value={this.state.idOficio}
                                                                required
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>
                                                                ID Oficio Condición PYME o PYMPA
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="idOficio"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                placeholder="Id Oficio"
                                                                value={this.state.idOficio}
                                                                required
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Número Operación</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="numeroOperacion"
                                                            class="form-control"
                                                            onChange={this.encargarseCambio}
                                                            placeholder="Número Operación"
                                                            value={this.state.numeroOperacion}
                                                            required
                                                            disabled={this.state.indNumeroOperacion}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} md={2}></Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col xs={12} md={4}></Col>
                                                <Col xs={12} md={4}>
                                                    {this.state.indEntidadBPFODEMIPYME ? (
                                                        <Form.Group>
                                                            <Form.Label>Número Solicitud Bancaria</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="numeroSolicitudBancaria"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                placeholder="Número Solicitud Bancaria"
                                                                value={this.state.numeroSolicitudBancaria}
                                                                required
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group></Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    {this.state.indEntidadBPFODEMIPYME ? (
                                                        <Form.Group>
                                                            <Form.Label>Número Operación Bancaria</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                name="numeroOperacionBancaria"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                placeholder="Número Operación Bancaria"
                                                                value={this.state.numeroOperacionBancaria}
                                                                required
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group></Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={2}></Col>
                                            </Form.Row>
                                            <hr></hr>
                                            <Row>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card
                                                            style={{ backgroundColor: "#FFFFFF !important" }}
                                                        >
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="InfoCliente"
                                                                >
                                                                    Información del Cliente{" "}
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="InfoCliente">
                                                                <Card.Body>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Información del Cliente</h4>
                                                                            <p>Información básica del cliente</p>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Nombre Cliente
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="nombreSolicitante"
                                                                                        class="form-control"
                                                                                        placeholder="Nombre Cliente"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.nombreSolicitante}
                                                                                        title="El nombre del cliente debe contener solo letras. Min: 5 caracteres. Max: 100 caracteres"
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Nombre Cliente
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="nombreSolicitante"
                                                                                        class="form-control"
                                                                                        placeholder="Nombre Cliente"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.nombreSolicitante}
                                                                                        title="El nombre del cliente debe contener solo letras. Min: 5 caracteres. Max: 100 caracteres"
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Tipo Identificación
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioTipoIdentificacion
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .tiposIdentificacionSeleccionada
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.tiposIdentificacionLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Tipo Identificación
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioTipoIdentificacion
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .tiposIdentificacionSeleccionada
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.tiposIdentificacionLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Número Identificación
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="numeroIdentificacionSolicitante"
                                                                                        class="form-control"
                                                                                        onChange={this.encargarseCambio}
                                                                                        placeholder="0"
                                                                                        value={
                                                                                            this.state
                                                                                                .numeroIdentificacionSolicitante
                                                                                        }
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Número Identificación
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="numeroIdentificacionSolicitante"
                                                                                        class="form-control"
                                                                                        onChange={this.encargarseCambio}
                                                                                        placeholder="0"
                                                                                        value={
                                                                                            this.state
                                                                                                .numeroIdentificacionSolicitante
                                                                                        }
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Provincia</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioProvincia}
                                                                                        value={
                                                                                            this.state.provinciaSeleccionada
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.provinciaLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.nombre}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Provincia</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioProvincia}
                                                                                        value={
                                                                                            this.state.provinciaSeleccionada
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.provinciaLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.nombre}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Cantón</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioCanton}
                                                                                        value={
                                                                                            this.state.cantonSeleccionado.id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.cantonLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.nombre}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Cantón</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioCanton}
                                                                                        value={
                                                                                            this.state.cantonSeleccionado.id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.cantonLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.nombre}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Distrito</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioDistrito}
                                                                                        value={
                                                                                            this.state.distritoSeleccionado.id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.distritoLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.nombre}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Distrito</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioDistrito}
                                                                                        value={
                                                                                            this.state.distritoSeleccionado.id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.distritoLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.nombre}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Dirección</Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        name="direccion"
                                                                                        minLength="5"
                                                                                        maxLength="100"
                                                                                        value={this.state.direccion}
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        rows={3}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Dirección</Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        name="direccion"
                                                                                        minLength="5"
                                                                                        maxLength="100"
                                                                                        value={this.state.direccion}
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        rows={3}
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Actividad Económica
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        name="actividadEconomica"
                                                                                        minLength="5"
                                                                                        maxLength="100"
                                                                                        value={
                                                                                            this.state.actividadEconomica
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        rows={3}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Actividad Económica
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        name="actividadEconomica"
                                                                                        minLength="5"
                                                                                        maxLength="100"
                                                                                        value={
                                                                                            this.state.actividadEconomica
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        rows={3}
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={3}></Col>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Sector Económico
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioSectorEconomico
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .sectorEconomicoSeleccionado.id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.sectorEconomicoLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Sector Económico
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioSectorEconomico
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .sectorEconomicoSeleccionado.id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.sectorEconomicoLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={3}></Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Sección CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="seccionCIIUSeleccionado"
                                                                                        class="form-control"
                                                                                        placeholder="Código CIIU"
                                                                                        value={
                                                                                            this.state.seccionCIIUSeleccionado
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Sección CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioSesionCIIUU}
                                                                                        disabled={this.state.indButton}
                                                                                        value={
                                                                                            this.state.seccionCIIUSeleccionado
                                                                                        }
                                                                                    >
                                                                                        {this.state.seccionCIIULista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data}
                                                                                                    >
                                                                                                        {data}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>División CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="divisionCIIUSeleccionado"
                                                                                        class="form-control"
                                                                                        placeholder="División CIIU"
                                                                                        value={
                                                                                            this.state
                                                                                                .divisionCIIUSeleccionado
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>División CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioDivisionCIIUU}
                                                                                        disabled={this.state.indButton}
                                                                                        value={
                                                                                            this.state
                                                                                                .divisionCIIUSeleccionado
                                                                                        }
                                                                                    >
                                                                                        {this.state.divisionCIIULista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data}
                                                                                                    >
                                                                                                        {data}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Grupo CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="grupoCIIUSeleccionado"
                                                                                        class="form-control"
                                                                                        placeholder="Grupo CIIU"
                                                                                        value={
                                                                                            this.state.grupoCIIUSeleccionado
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Grupo CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioGrupoCIIUU}
                                                                                        disabled={this.state.indButton}
                                                                                        value={
                                                                                            this.state.grupoCIIUSeleccionado
                                                                                        }
                                                                                    >
                                                                                        {this.state.grupoCIIULista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data}
                                                                                                    >
                                                                                                        {data}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Clase CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name="claseCIIUUSeleccionado"
                                                                                        class="form-control"
                                                                                        placeholder="Clase CIIU"
                                                                                        value={
                                                                                            this.state.claseCIIUUSeleccionado
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Clase CIIU</Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioClaseCIIUU}
                                                                                        disabled={this.state.indButton}
                                                                                        value={
                                                                                            this.state.claseCIIUUSeleccionado
                                                                                        }
                                                                                    >
                                                                                        {this.state.claseCIIUULista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data}
                                                                                                    >
                                                                                                        {data}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={3}></Col>
                                                                        <Col xs={12} md={6}>
                                                                            <Form.Group>
                                                                                <Form.Label>Código CIIU</Form.Label>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    name="codigoCIIU"
                                                                                    class="form-control"
                                                                                    placeholder="Código CIIU"
                                                                                    value={this.state.codigoCIIU}
                                                                                    onChange={this.encargarseCambio}
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={3}></Col>
                                                                    </Form.Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="CIC"
                                                                >
                                                                    Parámetros de Análisis{" "}
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="CIC">
                                                                <Card.Body>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Parámetros de Análisis</h4>
                                                                            <p>Reporte crediticio para la entidad</p>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontSize: "15px" }}
                                                                                    >
                                                                                        Nivel Comportamiento Pago SBD
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this
                                                                                                .cambioNivelComportamientoPagoSBD
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .nivelPagoHistoricoSBDSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.nivelPagoHistoricoSBDLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontSize: "15px" }}
                                                                                    >
                                                                                        Nivel Comportamiento Pago SBD
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this
                                                                                                .cambioNivelComportamientoPagoSBD
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .nivelPagoHistoricoSBDSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.nivelPagoHistoricoSBDLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Puntaje Final del Deudor SBD
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="100"
                                                                                        name="puntajeFinalDeudorSBD"
                                                                                        value={
                                                                                            this.state.puntajeFinalDeudorSBD
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Puntaje Final del Deudor SBD
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="100"
                                                                                        name="puntajeFinalDeudorSBD"
                                                                                        value={
                                                                                            this.state.puntajeFinalDeudorSBD
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontSize: "15px" }}
                                                                                    >
                                                                                        Nivel CIC
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioNivelCIC}
                                                                                        value={
                                                                                            this.state.nivelCICSeleccionado.id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.nivelCICLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label
                                                                                        style={{ fontSize: "15px" }}
                                                                                    >
                                                                                        Nivel CIC
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioNivelCIC}
                                                                                        value={
                                                                                            this.state.nivelCICSeleccionado.id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.nivelCICLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={6}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Puntaje Final CIC
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="puntajeCIC"
                                                                                        value={this.state.puntajeCIC}
                                                                                        onChange={this.encargarseCambio}
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Puntaje Final CIC
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="puntajeCIC"
                                                                                        value={this.state.puntajeCIC}
                                                                                        onChange={this.encargarseCambio}
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Nivel Histórico de Pago Entidad
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this
                                                                                                .cambioNivelHistoricoPagoEntidad
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .nivelHistoricoPagoEntidadSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.nivelHistoricoPagoEntidadLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Nivel Histórico de Pago Entidad
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this
                                                                                                .cambioNivelHistoricoPagoEntidad
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .nivelHistoricoPagoEntidadSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.nivelHistoricoPagoEntidadLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Tamaño <br></br>Empresa
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioTamanoEmpresa}
                                                                                        value={
                                                                                            this.state
                                                                                                .tamanoEmpresaSeleccionada.id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.tamanoEmpresaLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Tamaño <br></br>Empresa
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={this.cambioTamanoEmpresa}
                                                                                        value={
                                                                                            this.state
                                                                                                .tamanoEmpresaSeleccionada.id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.tamanoEmpresaLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Tipo Garantía Adicional al Aval
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioTipoGarantiaAdicional
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .tipoGarantiaAdicionalSeleccionada
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.tipoGarantiaAdicionalLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Tipo Garantía Adicional al Aval
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioTipoGarantiaAdicional
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .tipoGarantiaAdicionalSeleccionada
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.tipoGarantiaAdicionalLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={3}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Años Experiencia Empresa
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="annosExperienciaEmpresa"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={
                                                                                            this.state.annosExperienciaEmpresa
                                                                                        }
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Años Experiencia Empresa
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="annosExperienciaEmpresa"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={
                                                                                            this.state.annosExperienciaEmpresa
                                                                                        }
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                        onKeyPress={(event) => {
                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Impacto <br></br>Empleo (Mujeres)
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999"
                                                                                        name="impactoEmpleoMujeres"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={
                                                                                            this.state.impactoEmpleoMujeres
                                                                                        }
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Impacto <br></br>Empleo (Mujeres)
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999"
                                                                                        name="impactoEmpleoMujeres"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={
                                                                                            this.state.impactoEmpleoMujeres
                                                                                        }
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                        onKeyPress={(event) => {
                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Impacto Empleo (Hombres)
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999"
                                                                                        name="impactoEmpleoHombres"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={
                                                                                            this.state.impactoEmpleoHombres
                                                                                        }
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Impacto Empleo (Hombres)
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999"
                                                                                        name="impactoEmpleoHombres"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={
                                                                                            this.state.impactoEmpleoHombres
                                                                                        }
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        required
                                                                                        disabled={this.state.indButton}
                                                                                        onKeyPress={(event) => {
                                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    Total <br></br>Impacto Empleo
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    min="0"
                                                                                    disabled
                                                                                    name="totalImpactoEmpleo"
                                                                                    onChange={this.encargarseCambio}
                                                                                    value={this.state.totalImpactoEmpleo}
                                                                                    class="form-control"
                                                                                    placeholder="0"
                                                                                    required
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Plan de Inversión
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        minLength="5"
                                                                                        maxLength="100"
                                                                                        name="planInversion"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.planInversion}
                                                                                        rows={3}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Plan de Inversión
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        minLength="5"
                                                                                        maxLength="100"
                                                                                        name="planInversion"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.planInversion}
                                                                                        rows={3}
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        1. Tipo de beneficio indicado por el operador (presione CTRL para seleccionar varios elementos)
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        multiple={true}
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioFNATipoBeneficio
                                                                                        }
                                                                                        disabled
                                                                                        value={this.state.idTiposBeneficios}
                                                                                    >
                                                                                        {this.state.fnaTipoBeneficionLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        1. Tipo de beneficio indicado por el operador (presione CTRL para seleccionar varios elementos)
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        multiple={true}
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioFNATipoBeneficio
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                        value={this.state.idTiposBeneficios}
                                                                                    >
                                                                                        {this.state.fnaTipoBeneficionLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={!this.state.indCondicionesPago}>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Mejoras en las condiciones de pago:
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioMejoraCondicionesPagos
                                                                                        }
                                                                                        value={this.state.mejoraCondicionesSeleccionado.id}
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.mejoraCondicionesLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                    <span class="input-group-addon" id="spinMejoraCondiciones">
                                                                                        <i class="fa fa-refresh fa-spin"></i>
                                                                                    </span>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Mejoras en las condiciones de pago:
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioMejoraCondicionesPagos
                                                                                        }
                                                                                        value={this.state.mejoraCondicionesSeleccionado.id}
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.mejoraCondicionesLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                    <span class="input-group-addon" id="spinMejoraCondiciones">
                                                                                        <i class="fa fa-refresh fa-spin"></i>
                                                                                    </span>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={!this.state.indDescripcionMejoraCondicionesPago}>
                                                                        <Col xs={12} md={12}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Agregar una descripción
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        minLength="5"
                                                                                        maxLength="300"
                                                                                        name="descripcionMejoraCondicion"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.descripcionMejoraCondicion}
                                                                                        rows={3}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Agregar una descripción
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="textarea"
                                                                                        minLength="5"
                                                                                        maxLength="300"
                                                                                        name="descripcionMejoraCondicion"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.descripcionMejoraCondicion}
                                                                                        rows={3}
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <hr hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}></hr>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    2. Demostró afectación con COVID 19
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionAfectacionCOVID19
                                                                                    }
                                                                                    value={this.state.valueDemostroAfectacionCOVID}
                                                                                    disabled
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    3. La actividad económica se realiza:
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionActividadDentroPais
                                                                                    }
                                                                                    value={this.state.valueRealizaActividadDentroDelPais}
                                                                                    disabled
                                                                                >
                                                                                    <option id="Dentro del país">Dentro del país</option>
                                                                                    <option id="Fuera del país">Fuera del país</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    4. El cliente a suscrito el compromiso para la conservación del empleo
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionConservacionEmpleo
                                                                                    }
                                                                                    value={this.state.valueCompromisoConservacionEmpleo}
                                                                                    disabled
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    5. El beneficiario pertenece a un conglomerado financiero según la definición del BCCR
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionBeneficiarioConglomerado
                                                                                    }
                                                                                    value={this.state.valueBeneficiaroPerteneceConglomerado}
                                                                                    disabled
                                                                                >
                                                                                    <option id="NO">NO</option>
                                                                                    <option id="SI">SI</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    6. El beneficiario presentaba previo a la declaratoria de emergencia nacional generada por la pandemia del COVID-19 CPH bueno o aceptable en el CIC, y una morosidad menor o igual a 60 días
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambionMorosidadPrevioCOVID
                                                                                    }
                                                                                    value={this.state.valueMorosidadPrevioCOVIDMenor60}
                                                                                    disabled
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    7. En esta solicitud el aval fue utilizado para
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioAvalSustituyeGarantia
                                                                                    }
                                                                                    value={this.state.valueAvalSustituyeGarantia}
                                                                                    disabled
                                                                                >
                                                                                    <option id="Complementar la garantía existente">Complementar la garantía existente</option>
                                                                                    <option id="Sustituir garantía">Sustituir garantía</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>
                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        <Col xs={12} md={12}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    8. El cliente cuenta con capacidad de pago verificada y documentada para atender las condiciones del nuevo compromiso
                                                                                </Form.Label>
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={
                                                                                        this.cambioClienteCapacidadPago
                                                                                    }
                                                                                    value={this.state.valueClienteConCapacidadPago}
                                                                                    disabled
                                                                                >
                                                                                    <option id="SI">SI</option>
                                                                                    <option id="NO">NO</option>
                                                                                </Form.Control>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Form.Row>

                                                                    <Form.Row hidden={idFondo == REACT_APP_OPERADORA_FODEMIPYME}>
                                                                        {this.state.indNoEditar ? (
                                                                            <Col xs={12} md={12}>
                                                                                {this.state.indNombreArchivoCuadroComparativo ? (
                                                                                    <Form.Group>
                                                                                        <Form.Label>
                                                                                            9. Cargar cuadro comparativo de las condiciones financieras antes y después del aval
                                                                                        </Form.Label>
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                this.descargarArchivoCuadroComparativo(
                                                                                                    this.state
                                                                                                        .archivoCuadroComparativo
                                                                                                )
                                                                                            }
                                                                                            class="btn btn-lg"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            disabled
                                                                                        >
                                                                                            <img
                                                                                                src="/assets/images/pdf-icon.png"
                                                                                                width="60"
                                                                                                height="60"
                                                                                                style={{ borderRadius: "20px" }}
                                                                                                alt="pdf-icon"
                                                                                            />
                                                                                        </a>{" "}
                                                                                        <a
                                                                                            href="#"
                                                                                            class="btn btn-lg"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            disabled
                                                                                            hidden
                                                                                        >
                                                                                            <img
                                                                                                src="/assets/images/upload-pdf-icon.png"
                                                                                                width="60"
                                                                                                height="60"
                                                                                                style={{ borderRadius: "20px" }}
                                                                                                alt="pdf-icon"
                                                                                            />
                                                                                        </a>
                                                                                    </Form.Group>
                                                                                ) : (
                                                                                    <Form.Group>
                                                                                        <Form.File
                                                                                            name="archivoCuadroComparativo"
                                                                                            onChange={this.encargarseCambioArchivosFNA}
                                                                                            accept="application/pdf"
                                                                                            disabled
                                                                                        />
                                                                                    </Form.Group>
                                                                                )}
                                                                                <Form.Group id="nombreArchivoCuadroComparativo">
                                                                                    <Form.File
                                                                                        name="archivoCuadroComparativo"
                                                                                        onChange={this.encargarseCambioArchivosFNA}
                                                                                        accept="application/pdf"
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        ) : (
                                                                            <Col xs={12} md={12}>
                                                                                {this.state.indNombreArchivoCuadroComparativo ? (
                                                                                    <Form.Group>
                                                                                        <Form.Label>
                                                                                            9. Cargar cuadro comparativo de las condiciones financieras antes y después del aval
                                                                                        </Form.Label>
                                                                                        <a
                                                                                            onClick={() =>
                                                                                                this.descargarArchivoCuadroComparativo(
                                                                                                    this.state
                                                                                                        .archivoCuadroComparativo
                                                                                                )
                                                                                            }
                                                                                            class="btn btn-lg"
                                                                                            style={{ cursor: "pointer" }}
                                                                                        >
                                                                                            <img
                                                                                                src="/assets/images/pdf-icon.png"
                                                                                                width="60"
                                                                                                height="60"
                                                                                                style={{ borderRadius: "20px" }}
                                                                                                alt="pdf-icon"
                                                                                            />
                                                                                        </a>
                                                                                        <a
                                                                                            onClick={
                                                                                                this.mostrarInputArchivoCuandroComparativo
                                                                                            }
                                                                                            class="btn btn-lg"
                                                                                            style={{ cursor: "pointer" }}
                                                                                        >
                                                                                            <img
                                                                                                src="/assets/images/upload-pdf-icon.png"
                                                                                                width="60"
                                                                                                height="60"
                                                                                                style={{ borderRadius: "20px" }}
                                                                                                alt="pdf-icon"
                                                                                            />
                                                                                        </a>
                                                                                    </Form.Group>
                                                                                ) : (
                                                                                    <Form.Group>
                                                                                        <Form.File
                                                                                            name="archivoCuadroComparativo"
                                                                                            onChange={this.encargarseCambioArchivosFNA}
                                                                                            accept="application/pdf"
                                                                                            disabled={this.state.indButton}
                                                                                        />
                                                                                    </Form.Group>
                                                                                )}
                                                                                <Form.Group id="nombreArchivoCuadroComparativo">
                                                                                    <Form.File
                                                                                        name="archivoCuadroComparativo"
                                                                                        onChange={this.encargarseCambioArchivosFNA}
                                                                                        accept="application/pdf"
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        )}
                                                                    </Form.Row>

                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="InfoFinanciera"
                                                                >
                                                                    Información Financiera
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="InfoFinanciera">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Información Financiera</h4>
                                                                            <p>Datos anuales en Colones</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Ingresos Anuales
                                                                                    </Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="totalIngresoAnual"
                                                                                        value={this.state.totalIngresoAnual}
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Ingresos Anuales
                                                                                    </Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="totalIngresoAnual"
                                                                                        value={this.state.totalIngresoAnual}
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={8}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Indicar Fuente Referencia
                                                                                        Información
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioFuenteReferenciaIngreso
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .fuenteReferenciaInformacionIngresosSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.fuenteReferenciaInformacionIngresosLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Indicar Fuente Referencia
                                                                                        Información
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioFuenteReferenciaIngreso
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .fuenteReferenciaInformacionIngresosSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.fuenteReferenciaInformacionIngresosLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Egresos Anuales
                                                                                    </Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="totalEgresoAnual"
                                                                                        value={this.state.totalEgresoAnual}
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Egresos Anuales
                                                                                    </Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="totalEgresoAnual"
                                                                                        value={this.state.totalEgresoAnual}
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={8}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Indicar Fuente Referencia
                                                                                        Información
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioFuenteReferenciaEgreso
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .fuenteReferenciaInformacionEgresosSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled
                                                                                    >
                                                                                        {this.state.fuenteReferenciaInformacionEgresosLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>
                                                                                        Indicar Fuente Referencia
                                                                                        Información
                                                                                    </Form.Label>
                                                                                    <Form.Control
                                                                                        as="select"
                                                                                        onChange={
                                                                                            this.cambioFuenteReferenciaEgreso
                                                                                        }
                                                                                        value={
                                                                                            this.state
                                                                                                .fuenteReferenciaInformacionEgresosSeleccionado
                                                                                                .id
                                                                                        }
                                                                                        disabled={this.state.indButton}
                                                                                    >
                                                                                        {this.state.fuenteReferenciaInformacionEgresosLista.map(
                                                                                            function (data, key) {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={key}
                                                                                                        value={data.id}
                                                                                                    >
                                                                                                        {data.valor}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Flujo Operativo</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    class="form-control"
                                                                                    name="montoFlujoOperativo"
                                                                                    value={this.state.montoFlujoOperativo}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={8}>
                                                                            <Form.Group>
                                                                                <Form.Label>
                                                                                    <strong>Monto Flujo Operativo</strong>
                                                                                </Form.Label>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                                <Col md={6}>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="InfoCrediticia"
                                                                >
                                                                    Información Crediticia
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="InfoCrediticia">
                                                                <Card.Body>
                                                                    <Row>
                                                                        <Col xs={12} md={12}>
                                                                            <h4>Información Crediticia</h4>
                                                                            <p>Datos mensuales en Colones</p>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <p>
                                                                                Sumatoria de cuotas mensuales de
                                                                                créditos en el CIC (No incluir la cuota
                                                                                del nuevo crédito)
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Monto Mensual</Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="cuotaMensualCreditoCIC"
                                                                                        value={
                                                                                            this.state.cuotaMensualCreditoCIC
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Monto Mensual</Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="cuotaMensualCreditoCIC"
                                                                                        value={
                                                                                            this.state.cuotaMensualCreditoCIC
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Anual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    class="form-control"
                                                                                    name="montoAnualCreditoCIC"
                                                                                    value={
                                                                                        this.state.montoAnualCreditoCIC
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <p>
                                                                                Sumatoria de cuotas mensuales de
                                                                                préstamos con otros acreedores
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Label>Monto Mensual</Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="cuotaMensualOtrosPrestamo"
                                                                                        value={
                                                                                            this.state
                                                                                                .cuotaMensualOtrosPrestamo
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Label>Monto Mensual</Form.Label>
                                                                                    <NumberFormat
                                                                                        thousandSeparator={true}
                                                                                        type="text"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        name="cuotaMensualOtrosPrestamo"
                                                                                        value={
                                                                                            this.state
                                                                                                .cuotaMensualOtrosPrestamo
                                                                                        }
                                                                                        onChange={this.encargarseCambio}
                                                                                        required
                                                                                        placeholder="0"
                                                                                        disabled={this.state.indButton}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Anual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    class="form-control"
                                                                                    name="montoAnualOtrosPrestamos"
                                                                                    value={
                                                                                        this.state.montoAnualOtrosPrestamos
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col xs={12} md={4}>
                                                                            <p>
                                                                                Monto de las sumatorias de las cuotas
                                                                                actuales
                                                                            </p>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Mensual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    class="form-control"
                                                                                    name="totalCuotasMensual"
                                                                                    value={this.state.totalCuotasMensual}
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col xs={12} md={4}>
                                                                            <Form.Group>
                                                                                <Form.Label>Monto Anual</Form.Label>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    class="form-control"
                                                                                    name="montoTotalCuotasAnuales"
                                                                                    value={
                                                                                        this.state.montoTotalCuotasAnuales
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    required
                                                                                    placeholder="0"
                                                                                    disabled
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </Row>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab
                                eventKey="garantiasApotadas"
                                title="Paso 2. Garantías Aportadas por el Cliente"
                                disabled={this.state.indIrPaso2}
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row hidden={this.state.tipoSolicitudSeleccionado.id == 2}>
                                        <Col md={12}>
                                            <Row>
                                                {" "}
                                                <Col xs={12} md={4}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>Tipo de Garantía</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioTipoGarantiaTabla}
                                                                value={
                                                                    this.state.tipoGarantiaSeleccionadaTabla.id
                                                                }
                                                                disabled
                                                            >
                                                                {this.state.tipoGarantiaAdicionalLista.map(
                                                                    function (data, key) {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.valor}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Tipo de Garantía</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioTipoGarantiaTabla}
                                                                value={
                                                                    this.state.tipoGarantiaSeleccionadaTabla.id
                                                                }
                                                                disabled={this.state.indButton}
                                                            >
                                                                {this.state.tipoGarantiaAdicionalLista.map(
                                                                    function (data, key) {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.valor}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>Descripción</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                minLength="5"
                                                                maxLength="100"
                                                                name="descripcionGarantia"
                                                                class="form-control"
                                                                placeholder="Descripción"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.descripcionGarantia}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Descripción</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                minLength="5"
                                                                maxLength="100"
                                                                name="descripcionGarantia"
                                                                class="form-control"
                                                                placeholder="Descripción"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.descripcionGarantia}
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={4}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>Valor</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="valorGarantia"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="999999999999"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.valorGarantia}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Valor</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                min="0"
                                                                max="999999999999"
                                                                class="form-control"
                                                                name="valorGarantia"
                                                                value={this.state.valorGarantia}
                                                                onChange={this.encargarseCambio}
                                                                required
                                                                placeholder="0"
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>% Máximo Aceptación</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="porcentajeMaximoAceptacion"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="100"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.porcentajeMaximoAceptacion}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>% Máximo Aceptación</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="porcentajeMaximoAceptacion"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="100"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.porcentajeMaximoAceptacion}
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>Monto Máximo Aceptación</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="montoMaximoAceptacion"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="999999999999"
                                                                step="0.01"
                                                                disabled
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.montoMaximoAceptacion}
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Monto Máximo Aceptación</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                min="0"
                                                                max="999999999999"
                                                                step="0.01"
                                                                class="form-control"
                                                                name="montoMaximoAceptacion"
                                                                value={this.state.montoMaximoAceptacion}
                                                                onChange={this.encargarseCambio}
                                                                required
                                                                placeholder="0"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>
                                                                Monto Definitivo Responsabilidad
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="montoDefinitivoResponsabilidad"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="999999999999"
                                                                onChange={this.encargarseCambio}
                                                                value={
                                                                    this.state.montoDefinitivoResponsabilidad
                                                                }
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Monto Definitivo Responsabilidad
                                                            </Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                min="0"
                                                                max="999999999999"
                                                                step="0.01"
                                                                class="form-control"
                                                                name="montoDefinitivoResponsabilidad"
                                                                value={
                                                                    this.state.montoDefinitivoResponsabilidad
                                                                }
                                                                onChange={this.encargarseCambio}
                                                                required
                                                                placeholder="0"
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group hidden>
                                                            <Form.Label>% Responsabilidad</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="porcentajeResponsabilidad"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="999999999999"
                                                                disabled
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.porcentajeResponsabilidad}
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>% Responsabilidad</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                name="porcentajeResponsabilidad"
                                                                class="form-control"
                                                                placeholder="0"
                                                                min="0"
                                                                max="999999999999"
                                                                disabled
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.porcentajeResponsabilidad}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={4}></Col>
                                                {this.state.indNoEditar ? (
                                                    <Col xs={12} md={4}>
                                                        <center>
                                                            <button
                                                                type="button"
                                                                onClick={this.agregarColumna}
                                                                class="btn btn-primary pull-left border-radius"
                                                                disabled
                                                                hidden
                                                            >
                                                                Agregar Garantía{" "}
                                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                        </center>
                                                    </Col>
                                                ) : (
                                                    <Col xs={12} md={4}>
                                                        <center>
                                                            <button
                                                                type="button"
                                                                onClick={this.agregarColumna}
                                                                class="btn btn-primary pull-left border-radius"
                                                                disabled={this.state.indButton}
                                                            >
                                                                Agregar Garantía{" "}
                                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                                            </button>
                                                        </center>
                                                    </Col>
                                                )}
                                                <Col xs={12} md={4}></Col>{" "}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={this.state.tipoSolicitudSeleccionado.id == 2} />
                                    <div hidden={this.state.tipoSolicitudSeleccionado.id == 2} class="table-responsive">
                                        <table
                                            id="tabla_garantias"
                                            class="table table-striped table-bordered"
                                            style={{ width: "100%" }}
                                        >
                                            <thead>
                                                <tr>
                                                    <th class="text-center">Tipo de Garantía</th>
                                                    <th class="text-center">Descripción</th>
                                                    <th class="text-center">Valor</th>
                                                    <th class="text-center">% Máximo Aceptación</th>
                                                    <th class="text-center">Monto Máximo Aceptación</th>
                                                    <th class="text-center">
                                                        Monto Definitivo Responsabilidad
                                                    </th>
                                                    <th class="text-center">% Responsabilidad</th>
                                                    <th class="text-center">Acciones</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.tablaGarantiasAportadas.map((c, index) => {
                                                    return (
                                                        <tr id={c.id}>
                                                            <td>{c.tipoGarantia.valor}</td>
                                                            <td>{c.descripcion}</td>
                                                            <td>
                                                                {parseFloat(c.valor)
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    c.porcentajeMaximoAceptacion
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {parseFloat(c.montoMaximoAceptacion)
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td>
                                                                {parseFloat(c.montoDefinitivoResponsabilidad)
                                                                    .toFixed(2)
                                                                    .toString()
                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                            </td>
                                                            <td>
                                                                {parseFloat(
                                                                    c.porcentajeResponsabilidad
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                {this.state.indNoEditar ? (
                                                                    <a
                                                                        class="btn btn-primary btn-sm border-radius"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => this.borrarColumna(index)}
                                                                        hidden
                                                                    >
                                                                        <i className="fa fa-minus-circle"></i>
                                                                    </a>
                                                                ) : (
                                                                    <a
                                                                        class="btn btn-primary btn-sm border-radius"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => this.borrarColumna(index)}
                                                                    >
                                                                        <i className="fa fa-minus-circle"></i>
                                                                    </a>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr hidden={this.state.tipoSolicitudSeleccionado.id == 2} />
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <p>
                                                            Total de las garantías aportadas por el cliente:
                                                        </p>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Monto Total</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                class="form-control"
                                                                name="montoTotalGarantiasAportadasCliente"
                                                                value={
                                                                    this.state.montoTotalGarantiasAportadasCliente
                                                                }
                                                                disabled
                                                                min="0"
                                                                placeholder="0"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>% Total</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                class="form-control"
                                                                placeholder="0"
                                                                value={
                                                                    this.state
                                                                        .porcentajeTotalGarantiasAportadasSolicitante
                                                                }
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <p>Monto Aval Solicitado:</p>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Monto Total</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                class="form-control"
                                                                name="montoTotalAvalSolicitado"
                                                                value={this.state.montoTotalAvalSolicitado}
                                                                onChange={this.encargarseCambio}
                                                                disabled
                                                                min="0"
                                                                max="999999999999"
                                                                placeholder="0"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>% Total</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                max="999999999999"
                                                                class="form-control"
                                                                placeholder="0"
                                                                name="porcentajeTotalAvalSolicitado"
                                                                value={this.state.porcentajeTotalAvalSolicitado}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={8}>
                                                <Row>
                                                    <Col xs={12} md={4}>
                                                        <p>Totales:</p>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Monto Total</Form.Label>
                                                            <NumberFormat
                                                                thousandSeparator={true}
                                                                type="text"
                                                                min="0"
                                                                max="999999999999"
                                                                class="form-control"
                                                                placeholder="0"
                                                                value={this.state.montoCredito}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>% Total</Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                min="0"
                                                                max="999999999999"
                                                                class="form-control"
                                                                placeholder="0"
                                                                value={100}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Form.Row>
                                                    <Col xs={12} md={12}>
                                                        <div class="table-responsive">
                                                            <table
                                                                class="table table-striped table-bordered"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="3">Cuadro Resumen</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Monto Total del Crédito
                                                                        </td>
                                                                        <td>
                                                                            {this.state.montoCredito
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                        <td>100.00%</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Total del monto de responsabilidades de
                                                                            las garantías aportadas por el cliente
                                                                        </td>
                                                                        <td>
                                                                            {this.state.montoTotalGarantiasAportadasCliente
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                        <td>
                                                                            {
                                                                                this.state
                                                                                    .porcentajeTotalGarantiasAportadasSolicitante
                                                                            }{" "}
                                                                            %
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Total del monto del AVAL solicitado(En
                                                                            caso del avales superiores al 50% del
                                                                            crédito. FODEMIPYME valorará y aprobará el
                                                                            crédito)
                                                                        </td>
                                                                        <td>
                                                                            {this.state.montoTotalAvalSolicitado
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                        <td>
                                                                            {this.state.porcentajeTotalAvalSolicitado}{" "}
                                                                            %
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Diferencias
                                                                        </td>
                                                                        <td>{this.state.diferenciasMonto.toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                                                        <td>{this.state.diferenciaPorcentaje.toString()
                                                                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "50%" }}>
                                                                            Saldo Crédito / Saldo Aval
                                                                        </td>
                                                                        <td>
                                                                            {this.state.saldoCredito
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                        <td>
                                                                            {this.state.saldoAval
                                                                                .toString()
                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Form.Row>
                                                    <Col xs={12} md={12}>
                                                        <div class="table-responsive">
                                                            <table
                                                                class="table table-striped table-bordered"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="3">Condiciones del Crédito</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Tipo de crédito</td>
                                                                        <td>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioTipoCredito}
                                                                                    disabled
                                                                                    value={
                                                                                        this.state.tipoCreditoSeleccionado
                                                                                            .id
                                                                                    }
                                                                                >
                                                                                    {this.state.tipoCreditoLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            ) : (
                                                                                <Form.Control
                                                                                    as="select"
                                                                                    onChange={this.cambioTipoCredito}
                                                                                    disabled
                                                                                    value={
                                                                                        this.state.tipoCreditoSeleccionado
                                                                                            .id
                                                                                    }
                                                                                >
                                                                                    {this.state.tipoCreditoLista.map(
                                                                                        function (data, key) {
                                                                                            return (
                                                                                                <option
                                                                                                    key={key}
                                                                                                    value={data.id}
                                                                                                >
                                                                                                    {data.valor}
                                                                                                </option>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Form.Control>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Tasa de Interés Anual</td>
                                                                        <td style={{ width: "30%" }}>
                                                                            {" "}
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        name="tasaInteresAnual"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.tasaInteresAnual}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        name="tasaInteresAnual"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.tasaInteresAnual}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Plazo del Crédito en Meses</td>
                                                                        <td style={{ width: "30%" }}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        name="plazoCreditoMeses"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.plazoCreditoMeses}
                                                                                        disabled
                                                                                    />
                                                                                </Form.Group>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        max="999999999999"
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        name="plazoCreditoMeses"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.plazoCreditoMeses}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr hidden={!this.state.indPlazoBallon}>
                                                                        <td>Plazo Ballon en Meses</td>
                                                                        <td style={{ width: "30%" }}>
                                                                            {this.state.indNoEditar ? (
                                                                                <Col xs={12} md={12}>
                                                                                    {this.state.indPlazoBallon ? (
                                                                                        <Form.Group>
                                                                                            <Form.Control
                                                                                                type="number"
                                                                                                min="0"
                                                                                                class="form-control"
                                                                                                placeholder="0"
                                                                                                name="plazoBallon"
                                                                                                onChange={this.encargarseCambio}
                                                                                                value={this.state.plazoBallon}
                                                                                                disabled
                                                                                            />
                                                                                        </Form.Group>
                                                                                    ) : (
                                                                                        <Form.Group></Form.Group>
                                                                                    )}
                                                                                </Col>
                                                                            ) : (
                                                                                <Form.Group>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        min="0"
                                                                                        class="form-control"
                                                                                        placeholder="0"
                                                                                        name="plazoBallon"
                                                                                        onChange={this.encargarseCambio}
                                                                                        value={this.state.plazoBallon}
                                                                                        hidden={!this.state.indPlazoBallon}
                                                                                    />
                                                                                </Form.Group>
                                                                            )}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Cuota financiera mensual del nuevo crédito
                                                                        </td>
                                                                        <td style={{ width: "30%" }}>
                                                                            <Form.Group>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    name="cuotaMensualNuevoCredito"
                                                                                    value={
                                                                                        this.state
                                                                                            .cuotaMensualNuevoCredito
                                                                                    }
                                                                                    onChange={this.encargarseCambio}
                                                                                    disabled
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    placeholder="0"
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            Cuota financiera anual del nuevo crédito
                                                                        </td>
                                                                        <td style={{ width: "30%" }}>
                                                                            <Form.Group>
                                                                                <NumberFormat
                                                                                    thousandSeparator={true}
                                                                                    type="text"
                                                                                    class="form-control"
                                                                                    name="cuotaAnualNuevoCredito"
                                                                                    value={
                                                                                        this.state.cuotaAnualNuevoCredito
                                                                                    }
                                                                                    disabled
                                                                                    min="0"
                                                                                    max="999999999999"
                                                                                    placeholder="0"
                                                                                />
                                                                            </Form.Group>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                {this.state.indMostrarHistoricoPagos ? (
                                                    <Form.Row>
                                                        <Col xs={12} md={12}>
                                                            <div class="table-responsive">
                                                                <table
                                                                    class="table table-striped table-bordered"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="6">Cuadro de pagos</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th class="text-center">Saldo crédito</th>
                                                                            <th class="text-center">Saldo aval</th>
                                                                            <th class="text-center">Fecha reporte</th>
                                                                            <th class="text-center">
                                                                                Monto de Comisión
                                                                            </th>
                                                                            <th class="text-center">Descripción</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.historicoPagos.map(
                                                                            (c, index) => {
                                                                                return (
                                                                                    <tr id={c.id}>
                                                                                        <td>
                                                                                            {c.saldo
                                                                                                .toFixed(2)
                                                                                                .toString()
                                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        </td>
                                                                                        <td>
                                                                                            {c.saldoAval
                                                                                                .toFixed(2)
                                                                                                .toString()
                                                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                                        </td>
                                                                                        <td>
                                                                                            {moment(c.fechaReporte)
                                                                                                .locale("en")
                                                                                                .format("YYYY-MM-DD")}
                                                                                        </td>
                                                                                        <td>{c.comisionMensual.toFixed(2).toString()
                                                                                            .replace(
                                                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                                                ","
                                                                                            )}</td>
                                                                                        <td>{c.mensaje}</td>
                                                                                    </tr>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Form.Row>
                                                ) : (
                                                    <Form.Row></Form.Row>
                                                )}
                                                {this.state.indMostrarHistoricoPagos ? (
                                                    <Form.Row>
                                                        <Col xs={12} md={12}>
                                                            <div class="table-responsive">
                                                                <table
                                                                    class="table table-striped table-bordered"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <thead>
                                                                        <tr>
                                                                            <th colSpan="2">Montos Totales de Pagos</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{ width: "50%" }}>
                                                                                Monto Pagado del Crédito
                                                                            </td>
                                                                            <td>
                                                                                {this.state.totalMontoPagado
                                                                                    .toString()
                                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: "50%" }}>
                                                                                Monto Total de Comisiones
                                                                            </td>
                                                                            <td>
                                                                                {this.state.totalComisionMensual
                                                                                    .toString()
                                                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </Col>
                                                    </Form.Row>
                                                ) : (
                                                    <Form.Row></Form.Row>
                                                )}
                                            </Col>
                                        </Row>
                                    </Container>
                                </Container>
                            </Tab>
                            <Tab
                                eventKey="documentacionAdjunta"
                                title="Paso 3. Documentación Adjunta Solicitud Aval"
                                disabled={this.state.indIrPaso3}
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Copia del documento de identificación del cliente físico
                                            </Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoFotoCedulaPersonaFisica ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFotoCedulaPersonaFisica(
                                                                    this.state
                                                                        .nombreArchivoFotoCedulaPersonaFisica
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            disabled
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            disabled
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFotoCedulaPersonaFisica"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFotoCedulaPersonaFisica">
                                                    <Form.File
                                                        name="nombreArchivoFotoCedulaPersonaFisica"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoFotoCedulaPersonaFisica ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFotoCedulaPersonaFisica(
                                                                    this.state
                                                                        .nombreArchivoFotoCedulaPersonaFisica
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                        <a
                                                            onClick={
                                                                this.mostrarInputArchivoFotoCedulaPersonaFisica
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFotoCedulaPersonaFisica"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFotoCedulaPersonaFisica">
                                                    <Form.File
                                                        name="nombreArchivoFotoCedulaPersonaFisica"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Para personas jurídicas, copia u original de la
                                                personería jurídica registral o notarial, con no más de
                                                un mes de emisión
                                            </Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoFotoCedulaPersonaJuridica ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFotoCedulaPersonaJuridica(
                                                                    this.state
                                                                        .nombreArchivoFotoCedulaPersonaJuridica
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFotoCedulaPersonaJuridica"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFotoCedulaPersonaJuridica">
                                                    <Form.File
                                                        name="nombreArchivoFotoCedulaPersonaJuridica"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoFotoCedulaPersonaJuridica ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFotoCedulaPersonaJuridica(
                                                                    this.state
                                                                        .nombreArchivoFotoCedulaPersonaJuridica
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this
                                                                    .mostrarInputArchivoFotoCedulaPersonaJuridica
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFotoCedulaPersonaJuridica"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFotoCedulaPersonaJuridica">
                                                    <Form.File
                                                        name="nombreArchivoFotoCedulaPersonaJuridica"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Para personas jurídicas, copia del documento de
                                                identificación del apoderado o apoderados que la
                                                representan
                                            </Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoFotoCedulaRepresentantePersonaJuridica ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFotoCedulaRepresentantePersonaJuridica(
                                                                    this.state
                                                                        .nombreArchivoFotoCedulaRepresentantePersonaJuridica
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFotoCedulaRepresentantePersonaJuridica"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFotoCedulaRepresentantePersonaJuridica">
                                                    <Form.File
                                                        name="nombreArchivoFotoCedulaRepresentantePersonaJuridica"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoFotoCedulaRepresentantePersonaJuridica ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFotoCedulaRepresentantePersonaJuridica(
                                                                    this.state
                                                                        .nombreArchivoFotoCedulaRepresentantePersonaJuridica
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this
                                                                    .mostrarInputArchivoFotoCedulaRepresentantePersonaJuridica
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFotoCedulaRepresentantePersonaJuridica"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFotoCedulaRepresentantePersonaJuridica">
                                                    <Form.File
                                                        name="nombreArchivoFotoCedulaRepresentantePersonaJuridica"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requisitos de formalidad (1 de 3){" "}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                {this.state.indNoEditar ? (
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>
                                                            {" "}
                                                            <strong>Certificaciones de la CCSS</strong>
                                                        </Form.Label>
                                                        {this.state.indNombreArchivoCertificacionCCSS ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoCertificacionCCSS(
                                                                            this.state.nombreArchivoCertificacionCCSS
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    href="#"
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                    hidden
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoCertificacionCCSS"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoCertificacionCCSS">
                                                            <Form.File
                                                                name="nombreArchivoCertificacionCCSS"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>
                                                            {" "}
                                                            <strong>Certificaciones de la CCSS</strong>
                                                        </Form.Label>
                                                        {this.state.indNombreArchivoCertificacionCCSS ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoCertificacionCCSS(
                                                                            this.state.nombreArchivoCertificacionCCSS
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    onClick={
                                                                        this.mostrarInputArchivoCertificacionCCSS
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoCertificacionCCSS"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled={this.state.indButton}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoCertificacionCCSS">
                                                            <Form.File
                                                                name="nombreArchivoCertificacionCCSS"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label>Estado</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioCertificacionCCSS}
                                                                value={
                                                                    this.state.estadoCertificacionCCSSSeleccionado
                                                                        .id
                                                                }
                                                                disabled
                                                            >
                                                                {this.state.estadoCertificacionCCSSLista.map(
                                                                    function (data, key) {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.valor}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Estado</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioCertificacionCCSS}
                                                                value={
                                                                    this.state.estadoCertificacionCCSSSeleccionado
                                                                        .id
                                                                }
                                                                disabled={this.state.indButton}
                                                            >
                                                                {this.state.estadoCertificacionCCSSLista.map(
                                                                    function (data, key) {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.valor}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label>Fecha emisión o consulta</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="fechaEmisionCertificacionCCSS"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.fechaEmisionCertificacionCCSS}
                                                                required
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Fecha emisión o consulta</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="fechaEmisionCertificacionCCSS"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                value={this.state.fechaEmisionCertificacionCCSS}
                                                                required
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requisitos de formalidad (2 de 3){" "}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                {this.state.indNoEditar ? (
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>
                                                            {" "}
                                                            <strong>Tributación directa</strong>
                                                        </Form.Label>
                                                        {this.state.indNombreArchivoTributacionDirecta ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoTributacionDirecta(
                                                                            this.state.nombreArchivoTributacionDirecta
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    href="#"
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                    hidden
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoTributacionDirecta"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoTributacionDirecta">
                                                            <Form.File
                                                                name="nombreArchivoTributacionDirecta"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>
                                                            {" "}
                                                            <strong>Tributación directa</strong>
                                                        </Form.Label>
                                                        {this.state.indNombreArchivoTributacionDirecta ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoTributacionDirecta(
                                                                            this.state.nombreArchivoTributacionDirecta
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    onClick={
                                                                        this.mostrarInputArchivoTributacionDirecta
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoTributacionDirecta"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled={this.state.indButton}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoTributacionDirecta">
                                                            <Form.File
                                                                name="nombreArchivoTributacionDirecta"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label></Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioEstadoTributacion}
                                                                value={
                                                                    this.state
                                                                        .estadoTributacionDirectaSeleccionada.id
                                                                }
                                                                disabled
                                                            >
                                                                {this.state.estadoTributacionDirectaLista.map(
                                                                    function (data, key) {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.valor}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label></Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioEstadoTributacion}
                                                                value={
                                                                    this.state
                                                                        .estadoTributacionDirectaSeleccionada.id
                                                                }
                                                                disabled={this.state.indButton}
                                                            >
                                                                {this.state.estadoTributacionDirectaLista.map(
                                                                    function (data, key) {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.valor}
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Requisitos de formalidad (3 de 3){" "}
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                {this.state.indNoEditar ? (
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>
                                                            {" "}
                                                            <strong>Riesgos de trabajo</strong>
                                                        </Form.Label>
                                                        {this.state.indNombreArchivoRiesgosTrabajo ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoRiesgosTrabajo(
                                                                            this.state.nombreArchivoRiesgosTrabajo
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    href="#"
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                    hidden
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoRiesgosTrabajo"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoRiesgosTrabajo">
                                                            <Form.File
                                                                name="nombreArchivoRiesgosTrabajo"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>
                                                            {" "}
                                                            <strong>Riesgos de trabajo</strong>
                                                        </Form.Label>
                                                        {this.state.indNombreArchivoRiesgosTrabajo ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoRiesgosTrabajo(
                                                                            this.state.nombreArchivoRiesgosTrabajo
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    onClick={
                                                                        this.mostrarInputArchivoRiesgosTrabajo
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoRiesgosTrabajo"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled={this.state.indButton}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoRiesgosTrabajo">
                                                            <Form.File
                                                                name="nombreArchivoRiesgosTrabajo"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label></Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioRiesgoTrabajo}
                                                                value={this.state.riesgosTrabajoSeleccionado.id}
                                                                disabled
                                                            >
                                                                {this.state.riesgosTrabajoLista.map(function (
                                                                    data,
                                                                    key
                                                                ) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label></Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioRiesgoTrabajo}
                                                                value={this.state.riesgosTrabajoSeleccionado.id}
                                                                disabled={this.state.indButton}
                                                            >
                                                                {this.state.riesgosTrabajoLista.map(function (
                                                                    data,
                                                                    key
                                                                ) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={8}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Certificación de condición PYME, emitida por el
                                                            MEIC o MAG según corresponda
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                                {this.state.indNoEditar ? (
                                                    <Col xs={12} md={6}>
                                                        {this.state
                                                            .indNombreArchivoCertificacionPymePympa ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoCertificacionPymePympa(
                                                                            this.state
                                                                                .nombreArchivoCertificacionPymePympa
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    href="#"
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                    hidden
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoCertificacionPymePympa"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoCertificacionPymePympa">
                                                            <Form.File
                                                                name="nombreArchivoCertificacionPymePympa"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <Col xs={12} md={6}>
                                                        {this.state
                                                            .indNombreArchivoCertificacionPymePympa ? (
                                                            <Form.Group>
                                                                <a
                                                                    onClick={() =>
                                                                        this.descargarArchivoCertificacionPymePympa(
                                                                            this.state
                                                                                .nombreArchivoCertificacionPymePympa
                                                                        )
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>{" "}
                                                                <a
                                                                    onClick={
                                                                        this
                                                                            .mostrarInputArchivoCertificacionPymePympa
                                                                    }
                                                                    class="btn btn-lg"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <img
                                                                        src="/assets/images/upload-pdf-icon.png"
                                                                        width="60"
                                                                        height="60"
                                                                        style={{ borderRadius: "20px" }}
                                                                        alt="pdf-icon"
                                                                    />
                                                                </a>
                                                            </Form.Group>
                                                        ) : (
                                                            <Form.Group>
                                                                <Form.File
                                                                    name="nombreArchivoCertificacionPymePympa"
                                                                    onChange={this.encargarseCambioArchivos}
                                                                    accept="application/pdf"
                                                                    disabled={this.state.indButton}
                                                                />
                                                            </Form.Group>
                                                        )}
                                                        <Form.Group id="nombreArchivoCertificacionPymePympa">
                                                            <Form.File
                                                                name="nombreArchivoCertificacionPymePympa"
                                                                onChange={this.encargarseCambioArchivos}
                                                                accept="application/pdf"
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label>Fecha vencimiento</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="fechaVencimientoCertificacionPymePympa"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                value={
                                                                    this.state
                                                                        .fechaVencimientoCertificacionPymePympa
                                                                }
                                                                required
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Fecha vencimiento</Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                name="fechaVencimientoCertificacionPymePympa"
                                                                class="form-control"
                                                                onChange={this.encargarseCambio}
                                                                value={
                                                                    this.state
                                                                        .fechaVencimientoCertificacionPymePympa
                                                                }
                                                                required
                                                                disabled={this.state.indButton}
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME}>
                                        <Col xs={12} md={8}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={6}>
                                                    {" "}
                                                    <Form.Group>
                                                        <Form.Label>
                                                            En caso de certificación condición PYMPA del MAG,
                                                            adjuntar el cálculo del tamaño de la empresa
                                                        </Form.Label>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={4}>
                                            <Row style={{ padding: "0 0 20px 0" }}>
                                                <Col xs={12} md={12}>
                                                    {this.state.indNoEditar ? (
                                                        <Form.Group>
                                                            <Form.Label>Tamaño empresa</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioTamanoPymePymPa}
                                                                value={
                                                                    this.state.tamanoPymePympaSeleccionado.id
                                                                }
                                                                disabled
                                                            >
                                                                {this.state.tamanoPymePympaLista.map(function (
                                                                    data,
                                                                    key
                                                                ) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group>
                                                            <Form.Label>Tamaño empresa</Form.Label>
                                                            <Form.Control
                                                                as="select"
                                                                onChange={this.cambioTamanoPymePymPa}
                                                                value={
                                                                    this.state.tamanoPymePympaSeleccionado.id
                                                                }
                                                                disabled={this.state.indButton}
                                                            >
                                                                {this.state.tamanoPymePympaLista.map(function (
                                                                    data,
                                                                    key
                                                                ) {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.valor}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr hidden={idFondo != REACT_APP_OPERADORA_FODEMIPYME} />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={4}>
                                            {" "}
                                            <Form.Label>Consulta abierta del CIC de SUGEF</Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={4}>
                                                {this.state.indNombreArchivoCICSugef ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoCICSugef(
                                                                    this.state.nombreArchivoCICSugef
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoCICSugef"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoCICSugef">
                                                    <Form.File
                                                        name="nombreArchivoCICSugef"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={4}>
                                                {this.state.indNombreArchivoCICSugef ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoCICSugef(
                                                                    this.state.nombreArchivoCICSugef
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this.mostrarInputArchivoCICSugef
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoCICSugef"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoCICSugef">
                                                    <Form.File
                                                        name="nombreArchivoCICSugef"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                        <Col xs={12} md={4}>
                                            {this.state.indNoEditar ? (
                                                <Form.Group>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.cambionConsultaCICSUGEF}
                                                        value={this.state.idConsultaAbierta}
                                                        disabled
                                                    >
                                                        {this.state.consultaAbiertaCICSUGEFLista.map(
                                                            function (data, key) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                            ) : (
                                                <Form.Group>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.cambionConsultaCICSUGEF}
                                                        value={this.state.idConsultaAbierta}
                                                        disabled={this.state.indButton}
                                                    >
                                                        {this.state.consultaAbiertaCICSUGEFLista.map(
                                                            function (data, key) {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.valor}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Copia del documento o acta de aprobación del crédito
                                            </Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoActaAprobacionCredito ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoActaAprobacionCredito(
                                                                    this.state.nombreArchivoActaAprobacionCredito
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoActaAprobacionCredito"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoActaAprobacionCredito">
                                                    <Form.File
                                                        name="nombreArchivoActaAprobacionCredito"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoActaAprobacionCredito ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoActaAprobacionCredito(
                                                                    this.state.nombreArchivoActaAprobacionCredito
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this.mostrarInputArchivoActaAprobacionCredito
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoActaAprobacionCredito"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoActaAprobacionCredito">
                                                    <Form.File
                                                        name="nombreArchivoActaAprobacionCredito"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Estudio registral de bienes muebles, actualizado a la
                                                fecha de aprobación del crédito
                                            </Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoEstudioRegistralBienesMuebles ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoEstudioRegistralBienesMuebles(
                                                                    this.state
                                                                        .nombreArchivoEstudioRegistralBienesMuebles
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoEstudioRegistralBienesMuebles"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoEstudioRegistralBienesMuebles">
                                                    <Form.File
                                                        name="nombreArchivoEstudioRegistralBienesMuebles"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoEstudioRegistralBienesMuebles ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoEstudioRegistralBienesMuebles(
                                                                    this.state
                                                                        .nombreArchivoEstudioRegistralBienesMuebles
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this
                                                                    .mostrarInputArchivoEstudioRegistralBienesMuebles
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoEstudioRegistralBienesMuebles"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoEstudioRegistralBienesMuebles">
                                                    <Form.File
                                                        name="nombreArchivoEstudioRegistralBienesMuebles"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>
                                                Estudio registral de bienes inmuebles, actualizado a la
                                                fecha de aprobación del crédito
                                            </Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoExcluirGarantiaBienMuebleInmueble ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoExcluirGarantiaBienMuebleInmueble(
                                                                    this.state
                                                                        .nombreArchivoExcluirGarantiaBienMuebleInmueble
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoExcluirGarantiaBienMuebleInmueble"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoExcluirGarantiaBienMuebleInmueble">
                                                    <Form.File
                                                        name="nombreArchivoExcluirGarantiaBienMuebleInmueble"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoExcluirGarantiaBienMuebleInmueble ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoExcluirGarantiaBienMuebleInmueble(
                                                                    this.state
                                                                        .nombreArchivoExcluirGarantiaBienMuebleInmueble
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this
                                                                    .mostrarInputArchivoExcluirGarantiaBienMuebleInmueble
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoExcluirGarantiaBienMuebleInmueble"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoExcluirGarantiaBienMuebleInmueble">
                                                    <Form.File
                                                        name="nombreArchivoExcluirGarantiaBienMuebleInmueble"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>Se adjunta factura proforma</Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoFacturaProforma ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFacturaProforma(
                                                                    this.state.nombreArchivoFacturaProforma
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFacturaProforma"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFacturaProforma">
                                                    <Form.File
                                                        name="nombreArchivoFacturaProforma"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoFacturaProforma ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoFacturaProforma(
                                                                    this.state.nombreArchivoFacturaProforma
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoFacturaProforma}
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoFacturaProforma"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoFacturaProforma">
                                                    <Form.File
                                                        name="nombreArchivoFacturaProforma"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={6}>
                                            {" "}
                                            <Form.Label>Se adjunta informe de avalúo</Form.Label>
                                        </Col>
                                        {this.state.indNoEditar ? (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoInformeAvaluo ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoInformeAvaluo(
                                                                    this.state.nombreArchivoInformeAvaluo
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoInformeAvaluo"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoInformeAvaluo">
                                                    <Form.File
                                                        name="nombreArchivoInformeAvaluo"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoInformeAvaluo ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoInformeAvaluo(
                                                                    this.state.nombreArchivoInformeAvaluo
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoInformeAvaluo}
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoInformeAvaluo"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoInformeAvaluo">
                                                    <Form.File
                                                        name="nombreArchivoInformeAvaluo"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    {(
                                        ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ||
                                            ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) &&
                                                this.state.nombreArchivoContrato != null && this.state.nombreArchivoPagare != null))
                                        && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
                                    ) ? (
                                        <Row style={{ padding: "0 0 20px 0" }}>
                                            <Col xs={12} md={4}></Col>
                                            <Col xs={12} md={4}>
                                                {" "}
                                                <Form.Label><strong>Documentación Formalización</strong></Form.Label>
                                            </Col>
                                            <Col xs={12} md={4}></Col>
                                        </Row>
                                    ) : (
                                        <Row></Row>
                                    )}
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        {(
                                            ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ||
                                                ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) &&
                                                    this.state.nombreArchivoContrato != null && this.state.nombreArchivoPagare != null))
                                            && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
                                        ) ? (
                                            <Col xs={12} md={6}>
                                                {" "}
                                                <Form.Label>Se adjunta contrato de aval</Form.Label>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}></Col>
                                        )}
                                        {this.state.indDocumentacionFormalizacion && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO ? (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoContrato ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoContrato(
                                                                    this.state.nombreArchivoContrato
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoContrato"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                            hidden={
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_FORMALIZADO
                                                            }
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoContrato">
                                                    <Form.File
                                                        name="nombreArchivoContrato"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6} hidden={this.state.tipoSolicitudSeleccionado.id != REACT_APP_NATURALEZA_LINEA_CREDITO}>
                                                {this.state.indNombreArchivoContrato ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoContrato(
                                                                    this.state.nombreArchivoContrato
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoContrato}
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoContrato"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                            hidden={
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_FORMALIZADO
                                                            }
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoContrato">
                                                    <Form.File
                                                        name="nombreArchivoContrato"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    {(
                                        ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ||
                                            ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) &&
                                                this.state.nombreArchivoContrato != null && this.state.nombreArchivoPagare != null))
                                        && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
                                    ) ? (
                                        <hr style={{ borderTop: "1px dashed gray" }} />
                                    ) : (
                                        <hr hidden />
                                    )}
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        {(
                                            ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ||
                                                ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) &&
                                                    this.state.nombreArchivoContrato != null && this.state.nombreArchivoPagare != null))
                                            && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
                                        ) ? (
                                            <Col xs={12} md={6}>
                                                {" "}
                                                <Form.Label>Se adjunta pagaré del aval</Form.Label>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}></Col>
                                        )}
                                        {this.state.indDocumentacionFormalizacion && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO ? (
                                            <Col xs={12} md={6}>
                                                {this.state.indNombreArchivoPagare ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoPagare(
                                                                    this.state.nombreArchivoPagare
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoPagare"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                            hidden={
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_FORMALIZADO
                                                            }
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoPagare">
                                                    <Form.File
                                                        name="nombreArchivoPagare"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6} hidden={this.state.tipoSolicitudSeleccionado.id != REACT_APP_NATURALEZA_LINEA_CREDITO}>
                                                {this.state.indNombreArchivoPagare ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoPagare(
                                                                    this.state.nombreArchivoPagare
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={this.mostrarInputArchivoPagare}
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden={idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_JEFATURA}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoPagare"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                            hidden={
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_FORMALIZADO
                                                            }
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoPagare">
                                                    <Form.File
                                                        name="nombreArchivoPagare"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                    {(
                                        ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                                            this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ||
                                            ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) &&
                                                this.state.nombreArchivoContrato != null && this.state.nombreArchivoPagare != null))
                                        && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
                                    ) ? (
                                        <hr style={{ borderTop: "1px dashed gray" }} />
                                    ) : (
                                        <hr hidden />
                                    )}
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        {(
                                            ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_PROCESANDO_MENSUALMENTE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_FODEMIPYME ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_AUTORIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ATRASO_EFA ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_EN_ATRASO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FINALIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_IRRECUPERABLE ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_AUTORIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_FORMALIZADO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO ||
                                                this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ||
                                                ((this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_RECHAZADO_FODEMIPYME || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_DESESTIMADO_ENTIDAD) &&
                                                    this.state.nombreArchivoContrato != null && this.state.nombreArchivoPagare != null))
                                            && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO
                                        ) ? (
                                            <Col xs={12} md={6} hidden={this.state.tipoSolicitudSeleccionado.id != REACT_APP_NATURALEZA_LINEA_CREDITO}>
                                                {" "}
                                                <Form.Label>
                                                    Se adjunta comprobante de pago de comisión de
                                                    formalización
                                                </Form.Label>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6}></Col>
                                        )}
                                        {this.state.indDocumentacionFormalizacion && this.state.tipoSolicitudSeleccionado.id == REACT_APP_NATURALEZA_LINEA_CREDITO ? (
                                            <Col xs={12} md={6}>
                                                {this.state
                                                    .indNombreArchivoComprobantePagoComisionFormalizacion ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoComprobantePagoComisionFormalizacion(
                                                                    this.state
                                                                        .nombreArchivoComprobantePagoComisionFormalizacion
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            href="#"
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoComprobantePagoComisionFormalizacion"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled
                                                            hidden={
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_FORMALIZADO
                                                            }
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoComprobantePagoComisionFormalizacion">
                                                    <Form.File
                                                        name="nombreArchivoComprobantePagoComisionFormalizacion"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ) : (
                                            <Col xs={12} md={6} hidden={this.state.tipoSolicitudSeleccionado.id != REACT_APP_NATURALEZA_LINEA_CREDITO}>
                                                {this.state
                                                    .indNombreArchivoComprobantePagoComisionFormalizacion ? (
                                                    <Form.Group>
                                                        <a
                                                            onClick={() =>
                                                                this.descargarArchivoComprobantePagoComisionFormalizacion(
                                                                    this.state
                                                                        .nombreArchivoComprobantePagoComisionFormalizacion
                                                                )
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <img
                                                                src="/assets/images/pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>{" "}
                                                        <a
                                                            onClick={
                                                                this
                                                                    .mostrarInputArchivoComprobantePagoComisionFormalizacion
                                                            }
                                                            class="btn btn-lg"
                                                            style={{ cursor: "pointer" }}
                                                            hidden={idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME || idRol == REACT_APP_ROL_JEFATURA}
                                                        >
                                                            <img
                                                                src="/assets/images/upload-pdf-icon.png"
                                                                width="60"
                                                                height="60"
                                                                style={{ borderRadius: "20px" }}
                                                                alt="pdf-icon"
                                                            />
                                                        </a>
                                                    </Form.Group>
                                                ) : (
                                                    <Form.Group>
                                                        <Form.File
                                                            name="nombreArchivoComprobantePagoComisionFormalizacion"
                                                            onChange={this.encargarseCambioArchivos}
                                                            accept="application/pdf"
                                                            disabled={this.state.indButton}
                                                            hidden={
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_AUTORIZADO &&
                                                                this.state.estadoSeleccionado.id != REACT_APP_AVAL_ESTADO_FORMALIZADO
                                                            }
                                                        />
                                                    </Form.Group>
                                                )}
                                                <Form.Group id="nombreArchivoComprobantePagoComisionFormalizacion">
                                                    <Form.File
                                                        name="nombreArchivoComprobantePagoComisionFormalizacion"
                                                        onChange={this.encargarseCambioArchivos}
                                                        accept="application/pdf"
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )}
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab
                                eventKey="Asignaciones"
                                title="Paso 4. Asignaciones (Usuarios - Estados) / Observaciones"
                            >
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Form.Row>
                                        <Col xs={12} md={2}></Col>
                                        <Col xs={12} md={4}>
                                            {this.state.indNoEditarEstado ? (
                                                <Form.Group>
                                                    <Form.Label>Estado Aval</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.cambioEstadoAval}
                                                        disabled
                                                        value={this.state.estadoSeleccionado.id}
                                                    >
                                                        {this.state.estadosLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            ) : (
                                                <Form.Group>
                                                    <Form.Label>Estado Aval</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.cambioEstadoAval}
                                                        disabled={this.state.indButton}
                                                        value={this.state.estadoSeleccionado.id}
                                                    >
                                                        {this.state.estadosLista.map(function (data, key) {
                                                            return (
                                                                <option key={key} value={data.id}>
                                                                    {data.valor}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}
                                        </Col>
                                        <Col xs={12} md={4}>
                                            {this.state.indNoEditarEjecutivo ? (
                                                <Form.Group>
                                                    <Form.Label>Usuario</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.cambioEjecutivo}
                                                        disabled
                                                        value={this.state.ejecutivoSeleccionado.b2CId}
                                                    >
                                                        {this.state.ejecutivoLista.map(function (
                                                            data,
                                                            key
                                                        ) {
                                                            return (
                                                                <option key={key} value={data.b2CId}>
                                                                    {data.correoElectronico}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            ) : (
                                                <Form.Group>
                                                    <Form.Label>Usuario</Form.Label>
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.cambioEjecutivo}
                                                        disabled={this.state.indButton}
                                                        value={this.state.ejecutivoSeleccionado.b2CId}
                                                    >
                                                        {this.state.ejecutivoLista.map(function (
                                                            data,
                                                            key
                                                        ) {
                                                            return (
                                                                <option key={key} value={data.b2CId}>
                                                                    {data.correoElectronico}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Form.Group>
                                            )}
                                            <span class="input-group-addon" id="spinUsuarios">
                                                <i class="fa fa-refresh fa-spin"></i>
                                            </span>
                                        </Col>
                                        <Col xs={12} md={2}></Col>
                                    </Form.Row>
                                    <hr></hr>
                                    <Row style={{ padding: "0 0 20px 0" }}>
                                        <Col xs={12} md={12}>
                                            {this.state.indNoEditar &&
                                                this.state.indNoEditarObservaciones ? (
                                                <Form.Group>
                                                    <Form.Label>Observación</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="observacion"
                                                        minLength="5"
                                                        maxLength="1000"
                                                        onChange={this.encargarseCambio}
                                                        value={this.state.observacion}
                                                        rows={3}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            ) : (
                                                <Form.Group>
                                                    <Form.Label>Observación</Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        name="observacion"
                                                        minLength="5"
                                                        maxLength="1000"
                                                        onChange={this.encargarseCambio}
                                                        value={this.state.observacion}
                                                        rows={3}
                                                        disabled={this.state.indButton}
                                                    />
                                                </Form.Group>
                                            )}
                                        </Col>
                                        <Col xs={12} md={12}>
                                            <button
                                                type="submit"
                                                class="btn btn-primary border-radius"
                                                disabled={this.state.indButton}
                                                hidden={
                                                    this.state.indNoEditarObservaciones ||
                                                    this.state.indEditarObservaciones
                                                }
                                                onClick={this.AgregarObservaciones}
                                            >
                                                Guardar observación
                                                {" "}
                                                <i class="fa fa-edit" aria-hidden="true"></i>
                                            </button>
                                        </Col>
                                        {this.state.indEditarObservaciones ? (
                                            <Col xs={12} md={12}>
                                                <button
                                                    type="submit"
                                                    class="btn btn-primary border-radius"
                                                    disabled={this.state.indButton}
                                                    onClick={this.EditarObservaciones}
                                                >
                                                    Editar observación
                                                    {" "}
                                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                                </button>
                                                <button
                                                    type="submit"
                                                    class="btn btn-danger border-radius"
                                                    disabled={this.state.indButton}
                                                    onClick={this.CancelarEdicionObservacion}
                                                >
                                                    Cancelar
                                                    {" "}
                                                    <i class="fa fa-window-close" aria-hidden="true"></i>
                                                </button>
                                            </Col>
                                        ) : (
                                            <Col></Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            {this.state.indMostrarHistoricoObservaciones ? (
                                                <Form.Row>
                                                    <Col xs={12} md={12}>
                                                        <div class="table-responsive">
                                                            <table
                                                                class="table table-striped table-bordered"
                                                                style={{ width: "100%" }}
                                                            >
                                                                <thead>
                                                                    <tr>
                                                                        <th colSpan="6">Histórico observaciones</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <th class="text-center">Fecha creación</th>
                                                                        <th class="text-center">Usuario</th>
                                                                        <th class="text-center">Observación</th>
                                                                        <th>Acciones</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.historicoObservaciones.map(
                                                                        (c, index) => {
                                                                            return (
                                                                                <tr id={c.id}>
                                                                                    <td>
                                                                                        {moment(c.fechaCreacion)
                                                                                            .locale("en")
                                                                                            .format("YYYY-MM-DD HH:mm:ss")}
                                                                                    </td>
                                                                                    <td>
                                                                                        {c.ejecutivoCreador.correoElectronico}
                                                                                    </td>
                                                                                    <td>{c.observaciones}</td>
                                                                                    <td>
                                                                                        {c.ejecutivoCreador.b2CId !=
                                                                                            idUsuarioConectado ||
                                                                                            this.state
                                                                                                .indNoEditarObservaciones ? (
                                                                                            <a
                                                                                                class="btn btn-primary btn-sm border-radius"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                onClick={() =>
                                                                                                    this.MostrarEditarObservaciones(
                                                                                                        c,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                                hidden
                                                                                            >
                                                                                                <i className="fa fa-pencil"></i>
                                                                                            </a>
                                                                                        ) : (
                                                                                            <a
                                                                                                class="btn btn-primary btn-sm border-radius"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                onClick={() =>
                                                                                                    this.MostrarEditarObservaciones(
                                                                                                        c,
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="fa fa-pencil"></i>
                                                                                            </a>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Form.Row>
                                            ) : (
                                                <Form.Row></Form.Row>
                                            )}
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            <Tab eventKey="Bitacora" title="Bitácora">
                                <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Form.Row>
                                                <Col xs={12} md={12}>
                                                    <div class="table-responsive">
                                                        <BootstrapTable
                                                            keyField="id"
                                                            data={this.state.historicoLista}
                                                            columns={this.state.columnas}
                                                            pagination={paginationFactory({ sizePerPage: 10 })}
                                                        />
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab>
                            {idRol == REACT_APP_ROL_ADMINISTRATIVO && (this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ANALISIS_ARREGLO_PAGO || this.state.estadoSeleccionado.id == REACT_APP_AVAL_ESTADO_ARREGLO_PAGO_REVISION_EFA) ? (
                                <Tab eventKey="BitacoraArregloPago" title="Bitácora Arreglo de Pago">
                                    <Container fluid="md" style={{ padding: "20px 0 10px 0" }}>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <Form.Row>
                                                    <Col xs={12} md={12}>
                                                        <div class="table-responsive">
                                                            <BootstrapTable
                                                                keyField="id"
                                                                data={this.state.historicoArregloPagoLista}
                                                                columns={this.state.columnasArregloPago}
                                                                pagination={paginationFactory({ sizePerPage: 10 })}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Form.Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Tab>
                            ) : (
                                <Tab></Tab>
                            )}
                        </Tabs>
                    </div>
                    <button
                        hidden={
                            this.state.indNoEditar && this.state.indNoEditarObservaciones
                        }
                        class="btn-flotante border-radius"
                        onClick={this.modificarAval}
                        disabled={this.state.indButton}
                    >
                        <div id="guardando" class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <i
                            id="floppy"
                            class="fa fa-pencil-square-o"
                            aria-hidden="true"
                            style={{ color: "white" }}
                        ></i>
                    </button>
                </div>
            </div>
        );
    }
}
export default EditarLineaCreditoComponent;
