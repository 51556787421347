import React, { Component } from "react";
import {
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_ROL_AUDITOR,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401
} from "../../environment";
import {
    ListarProvincias,
    ListarCentrosEmpresariales,
    ReestablecerClaveUsuario,
    DesactivarMFAUsuario
} from "../../Services/UsuarioService";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import swal from "sweetalert";
import ReactDOM from "react-dom";
const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
export default class UsuarioComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indUbicacion: false,
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idRol = localStorage.getItem("idRol");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const userAgent = ObtenerUserAgent();
        sessionStorage.removeItem("infoUsuarioEditar");

        /**
         * Definicion de las columnas 
         **/
        let columnas = [
            {
                data: "b2CId",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    return filtro[0].entidadFinancieraAutorizada.nombreOperadora;
                },
                name: "b2CId"
            },
            { data: "correoElectronico", name: "correoElectronico" },
            {
                data: "b2CId",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    return filtro[0].rol.nombreRol;
                },
                name: "b2CId"
            },
            {
                data: "b2CId",
                render: function (row, type, val, meta) {
                    var filtro = [];
                    var idFondo = localStorage.getItem("idFondo");
                    filtro = val.permisos.filter(function (permiso) {
                        return (
                            permiso.fondo.id == idFondo
                        );
                    });
                    return filtro[0].estado.valor;
                },
                name: "b2CId"
            },
            {
                data: "ultimoInicioSesion",
                render: function (jsonDate) {
                    var date = new Date(jsonDate);
                    return date.toLocaleString();
                },
                name: "ultimoInicioSesion",
            },
        ];
        let definicionColumnas = [];
        if (idRol == REACT_APP_ROL_ADMINISTRATIVO ||
            idRol == REACT_APP_ROL_CONTABILIDAD ||
            idRol == REACT_APP_ROL_SEGUIMIENTO ||
            idRol == REACT_APP_ROL_AUDITOR) {
            columnas.push({ data: null, name: "Editar" });
            definicionColumnas.push({
                targets: 5,
                orderable: false,
                createdCell: (td, rowData) =>
                    ReactDOM.render(
                        <a
                            className="btn btn-primary btn-sm border-radius"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editar(rowData)}
                        >
                            <i className="fa fa-pencil"></i>
                        </a>,
                        td
                    ),
            });
        }
        if (idRol == REACT_APP_ROL_ADMINISTRATIVO ||
            idRol == REACT_APP_ROL_AUDITOR) {
            columnas.push({ data: null, name: "Desactivar MFA" });
            definicionColumnas.push({
                targets: 6,
                orderable: false,
                createdCell: (td, rowData) =>
                    ReactDOM.render(
                        <a
                            className="btn btn-light btn-sm border-radius"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.desactivarMFA(rowData)}
                        >
                            <i className="fa fa-2x fa-mobile"></i>
                        </a>,
                        td
                    ),
            });
            columnas.push({ data: null, name: "Reestablecer Clave" });
            definicionColumnas.push({
                targets: 7,
                orderable: false,
                createdCell: (td, rowData) =>
                    ReactDOM.render(
                        <a
                            className="btn btn-warning btn-sm border-radius"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.reestablecerClaveUsuario(rowData)}
                        >
                            <i className="fa fa-lock"></i>
                        </a>,
                        td
                    ),
            });
        }
        /**
         * Creacion del DataTable
         **/
        $("#tabla").DataTable({
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                this.api()
                    .column()
                    .each(function () {
                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ListarUsuarios?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr) {
                    if (xhr.status === REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                        }).then(() => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los usuarios", xhr.responseJSON.mensaje, "error", { button: "Aceptar", });
                        return;
                    }
                },
                type: "POST",
            },
            columns: columnas,
            columnDefs: definicionColumnas,
        });
        this.cargarProvincias();
        this.cargarCentrosEmpresariales();
    }
    /**
     * Cargar provincias
     **/
    cargarProvincias = () => {
        ListarProvincias().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error", res.json.mensaje, "error");
                    return;
                }
                localStorage.setItem(
                    "provinciasLista",
                    JSON.stringify(res.json.provincias)
                );
            }
        });
    };
    /**
     * Cargar centros empresariales
     **/
    cargarCentrosEmpresariales = () => {
        ListarCentrosEmpresariales().then((res) => {
            if (res) {
                if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                    swal("Error", res.json.mensaje, "error");
                    return;
                }
                localStorage.setItem(
                    "centrosEmpresarialesLista",
                    JSON.stringify(res.json.centrosEmpresariales)
                );
            }
        });
    };
    /**
     * Se llama inmediatamente antes de que se destruya un componente. 
     * Realice cualquier limpieza necesaria en este método, como solicitudes de red canceladas o la limpieza de cualquier elemento DOM creado en componentDidMount.
     **/
    componentWillUnmount() {
        $(".data-table-wrapper").find("table").DataTable().destroy(true);
    }
    /**
     * 
     * @returns Si se devuelve falso, no se llamará a Component#render, componentWillUpdate y componentDidUpdate.
     **/
    shouldComponentUpdate() {
        return false;
    }
    /**
     * 
     * @returns Redirecciona a la vista de Crear Usuario
     **/
    crearUsuario = () => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning"
            );
            return;
        }
        window.location.href = "usuario/crear";
    };
    /**
     * 
     * @returns Redirecciona a la vista de Editar Usuario
     **/
    editar = (row) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning"
            );
            return;
        }
        sessionStorage.setItem("infoUsuarioEditar", JSON.stringify(row));
        window.location.href = "usuario/" + row.b2CId;
    };
    desactivarMFA = (row) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning"
            );
            return;
        }
        let data = {
            Id: row.b2CId,
        };

        swal({
            title: "Estás seguro/segura?",
            text: "Desea desactivar la Autenticación Multifactor (MFA) al usuario: " + row.correoElectronico,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willAcept) => {
            if (willAcept) {
                swal(fondoSeleccionado.nombreFondo,
                    "Desactivando Autenticación multifactor, espere un momento.....", {
                    closeOnClickOutside: false,
                    button: "Aceptar"
                });
                DesactivarMFAUsuario(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al reiniciar el MFA del usuario", "error", { button: "Aceptar", });
                            return;
                        }
                        swal(fondoSeleccionado.nombreFondo, "¡Usuario editado con éxito!", "success", { button: "Aceptar", }).then((value) => {
                            let oTable = $("#tabla").DataTable();
                            oTable.draw();
                        });
                    }
                });
            }
        });
    };
    reestablecerClaveUsuario = (row) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning"
            );
            return;
        }
        let data = {
            Id: row.b2CId,
        };

        swal({
            title: "Estás seguro/segura?",
            text: "Desea reestablecer la clave al usuario: " + row.correoElectronico,
            icon: "warning",
            buttons: ["NO", "SÍ"],
            dangerMode: true,
        }).then((willAcept) => {
            if (willAcept) {
                swal(fondoSeleccionado.nombreFondo, "Reestableciendo clave, espere un momento.....", {
                    closeOnClickOutside: false,
                    button: "Aceptar"
                });
                ReestablecerClaveUsuario(data).then((res) => {
                    if (res) {
                        if (res.codigo != REACT_APP_CODIGO_RESPUESTA_200) {
                            swal(fondoSeleccionado.nombreFondo, "Ha ocurrido un error al reestablecer la clave", "error", { button: "Aceptar", });
                            return;
                        }
                        swal(fondoSeleccionado.nombreFondo, "¡Usuario editado con éxito!", "success", { button: "Aceptar", }).then((value) => {
                            let oTable = $("#tabla").DataTable();
                            oTable.draw();
                        });
                    }
                });
            }
        });
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (idRol) {
            if (idRol != REACT_APP_ROL_ADMINISTRATIVO &&
                idRol != REACT_APP_ROL_CONTABILIDAD &&
                idRol != REACT_APP_ROL_SEGUIMIENTO &&
                idRol != REACT_APP_ROL_JEFATURA &&
                idRol != REACT_APP_ROL_AUDITOR) window.location.href = "/admin";
        } else {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Usuarios</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Usuarios
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                onClick={this.crearUsuario}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Usuario
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Operadora</th>
                                        <th>Usuario</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Último Inicio Sesión</th>
                                        <th style={{ textAlign: "center" }}>Editar</th>
                                        <th style={{ textAlign: "center" }}>Desactivar MFA</th>
                                        <th style={{ textAlign: "center" }}>Reestablecer Clave</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_CONTABILIDAD
            case "6":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Usuarios</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Usuarios
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                onClick={this.crearUsuario}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Usuario
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Operadora</th>
                                        <th>Usuario</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Último Inicio Sesión</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_SEGUIMIENTO
            case "7":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Usuarios</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Usuarios
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                onClick={this.crearUsuario}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Usuario
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Operadora</th>
                                        <th>Usuario</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Último Inicio Sesión</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Usuarios</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Usuarios
                                </li>
                            </ol>
                        </nav>
                        <nav className="navbar navbar-light">
                            <button
                                type="button"
                                onClick={this.crearUsuario}
                                className="btn btn-gris"
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Usuario
                            </button>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Operadora</th>
                                        <th>Usuario</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Último Inicio Sesión</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_JEFATURA
            case "5":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Usuarios</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Usuarios
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Operadora</th>
                                        <th>Usuario</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Último Inicio Sesión</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            //REACT_APP_ROL_AUDITOR
            case "9":
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Usuarios</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <a href="/mantenimiento">Mantenimientos</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Usuarios
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>Nombre Operadora</th>
                                        <th>Usuario</th>
                                        <th>Rol</th>
                                        <th>Estado</th>
                                        <th>Último Inicio Sesión</th>
                                        <th style={{ textAlign: "center" }}>Editar</th>
                                        <th style={{ textAlign: "center" }}>Desactivar MFA</th>
                                        <th style={{ textAlign: "center" }}>Reestablecer Clave</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
        }
    }
}