import React, { Component } from "react";
import {
    REACT_APP_API_URL, REACT_APP_CODE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA,
    REACT_APP_ROL_ADMINISTRATIVO,
    REACT_APP_ROL_EJECUTIVO,
    REACT_APP_ROL_INCLUSION,
    REACT_APP_ROL_OPERATIVO,
    REACT_APP_ROL_JEFATURA,
    REACT_APP_ROL_CONTABILIDAD,
    REACT_APP_ROL_SEGUIMIENTO,
    REACT_APP_ROL_REPRESENTANTE_FODEMIPYME,
    REACT_APP_CODIGO_RESPUESTA_200,
    REACT_APP_CODIGO_RESPUESTA_401,
    REACT_APP_OPERADORA_FODEMIPYME,
    REACT_APP_OPERADORA_FNA,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE,
    REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA
} from "../../environment";
import { BorrarLocalStorage } from "../../Utils/BorrarInfoLocalStorage";
import { ObtenerUserAgent } from "../../Utils/BaseSolicitudModel";
import swal from "sweetalert";
import ReactDOM from "react-dom";

const $ = require("jquery");
$.DataTable = require("datatables.net-bs4");
/**
 * Obtener el Fondo seleccionado en iniciar sesion
 **/
const fondoSeleccionado = JSON.parse(localStorage.getItem("fondoSeleccionado"));
/**
 * Obtener el ID del Fondo seleccionado en iniciar sesion
 **/
const idFondo = localStorage.getItem("idFondo");
export default class HonramientoComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indUbicacion: false,
            indButton: false,
        };
    }
    /**
     * Llamado inmediatamente después de montar un componente. 
     * Establecer el estado aquí activará la re-renderización.
     **/
    componentDidMount() {
        const idRol = localStorage.getItem("idRol");
        const tokenContent = localStorage.getItem("isLoggedIn");
        const idOperadora = localStorage.getItem("idOperadora");
        let idUsuarioLogueado = localStorage.getItem("idUsuarioCreador");
        sessionStorage.removeItem("infoAvalEditar");
        if (!tokenContent) {
            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        }
        const userAgent = ObtenerUserAgent();
        sessionStorage.removeItem("infoHonramientoEditar");

        /**
        * Definicion de las columnas 
        **/
        let columnas = [
            { data: "id", name: "id" },
            {
                data: "aval.id",
                name: "aval.id",
                render: function (data, type, row, meta) {
                    if (type === "display" && idRol != REACT_APP_ROL_SEGUIMIENTO) {
                        if (row.aval.naturaleza == 0) {
                            data = '<a onclick="' + row.aval.id + '" href="aval/' + row.aval.id + '">' + row.aval.id + "</a>"
                        } else {
                            data = '<a onclick="' + row.aval.id + '" href="linea-credito/' + row.aval.id + '">' + row.aval.id + "</a>"
                        }
                    }
                    return data;
                },
            },
            {
                data: "aval.naturaleza",
                name: "aval.naturaleza",
                render: function (data, type, row, meta) {
                    if (type === "display") {
                        if (row.aval.naturaleza == 0) {
                            data = "Aval"
                        } else {
                            data = "Línea de Crédito"
                        }
                    }
                    return data;
                },
            },
            { data: "aval.tipoPrograma.valor", name: "aval.tipoPrograma.valor" },
            {
                data: "aval.entidadFinancieraAutorizada.nombreOperadora",
                name: "aval.entidadFinancieraAutorizada.nombreOperadora",
            },
            { data: "aval.nombreSolicitante", name: "aval.nombreSolicitante" },
            {
                data: "aval.numeroIdentificacionSolicitante",
                name: "aval.numeroIdentificacionSolicitante",
            },
            {
                data: "montoHonramiento",
                render: function (montoHonramiento) {
                    return montoHonramiento
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                },
                name: "montoHonramiento"
            },
            {
                data: "tieneDemanda",
                name: "tieneDemanda",
                render: function (data, type, row, meta) {
                    if (type === "display") {
                        if (row.tieneDemanda) {
                            data = "SI";
                        } else {
                            data = "NO";
                        }
                    }

                    return data;
                },
            },
            {
                data: "estado.valor",
                name: "estado.valor",
            },
        ];
        let definicionColumnas = [];
        /*if (idRol != REACT_APP_ROL_CONTABILIDAD) {*/
        columnas.push({ data: null, name: "Editar" });
        definicionColumnas.push({
            targets: 10,
            orderable: false,
            createdCell: (td, cellData, rowData, row, col) => {
                var ejecutivoCreador = [];
                ejecutivoCreador = rowData.ejecutivo.permisos.filter(function (permiso) {
                    return (
                        permiso.fondo.id == idFondo
                    );
                });
                (idOperadora == REACT_APP_OPERADORA_FODEMIPYME || idOperadora == REACT_APP_OPERADORA_FNA) &&
                    (
                        idRol == REACT_APP_ROL_ADMINISTRATIVO ||
                        idRol == REACT_APP_ROL_EJECUTIVO ||
                        idRol == REACT_APP_ROL_SEGUIMIENTO ||
                        idRol == REACT_APP_ROL_REPRESENTANTE_FODEMIPYME ||
                        idRol == REACT_APP_ROL_CONTABILIDAD
                    )
                    ? ReactDOM.render(
                        <a
                            className="btn btn-primary btn-sm border-radius"
                            style={{ cursor: "pointer" }}
                            onClick={() => this.editar(rowData)}
                        >
                            <i className="fa fa-pencil"></i>
                        </a>,
                        td
                    )
                    : ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                        rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                        rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD &&
                        (ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION || ejecutivoCreador[0].rol.id == REACT_APP_ROL_OPERATIVO) &&
                        idRol == REACT_APP_ROL_JEFATURA)
                        ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD &&
                            ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION &&
                            rowData.ejecutivo.b2CId == idUsuarioLogueado)
                        ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            idRol == REACT_APP_ROL_OPERATIVO &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_REVISION_ENTIDAD &&
                            ejecutivoCreador[0].rol.id == REACT_APP_ROL_INCLUSION) ||
                        (ejecutivoCreador[0].rol.id == REACT_APP_ROL_OPERATIVO &&
                            rowData.ejecutivo.b2CId == idUsuarioLogueado) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD &&
                            rowData.ejecutivo.b2CId == idUsuarioLogueado) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_ENTIDAD &&
                            idRol == REACT_APP_ROL_JEFATURA) ||
                        (((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME &&
                            rowData.ejecutivo.b2CId == idUsuarioLogueado) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CONDICIONADO_FODEMIPYME &&
                            idRol == REACT_APP_ROL_JEFATURA) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA &&
                            rowData.ejecutivo.b2CId == idUsuarioLogueado) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA &&
                            rowData.idOperativoReciente == idUsuarioLogueado) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_SIN_DEMANDA &&
                            idRol == REACT_APP_ROL_JEFATURA) ||
                        ((idOperadora != REACT_APP_OPERADORA_FODEMIPYME && idOperadora != REACT_APP_OPERADORA_FNA) &&
                            rowData.aval.entidadFinancieraAutorizada.id == idOperadora &&
                            (rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_IRRECUPERABLE ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_RECUPERABLE ||
                                rowData.estado.id == REACT_APP_AVAL_ESTADO_HONRAMIENTO_CON_DEMANDA) &&
                            idRol == REACT_APP_ROL_INCLUSION)
                        ? ReactDOM.render(
                            <a
                                className="btn btn-primary btn-sm border-radius"
                                style={{ cursor: "pointer" }}
                                onClick={() => this.editar(rowData)}
                            >
                                <i className="fa fa-pencil"></i>
                            </a>,
                            td
                        )
                        : ReactDOM.render("", td);
            }
        });
        //}
        /**
         * Creacion del DataTable
         **/
        $("#tabla").DataTable({
            processing: true,
            serverSide: true,
            language: {
                searchPlaceholder: "Buscar...",
                url: "//cdn.datatables.net/plug-ins/1.10.16/i18n/Spanish.json",
            },
            initComplete: function () {
                this.api()
                    .column()
                    .each(function () {
                        var table = $('#tabla').DataTable();
                        $(".dataTables_filter input")
                            .off()
                            .on('change clear', function (e) {
                                if (table.search() !== this.value) {
                                    table.search(this.value)
                                        .draw();
                                }
                            });
                    });
            },
            ajax: {
                url: `/api/ListarHonramientos?fondo=${localStorage.getItem("idFondo")}&ip=${localStorage.getItem("ip")}&latitud=${localStorage.getItem("latitud")}&longitud=${localStorage.getItem("longitud")}&userAgent=${userAgent}&usuario=${localStorage.getItem("idUsuarioCreador")}`,
                error: function (xhr, error, thrown) {
                    if (xhr.status == REACT_APP_CODIGO_RESPUESTA_401) {
                        swal({
                            title: "¡Sesión Expiró!",
                            text: "Por favor vuelva a iniciar sesión",
                            icon: "warning",
                            button: "Aceptar",
                        }).then((value) => {
                            BorrarLocalStorage();
                            window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
                        });
                        return;
                    }
                    if (xhr.status != REACT_APP_CODIGO_RESPUESTA_200) {
                        swal("Error al obtener los horamientos", thrown, "error", { button: "Aceptar", });
                        return;
                    }
                },
                complete: function (data) {
                    if (data.responseJSON != undefined || data.responseJSON != null) {
                        localStorage.setItem("datatable", JSON.stringify(data.responseJSON.data));
                    }
                },
                type: "POST",
            },
            columns: columnas,
            columnDefs: definicionColumnas,
        });
    }
    /**
     * 
     * @param {*} columna 
     * @returns Redirecciona a la vista de Editar Honramiento
     **/
    editar = (columna) => {
        if (this.state.indUbicacion) {
            swal(
                fondoSeleccionado.nombreFondo,
                "Active la ubicación y recargue el sitio web.",
                "warning", { button: "Aceptar", }
            );
            return;
        }
        sessionStorage.setItem("infoHonramientoEditar", JSON.stringify(columna));
        window.location.href = "honramiento/" + columna.id;
    };
    render() {
        const idRol = localStorage.getItem("idRol");
        if (!idRol) window.location.href = `https://${window.location.host}/seguridad/logout-b2c`;
        switch (idRol) {
            //REACT_APP_ROL_ADMINISTRATIVO
            case "1": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_EJECUTIVO
            case "2": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_INCLUSION
            case "3": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_OPERATIVO
            case "4": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_JEFATURA
            case "5": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_CONTABILIDAD
            case "6": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_SEGUIMIENTO
            case "7": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
            //REACT_APP_ROL_REPRESENTANTE_FODEMIPYME
            case "8": {
                return (
                    <div className="mx-5">
                        <center>
                            <h2>Honramientos</h2>
                        </center>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/admin">Inicio</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Lista Honramientos
                                </li>
                            </ol>
                        </nav>
                        <div className="table-responsive">
                            <table
                                id="tabla"
                                className="table table-striped table-bordered"
                                style={{ width: "100%" }}
                            >
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th># Aval</th>
                                        <th>Tipo Solicitud</th>
                                        <th>Tipo Programa</th>
                                        <th>Entidad Financiera Autorizada</th>
                                        <th>Solicitante Aval</th>
                                        <th>Identificación Solicitante Aval</th>
                                        <th>Monto Honramiento</th>
                                        <th>¿Tiene Demanda?</th>
                                        <th>Estado</th>
                                        <th>Editar</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                );
            }
        }
    }
}
